import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {ObjectClassState} from '../../backend/object-class/object-class.state';
import {TemplateState} from '../../backend/templates/template.state';
import { Observable } from 'rxjs';
import {filter} from 'rxjs/operators';
import { Field, PropertyDefinition, Template } from '../../..';

@Injectable({
  providedIn: 'root'
})

export class ObjectSearchResultConnector {

  constructor(private store: Store) {
  }

  getTemplate(templateName: string): Observable<Template> {
    return this.store.selectOnce(TemplateState.searchTemplateByName(templateName)).pipe(
      filter(pDefs => !!pDefs)
    );
  }

  public getPropertyDefinitions(objectClassNames: string[]): Observable<PropertyDefinition[]> {
    return this.store.selectOnce(ObjectClassState.propertyDefinitions(objectClassNames)).pipe(
      filter(pDefs => !!pDefs)
    );
  }

  public getResultTableColumns(templateName: string): Observable<Field[]> {
    return this.store.selectOnce(TemplateState.resultTableColumns(templateName)).pipe(
      filter(fields => !!fields)
    );
  }

  /**
   * Attention, we only return ONE searchIndexName, because our backend cannot handle searching in multiple searchIndexes.
   * @param objectClassNames
   */
  public getSearchIndexName(objectClassNames: string[]): Observable<string | undefined> {
    if (objectClassNames?.length > 0) {
      return this.store.selectOnce(ObjectClassState.searchIndexName(objectClassNames[0]))
    }
  }
}
