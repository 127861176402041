<div class="page flex flex-row justify-end items-center w-full h-full">
  <div class="info-area flex flex-col basis-full sm:basis-1/3">
    <div>
      <h1>{{ 'maintenance.page.header' | translate | uppercase }}</h1>
      <h2>{{ 'maintenance.page.text' | translate }}</h2>
    </div>

    <div class="flex flex-row justify-between">
      <div class="info-circle flex flex-row justify-center items-center">
      <span class="circle-content">
        <div class="value">{{ countdown.hours }}</div>
        <div class="caption">{{ 'maintenance.page.hours' | translate }}</div>
      </span>
      </div>

      <div class="info-circle flex flex-row justify-center items-center">
      <span class="circle-content">
        <div class="value">{{ countdown.minutes }}</div>
        <div class="caption">{{ 'maintenance.page.minutes' | translate }}</div>
      </span>
      </div>

      <div class="info-circle flex flex-row justify-center items-center">
      <span class="circle-content">
        <div class="value">{{ countdown.seconds }}</div>
        <div class="caption">{{ 'maintenance.page.seconds' | translate }}</div>
      </span>
      </div>

    </div>
  </div>


</div>
