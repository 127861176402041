import { Component, Input } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'frontmania-select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss']
})
export class SelectAllComponent {
  @Input() matSelect: MatSelect;

  isAllSelected() {
    if (!this.matSelect.options) {
      return false;
    }
    return !this.matSelect.options.find(opt => !opt.selected);
  }

  selectAllToggle() {

    if (this.isAllSelected()) {
      this.matSelect.options.forEach((item: MatOption) => item.deselect());
    } else {
      this.matSelect.options.forEach((item: MatOption) => item.select());
    }
  }

}
