import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class I18nModuleConfig {
  supportedLocales: string[];
  defaultLocale: string;
}

