import { FormGroup } from '@angular/forms';
import { ChoiceValue, FieldDefinition } from '@frontmania/object-master-data';
import { Component, Input, Optional } from '@angular/core';
import { DynamicFormsConfig } from '../../dynamic-forms.config';
import { DynamicFormsService } from '../../util/dynamic-forms.service';

@Component({
  selector: 'frontmania-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent {

  constructor(@Optional() private config: DynamicFormsConfig) {
  }

  choiceValueOptions: ChoiceValue[] = [];
  private _fieldDefinition: FieldDefinition;

  @Input()
  dynamicForm: FormGroup;

  @Input()
  public set fieldDefinition(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
    this.choiceValueOptions = this.getSelectOptionsForDropdown();
  }

  public get fieldDefinition(): FieldDefinition {
    return this._fieldDefinition;
  }

  get masterDataOriginUrl(): string {
    return this.config ? this.config.masterDataOriginUrl : '';
  }

  isDate() {
    return DynamicFormsService.isDate(this._fieldDefinition);
  }

  isMasterDataField() {
    return DynamicFormsService.isMasterDataField(this._fieldDefinition);
  }

  isChoiceValueSelect() {
    return DynamicFormsService.isChoiceValueSelect(this._fieldDefinition);
  }

  isMultiSelect() {
    return DynamicFormsService.isMultiSelect(this._fieldDefinition);
  }

  isText() {
    return DynamicFormsService.isText(this._fieldDefinition);
  }

  isFloating() {
   return DynamicFormsService.isFloating(this._fieldDefinition);
  }

  isBoolean() {
    return DynamicFormsService.isBoolean(this._fieldDefinition);
  }

  isSearchInput() {
    return DynamicFormsService.isSearchInput(this._fieldDefinition);
  }

  isMandatory() {
    return DynamicFormsService.isMandatory(this._fieldDefinition);
  }

  private getSelectOptionsForDropdown(): ChoiceValue[] {
    if (this.isChoiceValueSelect() && !this.isMultiSelect() ) {
      const result = [{label: '', value: null}] as ChoiceValue[];
      result.push(...this.fieldDefinition.choiceValues);
      return result;
    }
    else {
      return this.fieldDefinition.choiceValues;
    }
  }

}
