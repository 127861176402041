import {Injectable} from "@angular/core";
import { ClearResultTable, NextPage } from '../object-search.actions';
import {Store} from "@ngxs/store";
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObjectSearchService {


  applyFillStrategies$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private store: Store) {}

  clearState() {
    return this.store.dispatch(new ClearResultTable());
  }

  reset() {
    this.store.dispatch(new ClearResultTable());
  }

  nextPage() {
    this.store.dispatch(new NextPage());
  }
}
