import {ObjectAclDefinition, ObjectAclStateType, ObjectDetails} from '@frontmania/object-master-data';


export class AclMapper {

  private static readonly ACL_PLACEHOLDER = /\${.*}/;

  static mapAcl(objectDetails: ObjectDetails, defaultAclFromClass: ObjectAclDefinition[]) {
    const simpleReplacement: ObjectAclDefinition[] = this.setAclStateType(objectDetails.acl, defaultAclFromClass);
    return this.handlePlaceHolders(simpleReplacement, defaultAclFromClass, objectDetails);
  }

  private static setAclStateType(aclWithoutType: ObjectAclDefinition[], defaultAclFromClass: ObjectAclDefinition[]) {
    return aclWithoutType.map(acl => {
      const defAcl = defaultAclFromClass.filter(defaultAcl => defaultAcl.name === acl.name);
      return {...acl, stateType: defAcl.length === 1 ? ObjectAclStateType.DEFAULT : ObjectAclStateType.ADDED};
    });
  }

  private static handlePlaceHolders(simpleReplacement: ObjectAclDefinition[], defaultAclFromClass: ObjectAclDefinition[], properties: ObjectDetails) {
    const aclWithPlaceHolders = this.collectAclWithPlaceHolders(defaultAclFromClass);
    if (aclWithPlaceHolders?.length < 1) {
      return simpleReplacement;
    }
    const aclWithFilledInPlaceHolders = this.replacePlaceHolders(aclWithPlaceHolders, properties);
    return this.overwriteTypesForAclWithPlaceholders(simpleReplacement, aclWithFilledInPlaceHolders);
  }

  private static collectAclWithPlaceHolders(defaultAclFromClass: ObjectAclDefinition[]) {
    return defaultAclFromClass?.filter(acl => acl.name.includes("${"));
  }

  private static replacePlaceHolders(aclWithPlaceHolders: ObjectAclDefinition[], properties: ObjectDetails) {
    const mappedDefWithPlaceholders = [];
    aclWithPlaceHolders?.forEach(acl => {
      const match = this.ACL_PLACEHOLDER.exec(acl.name);
      if (match) {
        const placeHolderContent = this.extractPlaceHolderContent(match);
        const documentProperty = properties[placeHolderContent];
        if (Array.isArray(documentProperty)) {
          documentProperty.forEach(prop => {
            mappedDefWithPlaceholders.push(this.replaceContent(acl, prop));
          })
        } else {
          mappedDefWithPlaceholders.push(this.replaceContent(acl, documentProperty));
        }
      }

    });
    return mappedDefWithPlaceholders;
  }

  private static replaceContent(acl, prop) {
    return acl.name.replace(this.ACL_PLACEHOLDER, prop?.toLowerCase() ?? '' );
  }

  private static extractPlaceHolderContent(match: RegExpExecArray) {
    return match[0].replace("${", "").replace("}", "").replace("document.", "");
  }

  private static overwriteTypesForAclWithPlaceholders(simpleReplacement, mappedDefWithPlaceholders) {
    return simpleReplacement.map(acl => {
      const defAcl = mappedDefWithPlaceholders.filter(mappedAcl => mappedAcl === acl.name);
      return {...acl, stateType: defAcl.length === 1 ? ObjectAclStateType.DEFAULT : acl.stateType};
    });
  }

}
