import {Action, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {NotificationService} from './service/notification.service';
import {CloseNotification, OpenNotification} from "./notification.actions";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NotificationStateModel {
}

@State<NotificationStateModel>({
  name: 'notification',
  defaults: {}
})
@Injectable({
  providedIn: 'root'
})
// TODO: we may only need the service
export class NotificationState {

  constructor(private store: Store,
              private notificationService: NotificationService) {
  }

  @Action(OpenNotification)
  private showNotification(ctx: StateContext<NotificationStateModel>, action: OpenNotification) {
    this.notificationService.openNotification(action.type, action.messageKey, action.placeholders);
  }

  @Action(CloseNotification)
  private closeNotification() {
    this.notificationService.closeNotification();
  }
}
