import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicFormOutput, ObjectAclDefinition, ObjectEditConnector } from '@frontmania/object-master-data';
import { FORM_NAME, ObjectUpdateData } from '../../object-details.model';
import { BehaviorSubject, switchMap, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ObjectDetailsState } from '../../object-details.state';
import { Store } from '@ngxs/store';
import { FieldsMapper } from '../../service/fields-mapper';
import { AclMapper } from '../../service/acl-mapper';
import { ClearEditForm } from '../../object-details.actions';

@UntilDestroy()
@Component({
  selector: 'frontmania-object-details-editing',
  templateUrl: './object-details-editing.component.html',
  styleUrls: ['./object-details-editing.component.scss']
})
export class ObjectDetailsEditingComponent implements OnDestroy {

  @Output()
  uploadEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  submitEvent: EventEmitter<ObjectUpdateData> = new EventEmitter<ObjectUpdateData>();

  formDefinition$ = this.store.select(ObjectDetailsState.templateName).pipe(
    untilDestroyed(this),
    filter(templateName => !!templateName),
    switchMap(templateName => this.objectEditConnector.getFormDefinition(templateName)),
    withLatestFrom(this.store.select(ObjectDetailsState.currentObject)),
    filter(([formDef, object]) => formDef?.fieldDefinitionGroup.length > 0 && !!object),
    map(([formDef, object]) => FieldsMapper.toFormDefinitionInput(formDef, object)),
    map(formDef => FieldsMapper.removeReadOnlyFields(formDef)),
  );

  initialAcl$ = this.store.select(ObjectDetailsState.currentObject).pipe(
    withLatestFrom(this.store.select(ObjectDetailsState.defaultInstanceSecurity)),
    map(([details, defaultInstanceSecurity]) => AclMapper.mapAcl(details, defaultInstanceSecurity))
  );

  triggerReset$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  formName: string = FORM_NAME;

  showPermissions$ = this.store.select(ObjectDetailsState.checkinOptions).pipe(
    map(options => options?.showPermissions ?? false)
  )

  private currentAcl: ObjectAclDefinition[];

  constructor(private store: Store, private objectEditConnector: ObjectEditConnector) {
  }

  submitForm(formFields: DynamicFormOutput[]) {
    this.submitEvent.emit({
      fields: formFields.filter(field => !field.fieldDefinition.readonly),
      acl: this.currentAcl
    });
  }

  cancel() {
    this.triggerReset$$.next(true);
    this.cancelEvent.emit();
  }

  setCurrentAcl(newAcl: ObjectAclDefinition[]) {
    this.currentAcl = newAcl;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearEditForm());
  }
}
