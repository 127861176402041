import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SearchState, UserProfileStateModel, UserSettingsContent, UserSettingsResponse} from "./user-profile.model";
import {UserProfileService} from "./user-profile.service";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationType, OpenNotification} from "@frontmania/notification";
import {UserProfileConfig} from "./user-profile.config";
import {AddSearch, DeleteSearch, LoadSettings, SetStartPage} from "./user-profile.actions";

@State<UserProfileStateModel>({
  name: 'profile',
  defaults: {
    settings: undefined,
    currentSearch: undefined
  }
})
@Injectable()
export class UserProfileState {

  static savedSearches(templateName: string) {
    return createSelector([UserProfileState], (state: UserProfileStateModel) => {
      return state.settings?.searches?.filter(search => search.templateName === templateName);
    });
  }

  static selectSearch(id: string) {
    return createSelector([UserProfileState], (state: UserProfileStateModel) => {
      return state.settings?.searches?.find(search => search.id === id);
    });
  }

  @Selector()
  static currentSettings(state: UserProfileStateModel): UserSettingsContent {
    return state.settings;
  }

  @Selector()
  static currentSearch(state: UserProfileStateModel): SearchState {
    return state.currentSearch;
  }


  @Selector()
  static startPage(state: UserProfileStateModel): string {
    return state.settings.startPage;
  }

  constructor(private store: Store, private router: Router, private userProfileService: UserProfileService, private config?: UserProfileConfig) {
  }

  @Action(LoadSettings)
  loadSettings(ctx: StateContext<UserProfileStateModel>) {
    const currentSettings = ctx.getState().settings;
    if (!currentSettings && !!this.config?.userProfileUrl) {
      return this.userProfileService.getUserProfile().pipe(
        tap((settings:UserSettingsResponse) => ctx.patchState({settings: settings.userSettings}))
      )
    }
  }

  @Action(DeleteSearch)
  deleteSearch(ctx: StateContext<UserProfileStateModel>, payload: DeleteSearch) {
    const currentSettings = this.store.selectSnapshot(UserProfileState.currentSettings);
    const idx = currentSettings.searches.indexOf(currentSettings.searches.find(search => search.id === payload.id));
    const newSearches = [...currentSettings.searches];
    newSearches.splice(idx, 1);
    ctx.patchState({
      settings: {...currentSettings, searches: newSearches}
    });
    return this.saveUserSettings(ctx.getState().settings);
  }

  @Action(SetStartPage)
  setStartPage(ctx: StateContext<UserProfileStateModel>, payload: SetStartPage) {
    const currentSettings = ctx.getState().settings;
    const newSettings = {
      ...currentSettings,
      startPage: (currentSettings?.startPage === payload.startPage) ? undefined : payload.startPage
    };
    ctx.patchState({
      settings: newSettings
    })

    return this.saveUserSettings(newSettings);
  }

  @Action(AddSearch)
  addSearch(ctx: StateContext<UserProfileStateModel>, payload: AddSearch) {
    const currentSettings = ctx.getState().settings;
    const currentSearches = currentSettings?.searches;
    const newSearch: SearchState = {
      name: payload.searchState.name,
      templateName: payload.searchState.templateName,
      fields: payload.searchState.fields,
      id: payload.searchState.id,
      objectClassNames: payload.searchState.objectClassNames
    }
    let newSearches = [];
    if (!currentSearches) {
      newSearches.push(newSearch);
    } else {
      const duplicate = currentSettings.searches.find(search => search.templateName === payload.searchState.templateName && search.name === payload.searchState.name);
      if (duplicate) {
        this.store.dispatch(new OpenNotification(NotificationType.ERROR, "userProfile.errors.duplicateIdentifier", {"identifier": newSearch.name}));
        return;
      }
      newSearches = [...currentSettings.searches, newSearch];
    }
    const newSettings = {...currentSettings, searches: newSearches};
    ctx.patchState({
      settings: newSettings
    })

    return this.saveUserSettings(newSettings);
  }

  private saveUserSettings(newSettings: UserSettingsContent) {
    return this.userProfileService.saveUserProfile(newSettings);
  }

}
