import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ObjectSearchComponent} from './components/object-search/object-search.component';
import {MaterialModule} from '@frontmania/material';
import {ObjectSearchConfig} from './object-search.config';
import {ObjectSearchState} from './object-search.state';
import {NgxsModule} from '@ngxs/store';
import {I18nModule} from '@frontmania/i18n';
import {UntilDestroy} from '@ngneat/until-destroy';
import {authGuard, SecurityTemplates} from '@frontmania/auth';
import {ObjectSearchResultsTableComponent} from "./components/object-search/table/table.component";
import {AttachedObjectsComponent} from "./components/object-search/table/attached-objects/attached-objects.component";
import {PreviewComponent} from "./components/object-search/table/preview/preview.component";
import {SearchFormComponent} from "./components/object-search/form/search-form.component";
import {DynamicFormsModule} from "@frontmania/dynamic-forms";
import {FormsModule} from "@angular/forms";
import {
  SaveSearchDialogComponent
} from './components/object-search/form/save-search-dialog/save-search-dialog.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {UnitsModule} from "@frontmania/units";
import {ObjectStoreMasterDataModule} from "@frontmania/object-master-data";
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {DocCountInfoComponent} from './components/object-search/table/doc-count-info/doc-count-info.component';

@UntilDestroy()
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: 'object-search/:templateName',
        component: ObjectSearchComponent,
        canActivate: [authGuard],
        data: {securityTemplate: SecurityTemplates.OBJECT_SEARCH_ACCESS}
      }
    ]),
    NgxsModule.forFeature([ObjectSearchState]),
    I18nModule,
    DynamicFormsModule,
    UnitsModule,
    ObjectStoreMasterDataModule,
    NgxExtendedPdfViewerModule,
    InfiniteScrollModule,
  ],
  declarations: [ObjectSearchComponent, ObjectSearchResultsTableComponent,
    AttachedObjectsComponent,
    SearchFormComponent,
    SaveSearchDialogComponent,
    PreviewComponent,
    DocCountInfoComponent
  ]
})
export class ObjectSearchModule {

  static forRoot(config?: ObjectSearchConfig): ModuleWithProviders<ObjectSearchModule> {
    return {
      ngModule: ObjectSearchModule,
      providers: [{
        provide: ObjectSearchConfig,
        useValue: config
      }]
    };
  }
}
