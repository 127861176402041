export class LoadChoiceValues {
  static readonly type = '[ObjectMasterData] LoadChoiceValues';

  constructor(public language: string, public choiceValueNames: string[], public forceReload = false) {
  }
}

export class RefreshChoiceValues {
  static readonly type = '[ObjectMasterData] RefreshChoiceValues';

  constructor(public language: string, public choiceValueNames: string[], public forceReload = false) {
  }
}
