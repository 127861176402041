import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DynamicFormsConfig} from "../dynamic-forms.config";

@Injectable({
  providedIn: 'root'
})
export class LdapSearchService {

  constructor(private httpClient: HttpClient,
              private config: DynamicFormsConfig) {
  }

  loadRoles(query = '') {
    return this.httpClient.get<string[]>(this.config.ldapUrl + '?name=' + query);
  }
}
