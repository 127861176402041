import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationService} from "./service/notification.service";
import {NotificationComponent} from "./component/notification.component";
import {MaterialModule} from "@frontmania/material";
import {I18nModule} from "@frontmania/i18n";
import {NotificationConfig} from "./notification.config";
import {NgxsModule} from '@ngxs/store';
import {NotificationState} from './notification.state';


@NgModule({
    imports: [CommonModule,
        MaterialModule, I18nModule,
        NgxsModule.forFeature([NotificationState])
    ],
  declarations: [NotificationComponent]
})

export class NotificationModule {
  static forRoot(config?: NotificationConfig): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: NotificationService
        },
        {
          provide: NotificationConfig,
          useValue: config
        }
      ]
    }
  }
}
