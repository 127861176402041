import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { I18nState, SelectLocale } from '@frontmania/i18n';
import { ObjectMasterDataService } from '@frontmania/object-master-data';
import { combineLatest, map, Observable, switchMap } from 'rxjs';
import { AuthState, CurrentUser, Logout } from '@frontmania/auth';
import { filter } from 'rxjs/operators';
import { AppService } from './app-service';
import { AppState } from './app.state';
import { SetSideMenu, ToggleMenuByName } from './app.actions';
import { LoadSettings } from '@frontmania/user-profile';
import { Menu } from './app.model';
import { MaintenanceInfoService } from '@frontmania/maintenance-info';
import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'ap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @Select(AppState.menuState(AppState.MAIN_SIDE_NAV)) sideNavVisible$: Observable<boolean>;
  @Select(AppState.showSideMenu) showSideMenu$: Observable<boolean>;

  @Select(AuthState.currentUser) currentUser$: Observable<CurrentUser>
  @Select(I18nState.selectedLocale) selectedLocale$: Observable<string>
  @Select(I18nState.availableLocales) availableLocales$: Observable<string[]>

  exportAdminViewEnabled = environment.exportAdminViewEnabled ?? false;

  constructor(private store: Store,
              private objectMasterDataService: ObjectMasterDataService,
              private appService: AppService,
              private maintenanceService: MaintenanceInfoService) {
  }

  ngOnInit(): void {

    combineLatest([
      this.store.select(I18nState.selectedLocale),
      this.store.select(AppState.sideMenu),
      this.store.select(AuthState.authorized)]).pipe(
      untilDestroyed(this),
      filter(([locale, , authorized]) => !!locale && !!authorized),
      switchMap(([locale, template]) => {
        this.objectMasterDataService.updateLanguage(locale);
        return this.store.dispatch(new LoadSettings()).pipe(
          map(() => template)
        );
      }),
      filter((template: Menu) => !template),
      switchMap(() => {
        return this.appService.loadSideMenu();
      }),
    ).subscribe((sideMenu) => {
      this.store.dispatch(new SetSideMenu(sideMenu));
    });

  }

  get maintenanceRunning() {
    return this.maintenanceService.isMaintenanceRunning();
  }

  get logoutButtonVisible() {
    return environment?.oauth2?.logoutEnabled ?? true;
  }

  toggleSideNav() {
    this.store.dispatch(new ToggleMenuByName(AppState.MAIN_SIDE_NAV));
  }

  logout() {
    this.store.dispatch(new Logout())
  }

  selectLocale(locale: string) {
    this.store.dispatch(new SelectLocale(locale))
  }
}
