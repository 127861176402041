import * as moment from 'moment';
import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {SelectLocale} from './i18n.actions';
import {I18nModuleConfig} from './i18n.config';


export class LocaleStateModel {
  current: string;
  available: string[];
}

@Injectable()
@State<LocaleStateModel>({
  name: 'locale'
})
export class I18nState implements NgxsOnInit {

  constructor(private translate: TranslateService, private config: I18nModuleConfig) {
  }

  @Selector()
  static selectedLocale(state: LocaleStateModel): string {
    return state.current;
  }

  @Selector()
  static availableLocales(state: LocaleStateModel): string[] {
    return state.available;
  }

  ngxsOnInit(ctx: StateContext<LocaleStateModel>) {
    const availableLocales = this.config.supportedLocales;
    const currentLocale = (
      ctx.getState().current ||
      this.translate.getBrowserLang() ||
      this.translate.getDefaultLang() ||
      availableLocales[0]
    );

    this.translate.addLangs(availableLocales);
    this.translate.use(currentLocale);
    this.translate.setDefaultLang(this.config.defaultLocale);
    return ctx.patchState({
      current: currentLocale,
      available: availableLocales
    });
  }

  @Action(SelectLocale)
  selectLocale({patchState}: StateContext<LocaleStateModel>, payload: SelectLocale) {
    moment.locale(payload.locale);
    this.translate.use(payload.locale);

    return patchState({current: payload.locale});
  }

}
