import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Job, JobDefinition} from '../api/model/job.model';
import {map} from 'rxjs/operators';
import {ObjectExportConfig} from '../object-export.config';
import * as _ from 'lodash';
import {BackendResult} from "../backend-result.model";

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private httpClient: HttpClient,
              private config: ObjectExportConfig) {
  }

  getDownloadUrl(job: Job): string {
    return this.config.jobUrl.replace("{jobId}", job.id) + '/result';
  }

  getAllJobs(): Observable<Job[]> {
    return this.httpClient.get<BackendResult>(this.config.exportUrl + '/anything/all')
      .pipe(
        map(beResult => beResult.content),
        map(jobs => _.map(jobs, (j) => new Job(j)))
      );
  }

  createJob(job: JobDefinition): Observable<Job> {
    job.scope = this.config.scope;
    return this.httpClient.post<Job>(this.config.exportUrl, job);
  }

  deleteJob(job: Job): Observable<Job> {
    const _url = this.config.jobUrl.replace('{jobId}', job.id);
    return this.httpClient.delete<Job>(_url);
  }

}


