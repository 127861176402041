import {Injectable} from '@angular/core';

@Injectable()
export class ObjectSearchConfig {
  templateApiUrl: string;
  dataTableColumnsUrl: string;
  objectSearchUrl: string;
  objectCountUrl: string;
  attachmentsUrl: string;
  downloadUrl: string;
  inlineContentUrl: string;
  showFavoritesButton?: boolean;
  allowSavingSearches?: boolean;
  showBackButtonOnSearchForm?: boolean
  maxDocCount: number
}
