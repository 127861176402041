import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { ObjectMasterDataService } from '@frontmania/object-master-data';
import { ObjectSearchService } from './api/object-search.service';
import { DataTableService } from './service/data-table.service';
import { DataTableMapper } from './service/data-table.mapper';
import {
  ClearResultTable,
  CountObjectsByClasses,
  CountObjectsByQuery,
  FinishLoadingDocuments,
  InitObjectSearch,
  InitResultTable,
  NextPage,
  SearchDocuments,
  SetSortField,
  StartLoadingDocuments,
  UpdateSelection
} from './object-search.actions';
import {
  DocumentSearch,
  ObjectCount,
  ObjectSearchStateModel,
  SearchResult,
  STATE_DEFAULTS
} from './object-search.model';
import { NotificationType, OpenNotification } from '@frontmania/notification';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { throwError } from 'rxjs';

@State<ObjectSearchStateModel>({
  name: 'objectSearch',
  defaults: STATE_DEFAULTS,
})
@Injectable({
  providedIn: 'root',
})
export class ObjectSearchState {

  constructor(private searchService: DataTableService, private store: Store, private objectMasterDataService: ObjectMasterDataService, private objectSearchService: ObjectSearchService) {
  }


  @Selector()
  static loadedDocuments(state: ObjectSearchStateModel) {
    return state.loadedDocuments;
  }
  @Selector()
  static isLoading(state: ObjectSearchStateModel) {
    return state.loading;
  }

  @Selector()
  static options(state: ObjectSearchStateModel) {
    return state.options;
  }

  @Selector()
  static resultTableColumns(state: ObjectSearchStateModel) {
    return state.resultTableColumns;
  }

  @Selector()
  static resultTableColumnNames(state: ObjectSearchStateModel): string[] {
    return state.resultTableColumns.map(column => column.name);
  }

  @Selector()
  static totalNumberOfDocumentsByClasses(state: ObjectSearchStateModel) {
    return state.totalElements;
  }

  @Selector()
  static totalNumberOfDocumentsByQuery(state: ObjectSearchStateModel) {
    return state.totalElementsByQuery;
  }

  @Selector()
  static loadedNumberOfDocuments(state: ObjectSearchStateModel) {
    return state.loadedDocuments?.length ?? 0;
  }

  @Selector()
  static selection(state: ObjectSearchStateModel) {
    return state.selection;
  }

  @Selector()
  static rsqlQuery(state: ObjectSearchStateModel) {
    return state.rsqlQuery;
  }

  @Selector()
  static index(state: ObjectSearchStateModel) {
    return state.searchIndexName;
  }

  @Selector()
  static currentPage(state: ObjectSearchStateModel) {
    return state.currentPage;
  }

  @Selector()
  static currentPageSize(state: ObjectSearchStateModel) {
    return state.currentPageSize;
  }

  @Selector()
  static templateName(state: ObjectSearchStateModel) {
    return state.currentTemplateName;
  }

  @Action(InitObjectSearch)
  initObjectSearch(ctx: StateContext<ObjectSearchStateModel>, action: InitObjectSearch) {
    return this.objectSearchService.clearState().pipe(
      concatMap(() => {
        return this.objectMasterDataService.loadObjectSearchMasterData(action.language, action.templateName).pipe(
          tap(() => ctx.patchState({currentTemplateName: action.templateName, totalElements: undefined}))
        );
      })
    );
  }

  @Action(InitResultTable)
  initResultTable(ctx: StateContext<ObjectSearchStateModel>, action: InitResultTable) {
    return ctx.patchState({
      resultTableColumns: DataTableMapper.mapToTableColumns(action.templateFields, action.classFields),
      options: action.options,
      searchIndexName: action.searchIndexName || undefined
    });
  }

  @Action(ClearResultTable)
  clearResultTable(ctx: StateContext<ObjectSearchStateModel>) {
    return ctx.setState({ ...this.getClearedStateModel(ctx), loading: false })
  }

  @Action(StartLoadingDocuments)
  startLoadingDocumentsResultTable(ctx: StateContext<ObjectSearchStateModel>) {
    return ctx.patchState({loading: true });
  }

  @Action(FinishLoadingDocuments)
  finishLoadingDocumentsResultTable(ctx: StateContext<ObjectSearchStateModel>) {
    return ctx.patchState({loading: false });
  }

  @Action(UpdateSelection)
  updateSelection(ctx: StateContext<ObjectSearchStateModel>, action: UpdateSelection) {
    return ctx.patchState({selection: action.selection});
  }

  @Action(SearchDocuments, {cancelUncompleted: true})
  searchDocuments(ctx: StateContext<ObjectSearchStateModel>, action: SearchDocuments) {
    const search = {
      query: action.rsqlQuery,
      pageNumber: ctx.getState().currentPage,
      pageSize: ctx.getState().currentPageSize,
      index: ctx.getState().searchIndexName,
      withElementsCount: false
    } as DocumentSearch;

    if (!action.appendResult) {
      ctx.setState({ ...this.getClearedStateModel(ctx), rsqlQuery: action.rsqlQuery, loading: true});
      ctx.dispatch(new CountObjectsByQuery(new ObjectCount(search.query)));
    }

    return this.searchService.searchDocuments(search)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.GatewayTimeout) {
            return ctx.dispatch(new OpenNotification(NotificationType.ERROR, 'objectSearch.error.timeout'))
          }
          return throwError(error);
        }),
        tap((result: SearchResult) => {
          const sortSettings = ctx.getState().sortSettings;
          const loadedDocs = this.store.selectSnapshot(ObjectSearchState.loadedDocuments);
          if (action.appendResult && loadedDocs) {
            return ctx.patchState({ loadedDocuments: sortSettings ? this.searchService.sortObjects([...loadedDocs, ...result.elements], sortSettings) : [...loadedDocs, ...result.elements]})
          } else {
            return ctx.patchState({ loadedDocuments: sortSettings ? this.searchService.sortObjects(result.elements, sortSettings) : result.elements })
          }
        }),
      );
  }

  @Action(NextPage)
  nextPage(ctx: StateContext<ObjectSearchStateModel>) {
    ctx.patchState({ currentPage: ctx.getState().currentPage + 1 });
    return ctx.dispatch(new SearchDocuments(ctx.getState().rsqlQuery, true));
  }

  @Action(SetSortField)
  setSortField(ctx: StateContext<ObjectSearchStateModel>, action: SetSortField) {
    const objects = this.searchService.sortObjects(ctx.getState()?.loadedDocuments, action.sorting);
    return ctx.patchState({ sortSettings: action.sorting, loadedDocuments: objects });
  }

  @Action(CountObjectsByQuery, {cancelUncompleted: true})
  countObjectsByQuery(ctx: StateContext<ObjectSearchStateModel>, action: CountObjectsByQuery) {
    return this.searchService.countObjectsByQuery(action.rsql).pipe(
      tap(result => ctx.patchState({totalElementsByQuery: result.count as number }))
    );
  }

  @Action(CountObjectsByClasses, {cancelUncompleted: true})
  countObjectsByClasses(ctx: StateContext<ObjectSearchStateModel>, action: CountObjectsByClasses) {
    return this.searchService.countObjectByClasses(action.objectClasses).pipe(
      tap(result => ctx.patchState({totalElements: result.count as number}))
    )
  }

  private getClearedStateModel(ctx: StateContext<ObjectSearchStateModel>): ObjectSearchStateModel {
    return { ...STATE_DEFAULTS,
      currentTemplateName: ctx.getState().currentTemplateName,
      totalElements: ctx.getState().totalElements,
      resultTableColumns: ctx.getState()?.resultTableColumns,
      options: ctx.getState()?.options,
      searchForm: ctx.getState()?.searchForm,
    };
  }


}
