import {Injectable} from '@angular/core';
import {
  DateRangeField, DynamicFormOutput,
  ObjectAclDefinition,
  ObjectAclStateType,
  ValueType
} from '@frontmania/object-master-data';
import * as moment from "moment";
import {ObjectUpdateData} from '../object-details.model';


@Injectable({
  providedIn: 'root'
})

export class ObjectEditMetadataMapper {

  private static extractKeyValuePairsFromFieldDefinitions(formFields: DynamicFormOutput[]) {
    const uploadData = {};
    formFields.forEach(field => {
      if (field.fieldDefinition.type === ValueType.DATE || field.fieldDefinition.type === ValueType.DATE_TIME) {
        const dateRangeField = field.value as DateRangeField;
        uploadData[field.fieldDefinition.name] = ObjectEditMetadataMapper.toMoment(dateRangeField.start).valueOf();
      } else {
        uploadData[field.fieldDefinition.name] = field.value;
      }
    })
    return uploadData
  }

  private static restructureACLsForUpload (acls: ObjectAclDefinition[], uploadData: { [key: string]: unknown }) {
    const aclWithoutType = [];
    acls?.filter(acl => acl.stateType === ObjectAclStateType.ADDED)
      .forEach(acl => {
        const aclToAdd = {...acl };
        delete aclToAdd.stateType;
      aclWithoutType.push(aclToAdd);
    });

    uploadData["acl"] = aclWithoutType;
    uploadData["replaceAcl"] = true;
  }

  private static toMoment(value: unknown): moment.Moment {
    if (moment.isMoment(value)) {
      return value as moment.Moment;
    }
    return moment(value, moment.ISO_8601);
  }

  /**
   * This class maps the model from the ui components back to a key-value pairs object. This object represents the metadata
   * of an ObjectDetails, which can be uploaded to the ObjectStore
   */
  static toObjectDetailsUploadData(updateData: ObjectUpdateData) {
    const result = ObjectEditMetadataMapper.extractKeyValuePairsFromFieldDefinitions(updateData.fields);
    ObjectEditMetadataMapper.restructureACLsForUpload(updateData.acl, result);
    return  result;
  }


}

