import {Menu} from "./app.model";

export class ToggleMenuByName {
  static readonly type = '[Application] ToggleMenuByName';

  constructor(public name: string, public justClose?: boolean) {
  }
}

export class SetSideMenu {
  static readonly type = '[Application] SetSideMenu';

  constructor(public template: Menu) {
  }
}


export class ShowSideMenu {
  static readonly type = '[Application] ShowSideMenu';

  constructor(public show: boolean) {
  }
}

export class StartPageLoaded {
  static readonly type = '[Application] StartPageLoaded';
}

