
<ng-container *ngIf="(attachments$ | async) as attachments ">

  <mat-table class="fm-row-hover" [dataSource]="attachments">

    <ng-container matColumnDef="contentName">
      <mat-header-cell *matHeaderCellDef>{{'objectDetails.tableColumns.filename' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let attachment">{{attachment.contentName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="contentSizeInBytes">
      <mat-header-cell *matHeaderCellDef>{{'objectDetails.tableColumns.fileSize' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let attachment"> {{attachment.contentSizeInBytes | fileSize : selectedLocale() }}  </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns; let i = index;" routerLinkActive="is-selected" (click)="download(attachments[i])"></mat-row>

  </mat-table>
</ng-container>
