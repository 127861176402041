import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthModule, AuthModuleConfig } from '@frontmania/auth';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { routes } from './app.routing.module';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MaterialModule } from '@frontmania/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AnythingPortalConfig,
  appConfigFactory,
  authModuleConfigFactory,
  i18nModuleConfigFactory,
  notificationModuleConfigFactory,
  objectDetailsConfigFactory,
  objectExportConfigFactory,
  objectSearchConfigFactory,
  objectStoreMasterDataConfigFactory,
  userProfileConfigFactory,
  translateModuleConfig
} from './app.config';
import { I18nModule, I18nModuleConfig } from '@frontmania/i18n';
import { TranslateModule } from '@ngx-translate/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppState } from './app.state';
import { FileSaverModule } from 'ngx-filesaver';
import { NotificationConfig, NotificationModule } from '@frontmania/notification';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { ObjectSearchConfig, ObjectSearchModule } from '@frontmania/object-search';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ObjectStoreMasterDataConfig, ObjectStoreMasterDataModule } from '@frontmania/object-master-data';
import { ObjectDetailsConfig, ObjectDetailsModule } from '@frontmania/object-details';
import { ObjectExportConfig, ObjectExportModule } from '@frontmania/object-export';
import { UserProfileConfig, UserProfileModule } from '@frontmania/user-profile';
import { LoginPageComponent } from './login-page/login-page.component';
import { MaintenanceInfoModule } from '@frontmania/maintenance-info';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SidebarComponent,
    LoginPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FileSaverModule,
    LayoutModule,
    NgxsModule.forRoot([
      AppState
    ], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NotificationModule.forRoot(),
    AuthModule.forRoot(),
    I18nModule.forRoot(),
    TranslateModule.forRoot(translateModuleConfig),
    ObjectDetailsModule,
    ObjectStoreMasterDataModule.forRoot(),
    ObjectSearchModule,
    ObjectExportModule.forRoot(),
    RouterModule.forRoot(routes, {
      enableTracing: false
    }),
    UserProfileModule,
    MaintenanceInfoModule,
    MatRippleModule
  ],

  providers: [{
    provide: AnythingPortalConfig,
    useFactory: appConfigFactory
  },
    {
      provide: AuthModuleConfig,
      useFactory: authModuleConfigFactory
    },
    {
      provide: ObjectDetailsConfig,
      useFactory: objectDetailsConfigFactory
    },
    {
      provide: ObjectExportConfig,
      useFactory: objectExportConfigFactory
    },
    {
      provide: ObjectSearchConfig,
      useFactory: objectSearchConfigFactory,
    },
    {
      provide: ObjectStoreMasterDataConfig,
      useFactory: objectStoreMasterDataConfigFactory
    },
    {
      provide: I18nModuleConfig,
      useFactory: i18nModuleConfigFactory
    },
    {
      provide: NotificationConfig,
      useFactory: notificationModuleConfigFactory
    },
    {
      provide: UserProfileConfig,
      useFactory: userProfileConfigFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
