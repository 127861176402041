import {Injectable} from '@angular/core';

@Injectable()
export class ObjectStoreMasterDataConfig {
  classPropertiesUrl: string;
  choiceValuesUrl: string;
  searchTemplateUrl: string;
  checkinTemplateUrl: string;
  editTemplateUrl: string;

}


