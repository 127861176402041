<div class="fm-search-input" role="group" [formGroup]="inputParts">
  <input class="value-input"
         (focusin)="focusIn()"
         (focusout)="focusOut()"
         matInput
         formControlName="searchValue"
         [placeholder]="placeholder"
         [readonly]="false"
         [required]="required"
         (input)="_handleInput()">
  <mat-select class="option-input"
              matSuffix
              formControlName="searchOperator"
              [required]="true"
              (selectionChange)="_handleInput()" [value]="value.searchOperator">
    <mat-option *ngFor="let operator of searchOperators"
                [matTooltip]="_translateTooltip(operator) | async"
                matTooltipPosition="right"
                [value]="operator.value">{{ operator.label }}</mat-option>

  </mat-select>
</div>

