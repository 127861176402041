<fm-page>
  <fm-titlebar [backButtonVisible]="false" fmTitlebar></fm-titlebar>
  <div fmContent class="flex flex-row justify-center items-center h-full">
    <div class="flex flex-col justify-center items-center">
      <div class="logo"></div>
      <h1>404</h1>
      <h1>{{ 'pageNotFound' | translate }}</h1>
    </div>
  </div>
</fm-page>

