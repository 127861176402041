import {Backend, ObjectDetails} from './object-details.model';


export class ObjectDetailsMapper {

  /**
   * Our problem: We have two different backend representations of ObjectDetails either one from solr or one from object-storage
   * To solve this, you could use the ObjectDetailsMapper
   * @param backendResult
   */
  static map( objectDetails: Backend.ObjectDetails | {[key: string]: unknown}): ObjectDetails {
    let result = {} as ObjectDetails;
    for (const propertyName of Object.getOwnPropertyNames(objectDetails)) {
      const value = objectDetails[propertyName];
      if (value) {
        switch (propertyName) {
          case 'properties': {
            const propertiesResult = ObjectDetailsMapper.map(value as { [key: string]: unknown })
            result = {...result, ...propertiesResult}
            break;
          }
          case 'created': {
            result.createdBy = value['by'];
            result.createdOn = value['on'];
            break;
          }
          case 'modified': {
            result.modifiedBy = value['by'];
            result.modifiedOn = value['on'];
            break;
          }
          case 'content': {
            result.contentName = value['name'];
            result.contentType = value['type'];
            result.contentSizeInBytes = value['sizeInBytes'];
            break;
          }
          default: {
            result[propertyName] = value;
          }
        }
      }
    }
    return result;
  }

  static mapMany(backendResult: Backend.ObjectDetails[]): ObjectDetails[] {
    if (backendResult) {
      return backendResult.map(od => ObjectDetailsMapper.map(od));
    }
    return [];
  }
}
