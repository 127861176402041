<ng-container *ngIf="(user$ | async) as currentUser">
<div class="flex flex-col sm:flex-row gap-4">
  <mat-card class="flex-auto basis-full">
    <mat-card-title>{{'profile.accountDetails.details' | translate}}</mat-card-title>
    <mat-card-content>
        <div class="info-row flex flex-row justify-start items-center">
          <div class="basis-full flex flex-row justify-start items-center">
            <mat-icon>person</mat-icon>
            <span>{{'profile.accountDetails.fullName' | translate}}</span>
          </div>
          <div class="basis-full">{{currentUser.fullName}}</div>
        </div>
        <mat-divider></mat-divider>
        <div class="info-row flex flex-row justify-start items-center">
          <div class="basis-full flex flex-row justify-start items-center">
            <mat-icon>fingerprint</mat-icon>
            <span>{{'profile.accountDetails.userName' | translate}}</span>
          </div>
          <div class="basis-full">{{currentUser.userName}}</div>
        </div>
        <mat-divider></mat-divider>
        <div class="info-row flex flex-row justify-start items-center">
          <div class="basis-full flex flex-row justify-start items-center">
            <mat-icon>mail_outline</mat-icon>
            <div>{{'profile.accountDetails.emailAddress' | translate}}</div>
          </div>
          <div class="basis-full">{{currentUser.email}}</div>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="flex-auto basis-full">
    <mat-card-title>{{'profile.accountDetails.roles' | translate}}</mat-card-title>
    <mat-card-content>
      <div class="flex flex-row flex-wrap">
        <div class="info-row-small basis-1/2" *ngFor="let role of sort(currentUser.roles)">{{role}}</div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

</ng-container>
