import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoadingInterceptor } from './app-loading/http-loading.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { BackButtonDirective } from './directives/back-button.directive';
import { FmTitlebarComponent } from './components/fm-titlebar/fm-titlebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { FmToolbarComponent } from './components/fm-toolbar/fm-toolbar.component';
import { RouterModule } from '@angular/router';
import { FmPageComponent } from './components/fm-page/fm-page.component';
import { FmSearchInputComponent } from './components/fm-search-input/fm-search-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FmMasterdataInputComponent } from './components/fm-masterdata-input/fm-masterdata-input.component';
import { FmBannerComponent } from './components/fm-banner/fm-banner.component';

@NgModule({
  declarations: [
    BackButtonDirective,
    FmTitlebarComponent,
    FmToolbarComponent,
    FmPageComponent,
    FmSearchInputComponent,
    FmMasterdataInputComponent,
    FmBannerComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatMomentDateModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTabsModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatMomentDateModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTabsModule,

    BackButtonDirective,
    FmTitlebarComponent,
    FmToolbarComponent,
    FmPageComponent,
    FmSearchInputComponent,
    FmMasterdataInputComponent,
    FmBannerComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
  ],
})
export class MaterialModule {}
