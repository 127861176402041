import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthModuleConfig } from './auth.config';


@Injectable()
export class AppIdentifierInterceptor implements HttpInterceptor {
  constructor(private config: AuthModuleConfig) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.config.appIdentifier && (!req?.url?.includes(".auth.schwarz") && !req?.url?.includes("keycloak"))) {
      const headers = req.headers.append('X-APP-IDENTIFIER', this.config.appIdentifier);
      return next.handle(req.clone({ headers }));
    }
    return next.handle(req);
  }
}
