import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, distinct, filter, Subscription, switchMap} from 'rxjs';
import {SecurityTemplateService} from './security-template.service';

@Directive({
  selector: '[authVisibleFor]'
})
export class VisibleForDirective implements OnInit, OnDestroy {

  private curSecurityTemplateName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private viewRendered: boolean;
  private subscription: Subscription

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private securityTemplateService: SecurityTemplateService) { }

  @Input() set authVisibleFor(securityTemplateName: string) {
    this.curSecurityTemplateName.next(securityTemplateName);
  }

  ngOnInit(): void {
    this.subscription = this.curSecurityTemplateName.asObservable().pipe(
      filter(template => !!template),
      distinct(),
      switchMap(template => this.securityTemplateService.accessAllowed(template))
    ).subscribe(allowed => {
      if (allowed && !this.viewRendered) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.viewRendered = true;
      }
      if (!allowed && this.viewRendered) {
        this.viewContainer.clear();
        this.viewRendered = false;
      }
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
