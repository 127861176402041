import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AnythingPortalConfig} from "./app.config";
import {Observable} from "rxjs";
import {Menu} from "./app.model";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public constructor(private http: HttpClient, private config: AnythingPortalConfig) {
  }

  loadSideMenu(): Observable<Menu> {
    return this.http.get<Menu>(this.config.sideMenuUrl.replace(":identifier", "anythingPortalSideMenu"));
  }
}
