/**
 * This is the common representation of an ObjectDetails from the backend.
 * It should be used all over the system
 */
import {ObjectAclType} from '../object-class.model';


export interface ObjectDetails {
  id: string;
  classDefinitionName: string;

  versionSeriesId: string;
  versionNumber?: number;
  isCurrentVersion: boolean;
  versionState?: VersionState;

  createdBy: string;
  createdOn: string

  modifiedBy: string;
  modifiedOn: string;

  contentName: string;
  contentType: string;
  contentSizeInBytes: number;

  hasAttachments: boolean;

  acl: ObjectAcl[];

  //all properties based on objectClasses
  [key: string]: unknown;
}

export enum VersionState {
  CURRENT = ("CURRENT"), DEPRECATED = ("DEPRECATED")
}

export class ObjectAcl {
  name: string;
  type: ObjectAclType;
  permissions: string[];
}

/**
 * Most generic representation of ObjectDetails in the world :)
 * Our problem: We have two different backend representations of ObjectDetails either one from solr or one from object-storage
 * To solve this, you could use the ObjectDetailsMapper
 */
//eslint-disable-next-line
export namespace Backend {
  export interface ObjectDetails {
    [key: string]: unknown;
  }
}
