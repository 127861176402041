import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DynamicFormControlService {

  private resetForm$$: Subject<void> = new Subject();
  private cancelForm$$: Subject<void> = new Subject();
  private sendForm$$: Subject<void> = new Subject();
  private saveForm$$: Subject<void> = new Subject();
  private formModel$$: Subject<FormGroup> = new Subject<FormGroup>();

  get onResetForm(): Observable<void> {
    return this.resetForm$$.asObservable();
  }

  get onCancelForm(): Observable<void> {
    return this.cancelForm$$.asObservable();
  }

  get onSendForm(): Observable<void> {
    return this.sendForm$$.asObservable();
  }

  get onSaveForm(): Observable<void> {
    return this.saveForm$$.asObservable();
  }

  get formInvalid(): Observable<boolean> {
    return this.formModel$$.asObservable().pipe(
        map((form) => form?.invalid)
    );
  }

  public resetForm() {
    this.resetForm$$.next();
  }

  public cancelForm() {
    this.cancelForm$$.next();
  }

  public sendForm() {
    this.sendForm$$.next();
  }

  public saveForm() {
    this.saveForm$$.next();
  }

  public formModelChanged(form: FormGroup) {
    this.formModel$$.next(form);
  }

}
