import {Component, EventEmitter, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {ObjectDetails} from '@frontmania/object-master-data';
import {ObjectDetailsState} from '../../../object-details.state';

@UntilDestroy()
@Component({
  selector: 'frontmania-object-details-version',
  templateUrl: './object-details-version.component.html',
  styleUrls: ['./object-details-version.component.scss']
})
export class ObjectDetailsVersionComponent {

  columns = ["id", "documentTitle", "modifiedBy", "modifiedOn"];

  @Output()
  selectEvent: EventEmitter<string> = new EventEmitter<string>();

  @Select(ObjectDetailsState.currentVersions) currentVersions$: Observable<ObjectDetails[]>;

}
