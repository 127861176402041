import {FieldDefinition} from '../../model/field-definition.model';
import {CurrentUser} from '@frontmania/auth';

export class FilterCvByRoleCountryStrategy {

  /**
   * we try to find the country code based on the first two-letter word found in rolename!
   * kfl-de-super-reader -> de
   * @param user
   * @private
   */
  private static getCountryCodesByUserRoles(user: CurrentUser): string[] {
    if (user.roles?.some(role => role.match("\\b[a-z]{2}\\b"))) {
      const countryList = user.roles.map(role => role.match("\\b[a-z]{2}\\b")?.toString().toLowerCase());
        return [... new Set(countryList)];
    }
    return []
  }

  /**
   * if you want to use this feature, you need choiceValues where the name choiceValue-Value starts with a two-letter code!
   * It will be filtered based on the country-letters from the users roles.
   * e.g.
   * {
   *   name: myChoiceValue
   *   values: {
   *     deRegionA: {
   *       labels: {
   *         de: 'Region A'
   *         en: 'Region A;
   *       }
   *     }
   *     roRegionZ: {
   *       labels: {
   *         de: 'Region Z'
   *         en: 'Region Z;
   *       }
   *     }
   *   }
   * }
   * @param fieldDefinition
   * @param user
   * @param isAdmin
   * @private
   */
  public apply(fieldDefinition: FieldDefinition, user: CurrentUser): void {
    const originalCVs = [...fieldDefinition.choiceValues];
    let filteredCVs = [];
    const countryCodes = FilterCvByRoleCountryStrategy.getCountryCodesByUserRoles(user);
    if (countryCodes && countryCodes.length > 0) {
      countryCodes?.forEach(code => filteredCVs = [...filteredCVs, ...originalCVs.filter(cv => cv.value.slice(0,2).toLowerCase() === code)])
      fieldDefinition.choiceValues = filteredCVs;
    }
    fieldDefinition.choiceValues = fieldDefinition.choiceValues.sort((one, two) => one.label < two.label ? -1 : 1);
  }
}
