import {ChoiceValue, ChoiceValueDefinition} from '../../api/model/choice-values.model';
import {Backend} from './choice-values.model';


export class ChoiceValuesMapper {

  static mapChoiceValuesList(source: Backend.ChoiceValueDefinition[], language: string): ChoiceValueDefinition[] {
    return source.map(cv => ChoiceValuesMapper.mapChoiceValues(cv, language));
  }

  private static mapChoiceValues(source: Backend.ChoiceValueDefinition, language: string): ChoiceValueDefinition {
    return {
      name: source.name,
      authorities: source.authorities,
      permissions: source.permissions,
      values: source.values.map(item => ChoiceValuesMapper.mapChoiceValueItem(item, language))
    }
  }

  private static mapChoiceValueItem(source: Backend.ChoiceValue, language: string): ChoiceValue {
    return {
      value: source.value,
      label: source.labels ? source.labels[language] ?? source.value : source.value
    }
  }
}
