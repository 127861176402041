<mat-toolbar color="primary" class="fm-titlebar mat-elevation-z4 flex flex-row justify-between items-center">
  <div class="flex flex-row justify-start items-center basis-full">
    <button data-cy="back-button" *ngIf="backButtonVisible" fmBackButton mat-icon-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="content w-full">
      <ng-content></ng-content>
    </div>

  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</mat-toolbar>
