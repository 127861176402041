import {Field, PropertyDefinition, ValueType} from '@frontmania/object-master-data';
import {TableColumn, TableColumnType} from "../object-search.model";

export class DataTableMapper {

  static mapToTableColumns(templateFields: Field[], currentClassFields: PropertyDefinition[]): Array<TableColumn> {
    return templateFields.map(templateField => currentClassFields.find(field => field.name === templateField.name))
      .filter(field => !!field)
      .map(field => {
        return {
          name: field.name,
          type: DataTableMapper.mapFieldType(field.valueType),
          choiceValuesName: field.choiceValuesName,
          multivalued: field.multiValued,
          unitTransformInfo: templateFields.find(f => f.name === field.name)?.unitTransformInfo
        } as TableColumn
      });
  }

  private static mapFieldType(fieldType: ValueType): TableColumnType {
    switch (fieldType) {
      case ValueType.BOOLEAN:
        return TableColumnType.BOOLEAN
      case ValueType.DATE:
        return TableColumnType.DATE
        case ValueType.FLOATING:
          return TableColumnType.FLOATING
        default:
        return TableColumnType.STRING
    }
  }

}
