
import { TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {CustomHttpTranslateLoader, I18nModuleConfig} from '@frontmania/i18n';
import {AuthModuleConfig} from '@frontmania/auth';
import {NotificationConfig} from '@frontmania/notification';
import {ObjectStoreMasterDataConfig} from '@frontmania/object-master-data';
import {ObjectSearchConfig} from '@frontmania/object-search';
import {ObjectExportConfig} from '@frontmania/object-export';
import {ObjectDetailsConfig} from "@frontmania/object-details";
import {Injectable} from "@angular/core";
import {UserProfileConfig} from "@frontmania/user-profile";

export interface ServiceURLs {
  templateService: string,
  objectStorage: string,
  i18nService: string,
  exportService: string,
  choiceValuesService: string,
  userProfileService: string
}

const API_PREFIX = "/api/"
const APP_IDENTIFIER = "anything";

const URLS: { [key: string]: string } = {}; // TODO: refactor type definition
//concrete endpoints
URLS.documentSearch = API_PREFIX + "documents/search";
URLS.objectCountByQuery = API_PREFIX + "documents/countByQuery";
URLS.classDefinitions = API_PREFIX + "classDefinitions/:name";
URLS.choiceValues = API_PREFIX + "choiceValues/anything";
URLS.objectDetails = API_PREFIX + "documents";
URLS.searchTemplate = API_PREFIX + "template/" + APP_IDENTIFIER + "/search/:templateName";
URLS.checkinTemplate = API_PREFIX + "template/" + APP_IDENTIFIER + "/eci/:templateName";
URLS.objectDetailsTemplate = API_PREFIX + "template/" + APP_IDENTIFIER + "/edit/:templateName";
URLS.sideMenuUrl = API_PREFIX + "template/:identifier/menu";
URLS.translate = API_PREFIX + "translations/" + APP_IDENTIFIER + "/frontend";
URLS.export = API_PREFIX + "jobs";
URLS.userProfile = API_PREFIX + "profiles/" + APP_IDENTIFIER + "/:name";
URLS.securtiyTemplatesForAnything = API_PREFIX + "template/" + APP_IDENTIFIER + "/security";

@Injectable()
export class AnythingPortalConfig {
  sideMenuUrl: string;
}

function normalize(url: string) {
  return url.endsWith("/") ? url.substring(0, url.length - 1) : url;
}

export const translateModuleConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: I18nTranslationLoaderFactory,
    deps: [HttpClient]
  }
};

export function appConfigFactory(): AnythingPortalConfig {
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  return {
    sideMenuUrl: baseURLTemplateService + URLS.sideMenuUrl
  };
}

export function objectDetailsConfigFactory(): ObjectDetailsConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  return {
    objectDetailsUrl: baseURLObjectStore + URLS.objectDetails
  };
}

export function objectExportConfigFactory(): ObjectExportConfig {
  const baseURLExportService = normalize(environment.serviceBaseUrls.exportService);
  return {
    exportUrl: baseURLExportService + URLS.export,
    jobUrl: baseURLExportService + URLS.export + "/{jobId}",
    scope: APP_IDENTIFIER
  };
}


export function objectSearchConfigFactory(): ObjectSearchConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  return {
    templateApiUrl: baseURLTemplateService + URLS.searchTemplate,
    dataTableColumnsUrl: baseURLTemplateService + URLS.searchTemplate,
    objectSearchUrl: baseURLObjectStore + URLS.documentSearch,
    objectCountUrl: baseURLObjectStore + URLS.objectCountByQuery,
    attachmentsUrl: baseURLObjectStore + URLS.objectDetails + "/{objectId}/attachments",
    downloadUrl: baseURLObjectStore + URLS.objectDetails + "/{objectId}/content/attachment",
    inlineContentUrl: baseURLObjectStore + URLS.objectDetails + "/{objectId}/content/inline",
    showFavoritesButton: true,
    allowSavingSearches: true,
    showBackButtonOnSearchForm: false,
    maxDocCount: 1000
  };
}

export function objectStoreMasterDataConfigFactory(): ObjectStoreMasterDataConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  const choiceValuesURL = normalize(environment.serviceBaseUrls.choiceValuesService);
  return {
    classPropertiesUrl: baseURLObjectStore + URLS.classDefinitions,
    choiceValuesUrl: choiceValuesURL + URLS.choiceValues,
    searchTemplateUrl: baseURLTemplateService + URLS.searchTemplate,
    checkinTemplateUrl: baseURLTemplateService + URLS.checkinTemplate,
    editTemplateUrl: baseURLTemplateService + URLS.objectDetailsTemplate
  };
}

function I18nTranslationLoaderFactory(http: HttpClient) {
  return new CustomHttpTranslateLoader(http, normalize(environment.serviceBaseUrls.i18nService) + URLS.translate);
}

export function notificationModuleConfigFactory(): NotificationConfig {
  return {
    fadeOutAfterMs: 10000
  }
}

export function i18nModuleConfigFactory(): I18nModuleConfig {
  return {
    supportedLocales: environment.availableLocales,
    defaultLocale: environment.defaultLocale
  }
}

export function authModuleConfigFactory(): AuthModuleConfig {
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  return {
    ...environment.oauth2,
    securityTemplateEndpoint: baseURLTemplateService + URLS.securtiyTemplatesForAnything
  };

}

export function userProfileConfigFactory(): UserProfileConfig {
  const baseUrlUserProfileService = normalize(environment.serviceBaseUrls.userProfileService);
  return {
    userProfileUrl: baseUrlUserProfileService + URLS.userProfile,
    name: "userSettings"
  };
}
