import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoadingService} from './loading.service';
import {catchError, filter, tap} from 'rxjs/operators';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {

  private requestUrls: string[] = [];

  private _excludePatterns  =  [
    'silent-renew.html',
    'openid',
    'countByQuery',
    '/masterdata'
  ]

  constructor(private loadingService: LoadingService) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.addRequest(request.url);
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap(() => {
        this.removeRequest(request.url)
      }),
      catchError(error => {
        this.removeRequest(request.url);
        return throwError(error);
      }),
    );
  }

  private addRequest(url: string) {
    setTimeout(() => {
      if (!this.requestUrls.includes(url) && !this._excludePatterns.some((value) => url.includes(value))) {
        this.requestUrls.push(url);
      }
      if (this.requestUrls.length > 0) {
        this.loadingService.updateLoadingState(true);
      }
    })
  }

  private removeRequest(url: string) {
    setTimeout(() => {
      if (this.requestUrls.includes(url)) {
        this.requestUrls.splice(this.requestUrls.indexOf(url), 1);
      }
      if (this.requestUrls.length === 0) {
        this.loadingService.updateLoadingState(false);
      }
    })
  }
}
