import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SetSideMenu, ShowSideMenu, StartPageLoaded, ToggleMenuByName} from './app.actions';
import {append, patch, updateItem} from '@ngxs/store/operators';
import {Menu, MenuState} from './app.model';

export class AppStateModel {
  menuState: MenuState[];
  menu: Menu;
  showSideMenu: boolean;
  startPageLoaded: boolean;
}

@State<AppStateModel>({
  name: 'application',
  defaults: {
    menu: undefined,
    showSideMenu: true,
    startPageLoaded: false,
    menuState: [{
      name: AppState.MAIN_SIDE_NAV,
      expanded: false
    }]
  }
})
@Injectable({
  providedIn: 'root'
})
export class AppState {

  public static readonly MAIN_SIDE_NAV = 'mainSideNav';

  @Selector()
  static sideMenu(state: AppStateModel) {
    return state.menu;
  }

  @Selector()
  static showSideMenu(state: AppStateModel) {
    return state.showSideMenu;
  }

  @Selector()
  static startPageLoaded(state: AppStateModel) {
    return state.startPageLoaded;
  }

  static menuState(name: string) {
    return createSelector([AppState], (state: AppStateModel) => {
      const menuState = state.menuState.find(menu => menu.name === name);
      return menuState ? menuState.expanded : false;
    });
  }

  @Action(ToggleMenuByName)
  private toggleMenuByName(ctx: StateContext<AppStateModel>, action: ToggleMenuByName) {
    const menuItem: MenuState = ctx.getState().menuState.find(menu => menu.name === action.name);
    if (menuItem) {
      ctx.setState(patch({
        menuState: updateItem<MenuState>(state => state.name === action.name,
          {name: menuItem.name, expanded: action.justClose === true ? false : !menuItem.expanded})
      }));
    } else {
      ctx.setState(patch({
        menuState: append<MenuState>([{name: action.name, expanded: !action.justClose}])
      }));
    }
  }

  @Action(SetSideMenu)
  private setSideMenu(ctx: StateContext<AppStateModel>, action: SetSideMenu) {
    ctx.setState(patch({
      menu: action.template
    }));
  }

  @Action(ShowSideMenu)
  private showSideMenu(ctx: StateContext<AppStateModel>, action: ShowSideMenu) {
    ctx.setState(patch({
      showSideMenu: action.show
    }));
  }

  @Action(StartPageLoaded)
  private startPageLoaded(ctx: StateContext<AppStateModel>) {
    ctx.setState(patch({
      startPageLoaded: true
    }));
  }

}
