export class ObjectClass {
  name: string;
  searchIndexName?: string;
  propertyDefinitions: PropertyDefinition[];
  versionable: boolean;
  defaultInstanceSecurity: ObjectAclDefinition[];
}

export class ObjectAclDefinition {
  name: string;
  type: ObjectAclType;
  permissions?: string[];
  stateType?: ObjectAclStateType;
}

export enum ObjectAclType {
  GROUP = "GROUP"
}

export enum ObjectAclStateType {
  DEFAULT, ADDED
}

export class PropertyDefinition {
  name: string;
  multiValued: boolean;
  mandatory: boolean;
  systemic: boolean;
  valueType: ValueType;
  choiceValuesName?: string;
}

export enum ValueType {
  STRING = 'STRING',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  LONG = 'LONG',
  FLOATING = 'FLOATING',
  PATH = 'PATH' // TODO: check if still necessary
}



