import {
  FieldDefinition,
  FieldDefinitionGroup, FieldValue,
  FormDefinition, DynamicFormInput,
  FormValue,
  ObjectDetails
} from '@frontmania/object-master-data';

export class FieldsMapper {

  static toFormDefinitionInput(formDef?: FormDefinition, existingObjectMetadata?: ObjectDetails): DynamicFormInput {
    if (!formDef || !existingObjectMetadata) {
      return new DynamicFormInput(new FormDefinition([]));
    }
    const values = FieldsMapper.toFieldValue(formDef.getAllFieldDefinitions(), existingObjectMetadata);
    return new DynamicFormInput(formDef, values)
  }

  static removeReadOnlyFields(formDef?: DynamicFormInput): DynamicFormInput {
    const nonReadOnlyFields = formDef.formDefinition.fieldDefinitionGroup.map(group =>
      new FieldDefinitionGroup(group.fieldDefinitions.filter(definition => !definition?.readonly), group.label))
      .filter(group => group.fieldDefinitions.length > 0);
    return new DynamicFormInput(new FormDefinition(nonReadOnlyFields), formDef.values);
  }

  private static toFieldValue(fieldDefs: FieldDefinition[], existingObjectMetadata: ObjectDetails): FieldValue[] {
    return fieldDefs.map(fieldDef => {
      return {
        name: fieldDef.name,
        value: existingObjectMetadata[fieldDef.name] as FormValue
      }
    });
  }
}
