import {ObjectClass, PropertyDefinition} from '../../api/model/object-class.model';
import {Backend} from './object-class.model';

export class ObjectClassMapper {

  static mapObjectClass(source: Backend.ObjectClass): ObjectClass {
    return {
      name: source.name,
      searchIndexName: source.searchEngineSettings ? source.searchEngineSettings.targetIndex : undefined,
      propertyDefinitions: [...Object.getOwnPropertyNames(source.propertyDefinitions)
        .map(propName => ObjectClassMapper.mapPropertyDefinition(source.propertyDefinitions[propName]))
      ],
      defaultInstanceSecurity: source.defaultInstanceSecurity,
      versionable: source.versionable
    }
  }

  private static mapPropertyDefinition(source: Backend.PropertyDefinition): PropertyDefinition {
    return {
      choiceValuesName: source.choiceValuesName,
      mandatory: source.mandatory,
      multiValued: source.multiValued,
      name: source.name,
      systemic: source.systemic,
      valueType: source.valueType
    }
  }
}
