import {NgModule} from '@angular/core';
import {MaterialModule} from '@frontmania/material';
import {I18nModule} from '@frontmania/i18n';
import {ObjectDetailsComponent} from './components/object-details.component';
import {ObjectStoreMasterDataModule} from "@frontmania/object-master-data";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {NgxsModule} from "@ngxs/store";
import {ObjectDetailsState} from "./object-details.state";
import {DynamicFormsModule} from "@frontmania/dynamic-forms";
import {ObjectDetailsItemComponent} from "./components/object-details-view/object-details-item/object-details-item.component";
import {ObjectDetailsAttachmentsDialogComponent} from "./components/object-details-view/object-details-attachments-dialog/object-details-attachments-dialog.component";
import {ObjectDetailsEditingComponent} from "./components/object-details-editing/object-details-editing.component";
import {ObjectDetailsViewComponent} from "./components/object-details-view/object-details-view.component";
import {authGuard, AuthModule} from '@frontmania/auth';
import {ObjectDetailsVersionComponent} from './components/object-details-view/object-details-version/object-details-version.component';
import {UnitsModule} from '@frontmania/units';
@NgModule({
  declarations: [
    ObjectDetailsComponent,
    ObjectDetailsItemComponent,
    ObjectDetailsAttachmentsDialogComponent,
    ObjectDetailsEditingComponent,
    ObjectDetailsVersionComponent,
    ObjectDetailsViewComponent],
    imports: [
        CommonModule,
        AuthModule,
        I18nModule,
        MaterialModule,
        ObjectStoreMasterDataModule,
        DynamicFormsModule,
        NgxsModule.forFeature([ObjectDetailsState]),
        RouterModule.forChild([
            {
                path: 'objectDetails/:id',
                component: ObjectDetailsComponent,
                canActivate: [authGuard]
            }
        ]),
        RouterModule,
        UnitsModule
    ]
})

export class ObjectDetailsModule {
}
