import {
  DateRangeField, DynamicFormOutput,
  FormValue,
  SearchInputField,
  SearchOperator,
  ValueType
} from '@frontmania/object-master-data';
import * as moment from 'moment';


interface QueryPart {
  operator: string;
  fieldValue: string;
}

export class QueryOperator {
  static IN = "=in=";
  static NOT_IN = "=out=";
  static EQUAL = "==";
  static NOT_EQUAL = "!=";
  static CONTAINS = "=ct=";
  static STARTS_WITH = "=sw=";
  static BETWEEN_LEFT_INCLUSIVE = "=bli=";
  static BETWEEN_INCLUSIVE = "=bi=";
  static GREATER_THAN = "=gt=";
  static GREATER_THAN_OR_EQUAL = "=ge=";
  static LESS_THAN = "=lt=";
  static LESS_THAN_OR_EQUAL = "=le=";
  static ALL = "=all=";

  static stringValue(operator: QueryOperator) {
    return operator.toString();
  }
}

export class RSQLMapper {

  static toRsql(fieldDefinitionValues: DynamicFormOutput[], objectClassNames: string[], additionalSearchTerm?: string): string {
    const rsqlQueryParts = new Map<string, QueryPart>();

    for (const entry of fieldDefinitionValues) {
      if (RSQLMapper.isEmptyValue(entry.value)) {
        continue
      }
      if (RSQLMapper.isDateField(entry)) {
        rsqlQueryParts.set(entry.fieldDefinition.name, RSQLMapper.mapDateField(entry));
        continue;
      }

      // Array Values
      if (entry.fieldDefinition.multiValued && Array.isArray(entry.value) && entry.fieldDefinition.type !== ValueType.DATE) {
        const fieldValue = entry.value.map(val => `'${val}'`).join(",");
        rsqlQueryParts.set(entry.fieldDefinition.name, {
          operator: QueryOperator.stringValue(RSQLMapper.toQueryOperator(entry.fieldDefinition.searchOperator) || QueryOperator.IN),
          fieldValue: `(${fieldValue})`
        });
        continue;
      }

      if (RSQLMapper.isSearchInput(entry.value)) {
        const value = entry.value as SearchInputField;
        rsqlQueryParts.set(entry.fieldDefinition.name, {
          operator: QueryOperator.stringValue(RSQLMapper.toQueryOperator(value.searchOperator)),
          fieldValue: `('${value.searchValue}')`
        });
        continue;
      }

      rsqlQueryParts.set(entry.fieldDefinition.name, {
        operator: QueryOperator.stringValue(RSQLMapper.toQueryOperator(entry.fieldDefinition.searchOperator) || QueryOperator.EQUAL),
        fieldValue: `('${entry.value}')`
      });
    }
    const formQueryPart: string = Array.from(rsqlQueryParts.entries())
      .filter(entry => !!entry[1]?.fieldValue)
      .map(entry => entry[0].concat(entry[1].operator.concat(entry[1].fieldValue)))
      .join(';');

    return RSQLMapper.buildClassDefinitionQuery(objectClassNames) + (formQueryPart?.length > 0 ? `;${formQueryPart}` : '') + ';isCurrentVersion==true'
      + (RSQLMapper.isEmptyValue(additionalSearchTerm) ? '' : ';' + additionalSearchTerm);
  }

  private static isSearchInput(formValue: FormValue): boolean {
    if (formValue && formValue instanceof Object) {
      return formValue['searchOperator'] !== undefined && formValue['searchValue'] !== undefined;
    }
    return false;
  }

  private static buildClassDefinitionQuery(objectClassNames: string[]) {
    const joinedQuery = objectClassNames.map(className => `classDefinitionName=='${className}'`).join(', ');
    return `(${joinedQuery})`;
  }

  private static mapDateField(fieldDefinitionValue: DynamicFormOutput): QueryPart {
    const dateRangeField = fieldDefinitionValue.value as DateRangeField;
    const from: moment.Moment = RSQLMapper.toMoment(dateRangeField.start);
    const to: moment.Moment = RSQLMapper.toMoment(dateRangeField.end);
    const operator = dateRangeField.operator;

    if (!from && !to) {
      return;
    }
    if (operator === SearchOperator.DATE_BETWEEN) {
      return {
        operator: QueryOperator.BETWEEN_INCLUSIVE,
        fieldValue: `(${from.valueOf()}, ${moment(to).add(1, 'days').valueOf()})`
      };
    }

    if (fieldDefinitionValue.fieldDefinition.multiValued && operator === SearchOperator.DATE_EQUALS) {
      return {
        operator: QueryOperator.BETWEEN_LEFT_INCLUSIVE,
        fieldValue: `(${from.valueOf()}, ${moment(from).add(1, 'days').valueOf()})`
      };
    }

    if (operator === SearchOperator.DATE_EQUALS) {
      return {
        operator: QueryOperator.BETWEEN_LEFT_INCLUSIVE,
        fieldValue: `(${from.valueOf()}, ${moment(from).add(1, 'days').valueOf()})`
      };
    }

    if (operator === SearchOperator.DATE_LESS_THAN_OR_EQUAL) {
      return {
        operator: QueryOperator.LESS_THAN_OR_EQUAL,
        fieldValue: `(${moment(from).add(1, 'days').valueOf()})`
      };
    }

    if (operator === SearchOperator.DATE_GREATER_THAN) {
      return {
        operator: QueryOperator.GREATER_THAN,
        fieldValue: `(${moment(from).add(1, 'days').valueOf()})`
      };
    }

    return {
      operator: QueryOperator.stringValue(RSQLMapper.toQueryOperator(operator)),
      fieldValue: `(${from.valueOf()})`
    };

  }

  private static isDateField(fieldValue: DynamicFormOutput) {
    return fieldValue.fieldDefinition.type === ValueType.DATE || fieldValue.fieldDefinition.type === ValueType.DATE_TIME;
  }

  //TODO: refacor me: replace SearchOperator agains QueryOperator in FieldDefinition. Adapt ALL templates in TemplateService. Kill this method here :)
  private static toQueryOperator(searchOperator: SearchOperator): QueryOperator {
    switch (searchOperator) {
      case SearchOperator.DATE_LESS_THAN: {
        return QueryOperator.LESS_THAN;
      }
      case SearchOperator.DATE_LESS_THAN_OR_EQUAL: {
        return QueryOperator.LESS_THAN_OR_EQUAL;
      }
      case SearchOperator.DATE_GREATER_THAN: {
        return QueryOperator.GREATER_THAN;
      }
      case SearchOperator.DATE_GREATER_THAN_OR_EQUAL: {
        return QueryOperator.GREATER_THAN_OR_EQUAL;
      }
      case SearchOperator.STRING_EQ: {
        return QueryOperator.EQUAL;
      }
      case SearchOperator.STRING_CONTAINS: {
        return QueryOperator.CONTAINS;
      }
      case SearchOperator.STRING_STARTS_WITH: {
        return QueryOperator.STARTS_WITH;
      }
      case SearchOperator.STRING_NOT_EQUAL: {
        return QueryOperator.NOT_EQUAL;
      }
      case SearchOperator.DATE_ARRAY_CONTAINS: {
        return QueryOperator.IN;
      }
      default: {
        return undefined;
      }
    }
  }

  private static isEmptyValue(value: FormValue) {
    if (value instanceof Array) {
      const val = value as Array<FormValue>;
      // return true if all values in array are empty!
      return val.every(el => RSQLMapper.isEmptyValue(el));
    }

    if (RSQLMapper.isSearchInput(value)) {
      return RSQLMapper.isEmptyValue( (value as SearchInputField).searchValue);
    }

    return value === undefined || value == null || value === "" || value.toString().trim() === "";
  }

  private static toMoment(value: number | moment.Moment): moment.Moment {
    if (value) {
      if (moment.isMoment(value)) {
        return value as moment.Moment;
      }
      return moment(value, moment.ISO_8601);
    }
    return undefined;
  }

}
