<div class="flex flex-col" *ngIf="!!objectAclTableModel">
    <mat-form-field *ngIf="!readOnly">
      <mat-icon matPrefix>search</mat-icon>
      <input
        data-cy="role-search"
        (keyup)="search(aclSearch.value)"
        matInput
        #aclSearch
        placeholder="{{'objectAcl.searchInput' | translate}}"
        [matAutocomplete]="auto"/>

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option data-cy="roles-results" *ngFor="let result of searchResults" [value]="result">
          {{result}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  <table mat-table [dataSource]="objectAclTableModel">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let aclEntry">
        <ng-container *ngIf="isAdded(aclEntry) && !readOnly">
          <button mat-icon-button (click)="remove(aclEntry.name)">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!isAdded(aclEntry) || (isAdded(aclEntry) && readOnly)">
          <mat-icon>lock</mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{'objectAcl.name' | translate}}</th>
      <td mat-cell *matCellDef="let aclEntry"> {{aclEntry.name}} </td>
    </ng-container>

    <ng-container *ngFor="let permission of permissionConfig" matColumnDef="{{permission}}">
      <th mat-header-cell *matHeaderCellDef>{{'objectAcl.permissions.' + permission | translate}}</th>
      <td mat-cell *matCellDef="let aclEntry">
        <ng-container
          [ngTemplateOutlet]="(isAdded(aclEntry) && !readOnly && !isPermissionReadOnly(permission)) ? permissionCheckBox : aclIndicator"
          [ngTemplateOutletContext]="{'aclEntry' : aclEntry, 'permission': permission}">
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>

  </table>

</div>

<ng-template #permissionCheckBox let-aclEntry='aclEntry' let-permission='permission'>
  <mat-checkbox
    (click)="$event.stopPropagation()"
    (change)="$event ? onPermissionSelect(aclEntry, permission, $event.checked) : null"
    [checked]="selection.isSelected(aclEntry) || aclEntry.permissions?.includes(permission)">
  </mat-checkbox>
</ng-template>

<ng-template #aclIndicator let-aclEntry='aclEntry' let-permission='permission'>
  <ng-container
    [ngTemplateOutlet]="!!aclEntry.permissions && !!aclEntry.permissions.includes(permission) ? hasPermission : missingPermission">
  </ng-container>
</ng-template>

<ng-template #hasPermission>
  <mat-icon color="primary">check</mat-icon>
</ng-template>

<ng-template #missingPermission>
  <mat-icon color="warn">clear</mat-icon>
</ng-template>
