import {Injectable} from '@angular/core';
import {AuthModuleConfig} from '../auth.config';
import {Store} from '@ngxs/store';
import {SecurityTemplate} from '../auth.model';
import {combineLatest, map, Observable, of} from 'rxjs';
import {AuthState} from '../auth.state';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class SecurityTemplateService {

  constructor(private store: Store, private config: AuthModuleConfig, private httpClient: HttpClient) { }

  /**
   * called from authVisibleFor directive
   * @param securityTemplateName
   * @return true if at least one role matches the accessRolePatter of the securityTemplate
   */
  accessAllowed(securityTemplateName: string): Observable<boolean> {
    return combineLatest([
      this.store.select(AuthState.currentUser),
      this.store.select(AuthState.securityTemplate(securityTemplateName))
    ]).pipe(
      map(([user, template]) => {
        if (!template || !user) {
          return false;
        }
        if (template) {
          for (const pattern of template.accessRolePatterns) {
            for (const role of user.roles) {
              if (role.match(new RegExp('^' + pattern + '$', "gi"))) {
                return true;
              }
            }
          }
        }
        return false;
      })
    )
  }

  /**
   * we load all securityTemplates for the application, if this.config.securityTemplateEndpoint is set correctly!
   */
  loadSecurityTemplates(): Observable<SecurityTemplate[]> {
    return this.config.securityTemplateEndpoint ? this.httpClient.get<SecurityTemplate[]>(this.config.securityTemplateEndpoint) : of([])
  }
}
