import {Routes} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {LoginPageComponent} from './login-page/login-page.component';


export const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }

];
