import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  BackendSearchResult,
  DocumentSearch,
  ObjectCount,
  ObjectCountResult,
  SearchResult
} from '../object-search.model';
import { ObjectSearchConfig } from '../object-search.config';
import { SearchResultMapper } from '../util/search-result.mapper';
import { Backend, ObjectDetails, ObjectDetailsMapper } from '@frontmania/object-master-data';
import { RSQLMapper } from './rsql-mapper';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  constructor(
    private httpClient: HttpClient,
    private config: ObjectSearchConfig
  ) {
  }

  searchDocuments(searchData: DocumentSearch): Observable<SearchResult> {
    if (searchData.pageNumber * searchData.pageSize >= this.config.maxDocCount) {
      return EMPTY;
    }
    return this.httpClient.post<BackendSearchResult>(this.config.objectSearchUrl, searchData).pipe(
      map(backendSearchResult => SearchResultMapper.map(backendSearchResult))
    );
  }

  countObjectByClasses(objectClassNames: string[]) {
    const rsql = RSQLMapper.toRsql([], objectClassNames);
    return this.countObjectsByQuery(new ObjectCount(rsql));
  }

  countObjectsByQuery(query: ObjectCount): Observable<ObjectCountResult> {
    return this.httpClient.post<ObjectCountResult>(this.config.objectCountUrl, query).pipe(
      catchError(() => of({rsql: query.rsql, count: -1}))
    )
  }


  getAttachments(objectId: string): Observable<ObjectDetails[]> {
    return this.httpClient.get<Backend.ObjectDetails[]>(this.config.attachmentsUrl.replace('{objectId}', objectId)).pipe(
      map(beDetails => ObjectDetailsMapper.mapMany(beDetails))
    )
  }

  downloadFile(objectId: string) {
    return this.httpClient.get(this.config.downloadUrl.replace('{objectId}', objectId), {
      responseType: 'blob'
    });
  }

  downloadInlineContent(objectId: string) {
    return this.httpClient.get(this.config.inlineContentUrl.replace('{objectId}', objectId), {
      responseType: 'blob'
    });
  }

  sortObjects(documents: ObjectDetails[], sortInfo:Sort): ObjectDetails[] {
    const docs = [...documents];
    if (!documents) {
      return []
    }
    if (sortInfo.direction === 'asc') {
      return docs.sort((a,b) => `${a[sortInfo.active]}` > `${b[sortInfo.active]}`? -1 : 1)
    }
    return docs.sort((a,b) => `${a[sortInfo.active]}` > `${b[sortInfo.active]}`? 1 : -1)
  }
}
