<ng-container [formGroup]="dynamicForm">

  <div class="flex flex-row justify-start gap-2" formGroupName="{{fieldDefinition.name}}">
    <mat-form-field class="basis-1/4" *ngIf="hasField('operator')" subscriptSizing="dynamic">
      <mat-label>{{'form.dateRangeOperator' | translate}}</mat-label>
      <mat-select [attr.data-cy]="'date-operator-for-' + fieldDefinition.name" formControlName="operator">
        <mat-option *ngFor="let val of dateOperators" [value]="val.name">
          {{ 'form.dateRangeOperators.' + val.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex flex-row basis-full gap-2">
      <ng-container *ngIf="hasField('start')">
        <mat-form-field class="flex-auto basis-[calc(50%-8px)]" [ngClass]="{'mat-form-field-invalid' : hasErrors()}" subscriptSizing="dynamic">
          <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
          <input [required]="mandatory" matInput [matDatepicker]="doubleDatePicker_start" formControlName="start">
          <mat-datepicker-toggle matSuffix [for]="doubleDatePicker_start"></mat-datepicker-toggle>
          <mat-datepicker #doubleDatePicker_start></mat-datepicker>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="hasField('end') && isDateRangeOperator()">
        <mat-form-field class="flex-auto basis-[calc(50%-8px)]" [ngClass]="{'mat-form-field-invalid' : hasErrors()}" subscriptSizing="dynamic">
          <mat-label>{{'objectAttributes.to' | translate}}</mat-label>
          <input [required]="mandatory" matInput [matDatepicker]="doubleDatePicker_end" formControlName="end">
          <mat-datepicker-toggle matSuffix [for]="doubleDatePicker_end"></mat-datepicker-toggle>
          <mat-datepicker #doubleDatePicker_end></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </div>
  </div>

</ng-container>
