<fm-banner *ngIf="!bannerClosed" type="warn" (bannerClose)="bannerClose()" [closed]="bannerClosed" >
  {{
  "maintenance.info.text"
    | translate:
    {
      date: maintenanceDateTimestamp | date: 'shortDate':null:locale,
      time: maintenanceDateTimestamp | date: 'shortTime':null:locale,
      duration: duration
    }
  }}
</fm-banner>


