export interface SearchState {
  name: string;
  templateName: string;
  fields: SavedSearchField[];
  id: string;
  objectClassNames: string[];
}

export interface UserSettingsResponse {
  userSettings?: UserSettingsContent
}

export interface UserSettingsContent {
  startPage?: string;
  searches?: SearchState[]
  locale?: string;
}

export class UserProfileStateModel {
  settings: UserSettingsContent;
  currentSearch: SearchState;
}

export interface SavedSearchField {
  name: string;
  value: unknown;
}
