import { Component, OnInit } from '@angular/core';
import { catchError, combineLatest, finalize, Observable } from 'rxjs';
import {
  InitObjectDetails,
  UnloadObject,
  UpdateObjectDetails,
  UpdateViewState,
  UploadNewVersion
} from '../object-details.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ObjectDetailsState } from '../object-details.state';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDefinition, ObjectDetails, ObjectEditConnector, VersionState } from '@frontmania/object-master-data';
import { ObjectDetailsService } from '../service/object-details.service';
import { FileSaverService } from 'ngx-filesaver';
import { AuthState } from '@frontmania/auth';
import { ObjectEditMetadataMapper } from '../service/object-edit-metadata-mapper';
import { NotificationType, OpenNotification } from '@frontmania/notification';
import { Select, Store } from '@ngxs/store';
import { I18nState } from '@frontmania/i18n';
import { ObjectUpdateData } from '../object-details.model';

@UntilDestroy()
@Component({
  selector: 'frontmania-object-details',
  templateUrl: './object-details.component.html',
  styleUrls: ['./object-details.component.scss']
})
export class ObjectDetailsComponent implements OnInit {

  @Select(ObjectDetailsState.currentObject) currentObject$: Observable<ObjectDetails>;
  @Select(ObjectDetailsState.isInEditView) editModeEnabled$: Observable<boolean>;

  private formDef$ = this.store.select(ObjectDetailsState.templateName).pipe(
    untilDestroyed(this),
    filter(templateName => !!templateName),
    switchMap(templateName => this.objectEditConnector.getFormDefinition(templateName))
  )

  editAllowed$ = this.store.select(AuthState.currentUser).pipe(
    map((user) => !!user.roles?.some(role => role.endsWith("-edlv-author") || role === "ecm-admin")),
  )

  showNewVersionButton$ = combineLatest(([
    this.editAllowed$,
    this.store.select(ObjectDetailsState.currentObject),
    this.store.select(ObjectDetailsState.versionable),
  ])).pipe(
    filter(([, curObject]) => !!curObject),
    map(([editAllowed, curObject, versionable]) => editAllowed && versionable && curObject.versionState === VersionState.CURRENT)
  )

  showDownloadButton$ = this.store.select(ObjectDetailsState.currentObject).pipe(
    map(object => object?.contentSizeInBytes > 0)
  )

  showEditButton$ = combineLatest(([
    this.editAllowed$,
    this.store.select(ObjectDetailsState.currentObject),
    this.formDef$,
    this.store.select(ObjectDetailsState.isInEditView)
  ])).pipe(
    filter(([, curObject]) => !!curObject),
    map(([editAllowed, curObject, formDefinition, isInEditView]) => editAllowed && ObjectDetailsComponent.hasEditableFields(formDefinition) && curObject.versionState === VersionState.CURRENT && !isInEditView)
  )

  private static hasEditableFields(formDefinition?: FormDefinition) {
    return formDefinition?.getAllFieldDefinitions()?.filter(field => !field.readonly).length > 0;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store,
              private objectEditConnector: ObjectEditConnector,
              private objectDetailsService: ObjectDetailsService,
              private fileSaverService: FileSaverService) {
  }

  ngOnInit(): void {
    //based on the id we load the objectDetails and all versions and masterdata
    this.route.paramMap.pipe(
      untilDestroyed(this),
      tap(()=> this.store.dispatch(new UnloadObject())),
      filter(params => params.has('id')),
      map(params => params.get('id')),
      switchMap(id => this.store.dispatch(new InitObjectDetails(this.store.selectSnapshot(I18nState.selectedLocale), id))),
    ).subscribe();
  }

  switchView(editView: boolean) {
    this.store.dispatch(new UpdateViewState(editView));
  }

  download(objectId: string, filename: unknown): void {
    this.objectDetailsService.downloadFile(objectId).subscribe(fileBlob => {
      this.fileSaverService.save(fileBlob, filename as string);
    })
  }

  onFileSelectedForNewVersion(fileEvent: Event) {
    const file = (<HTMLInputElement>fileEvent.target).files[0];
    if (file) {
      this.store.dispatch(new UploadNewVersion(file)).pipe(
          untilDestroyed(this),
          switchMap(() => this.store.selectOnce(ObjectDetailsState.currentObject)),
          switchMap((curObject) => this.router.navigate(['objectDetails', curObject.id])),
          catchError((error) => this.store.dispatch(new OpenNotification(NotificationType.ERROR, 'objectVersion.error', {reason: error?.error?.message})))
      ).subscribe(() => {
        this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 'objectVersion.success'));
      })
    }
  }

  uploadDetails(updateData: ObjectUpdateData) {
    const uploadData = ObjectEditMetadataMapper.toObjectDetailsUploadData(updateData);
    this.store.dispatch(new UpdateObjectDetails(uploadData)).pipe(
      untilDestroyed(this),
      switchMap(() =>this.store.selectOnce(ObjectDetailsState.currentObject)),
        catchError((error) => this.store.dispatch(new OpenNotification(NotificationType.ERROR, 'objectEdit.error', {reason: error?.message}))),
        finalize(() => this.switchView(false))
      ).subscribe(() => {
          this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 'objectEdit.success'));
      })
  }

}

