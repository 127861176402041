import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "@frontmania/material";
import {I18nModule} from "@frontmania/i18n";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {authGuard} from "@frontmania/auth";
import {AccountDetailsComponent} from "./components/profile-page/account-details/account-details.component";
import {UserSettingsComponent} from "./components/profile-page/user-settings/user-settings.component";
import {ProfilePageComponent} from "./components/profile-page/profile-page.component";
import {NgxsModule} from "@ngxs/store";
import {UserProfileState} from "./user-profile.state";
import {DeleteConfirmDialogComponent} from "./components/profile-page/user-settings/delete-confirm-dialog/delete-confirm-dialog.component";
import {UserProfileConfig} from './user-profile.config';

@NgModule({
  imports: [CommonModule,
    HttpClientModule,
    MaterialModule,
    I18nModule,
    NgxsModule.forFeature([UserProfileState]),
    RouterModule.forChild([
      {
        path: 'profile',
        component: ProfilePageComponent,
        canActivate: [authGuard]
      }
    ])
  ],
  declarations: [UserSettingsComponent, AccountDetailsComponent, ProfilePageComponent, DeleteConfirmDialogComponent],
  exports: [ProfilePageComponent],
  providers: [
    {
      provide: UserProfileConfig,
      useValue: {}
    }
  ]
})
export class UserProfileModule {
}
