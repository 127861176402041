/**
 * This is the internal datamodel used for ChoiceValues.
 */

export interface Authority {
  [key: string]: string[]
}

export interface ChoiceValueDefinition {
  name: string;
  authorities?: Authority;
  permissions?: string[];
  values: ChoiceValue[];

}

export interface ChoiceValue {
  value: string;
  label?: string;
  labels?: { [key: string]: string };

}
