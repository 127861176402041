import { Injectable } from '@angular/core';
import {
  DynamicFormOutput,
  FieldValue,
  FormDefinition,
  DynamicFormInput,
  FormValue,
  ValueType
} from '@frontmania/object-master-data';
import { SavedSearchField } from '@frontmania/user-profile';
import { FormField } from '../object-search.model';

@Injectable({
  providedIn: 'root'
})
export class FieldsService {

  /**
   * if we receive a formDefinition and we want to use it for a search form, we have to assure that fields are neither
   * mandatory nor readonly. Furter we define, that each date-filed is a date range.
   * @param formDefinition
   */
  static prepareSearchFields(formDefinition: FormDefinition) {
    formDefinition.getAllFieldDefinitions()
      .map(field => {
      if (field.type === ValueType.DATE || field.type === ValueType.DATE_TIME) {
        field.dateRange = true;
      }
      field.mandatory = false;
      field.readonly = false;
    });
  }

  /**
   * this method is used to create simple FormFields, which are stored to user settings as saved searches.
   * @param formFields
   */
  static toFormFields(formFields: DynamicFormOutput[]): FormField[] {
    return formFields.map(field => { return { name: field.fieldDefinition.name, value: field.value }})
  }

  /**
   * if we have a formDefinition and a saved search, we have to crate FormDefinitionInputs in order to handle initial
   * values for forms
   * @param fieldsFromSavedSearch
   * @param formDefinition
   */
  static toFormDefinitionInput(fieldsFromSavedSearch: SavedSearchField[], formDefinition: FormDefinition): DynamicFormInput {
    const values: FieldValue[] = [];
      formDefinition.getAllFieldDefinitions().forEach(field => {
        const fromSavedSearch = fieldsFromSavedSearch?.find(f => f.name === field.name);
        if (fromSavedSearch) {
          values.push({name: fromSavedSearch.name, value: fromSavedSearch.value as FormValue})
        }
      })
    return new DynamicFormInput(formDefinition, values);
  }
}
