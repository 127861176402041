<fm-page>
  <fm-titlebar [backButtonVisible]="false" fmTitlebar></fm-titlebar>
  <div fmContent class="flex flex-row justify-center items-center h-full">
    <div class="flex flex-col justify-center items-center">
      <div class="logo"></div>
      <h1>Logging in... please wait!</h1>
    </div>
  </div>
</fm-page>

