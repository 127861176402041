import {Action, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ObjectClassState} from './backend/object-class/object-class.state';
import {TemplateState, TemplateStateModel} from './backend/templates/template.state';
import {ChoiceValuesState} from './backend/choice-values/choice-values.state';
import {LoadCheckinTemplate, LoadEditTemplate, LoadSearchTemplate} from './backend/templates/template.actions';
import {concatMap} from 'rxjs/operators';
import {LoadObjectClasses} from './backend/object-class/object-class.actions';
import {LoadChoiceValues, RefreshChoiceValues} from './backend/choice-values/choice-values.actions';
import {
  LoadCheckinMasterData,
  LoadEditMasterData,
  LoadObjectSearchMasterData,
  RefreshAllChoiceValues,
  UpdateLanguage
} from './object-master-data.actions';

export class MasterDataStateModel {
  locale: string;
}

@State<MasterDataStateModel>({
  name: 'objectMasterData',
  defaults: {
    locale: undefined
  },
  children: [ObjectClassState, TemplateState, ChoiceValuesState]
})
@Injectable({
  providedIn: 'root'
})
export class MasterDataState {

  constructor(private store: Store) {
  }


  @Action(LoadObjectSearchMasterData)
  loadObjectSearchMasterData(ctx: StateContext<TemplateStateModel>, action: LoadObjectSearchMasterData) {
    let objectClasses: string[];
    return this.store.dispatch(new LoadSearchTemplate(action.templateName)).pipe(
      concatMap(() => {
        objectClasses = this.store.selectSnapshot(TemplateState.searchTemplateByName(action.templateName)).objectClasses;
        return this.store.dispatch(new LoadObjectClasses(objectClasses));
      }),
      concatMap(() => {
        const choiceValueNames: string[] = this.store.selectSnapshot(ObjectClassState.choiceValueNamesOfClasses(objectClasses));
        return this.store.dispatch(new LoadChoiceValues(action.language, choiceValueNames));
      })
    );
  }

  @Action(LoadCheckinMasterData)
  loadCheckinMasterData(ctx: StateContext<TemplateStateModel>, action: LoadCheckinMasterData) {
    let objectClasses: string[];
    return this.store.dispatch(new LoadCheckinTemplate(action.templateName)).pipe(
      concatMap(() => {
        objectClasses = this.store.selectSnapshot(TemplateState.checkinTemplateByName(action.templateName)).objectClasses;
        return this.store.dispatch(new LoadObjectClasses(objectClasses));
      }),
      concatMap(() => {
        const choiceValueNames: string[] = this.store.selectSnapshot(ObjectClassState.choiceValueNamesOfClasses(objectClasses));
        return this.store.dispatch(new LoadChoiceValues(action.language, choiceValueNames));
      })
    );
  }

  @Action(LoadEditMasterData)
  loadEditMasterData(ctx: StateContext<TemplateStateModel>, action: LoadEditMasterData) {
    let objectClasses: string[];
    return this.store.dispatch(new LoadEditTemplate(action.className)).pipe(
      concatMap(() => {
        objectClasses = this.store.selectSnapshot(TemplateState.editTemplateByClassName(action.className)).objectClasses;
        return this.store.dispatch(new LoadObjectClasses(objectClasses));
      }),
      concatMap(() => {
        const choiceValueNames: string[] = this.store.selectSnapshot(ObjectClassState.choiceValueNamesOfClasses(objectClasses));
        return this.store.dispatch(new LoadChoiceValues(action.language, choiceValueNames));
      })
    );
  }

  @Action(UpdateLanguage)
  updateLanguage(ctx: StateContext<TemplateStateModel>, action: UpdateLanguage) {
    return this.store.dispatch(new LoadChoiceValues(action.language, this.store.selectSnapshot(ChoiceValuesState.allChoiceValueNames), true));
  }

  @Action(RefreshAllChoiceValues)
  refreshAllChoiceValues(ctx: StateContext<TemplateStateModel>, action: RefreshAllChoiceValues) {
    return this.store.dispatch(new RefreshChoiceValues(action.language, this.store.selectSnapshot(ChoiceValuesState.allChoiceValueNames), true));
  }
}
