<fm-page>
  <fm-titlebar fmTitlebar [backButtonVisible]="showBackButton">
    <div class="flex flex-row justify-between items-center">
      <div class="basis-full" data-cy="search-heading">{{ 'objectSearch.' + templateName | translate }}</div>
      <div class="mat-body flex flex-row justify-end items-center basis-full">
        <ng-container *ngIf="(savedSearches$ | async)?.length > 0">
          <mat-form-field subscriptSizing="dynamic" >
            <mat-label>{{'objectSearch.savedSearches' | translate}}</mat-label>
            <mat-select data-cy="saved-search-selection" (selectionChange)="selectSearch($event.source.value)">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let search of savedSearches$ | async" [value]="search.id">
                {{search.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <div *ngIf="!!showFavourites">
          <button data-cy="set-as-favourite-button"
                  matTooltip="{{'objectSearch.favouriteToolTip' | translate}}" (click)=toggleStartPage()
                  mat-icon-button>
            <ng-container [ngTemplateOutlet]="!!isCurrentStartPage ? star : starBorder"></ng-container>
          </button>
        </div>
      </div>
    </div>
  </fm-titlebar>

  <div fmContent>
    <div class="scroll-container flex flex-col"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScroll()"
         [scrollWindow]="false">

      <div class="fm-background-container" *ngIf="templateName$ | async as templateName">
        <frontmania-object-search-form
          [templateName$]="templateName$"
          (executeTheSearch)="newSearchQueryReceived($event)"
          [savedSearchFields$]="fieldsFromSavedSearch$$.asObservable()"
          (saveCurrentSearch)="saveCurrentSearch($event)"
          [allowSavingSearch]="allowSavingSearches">
        </frontmania-object-search-form>

        <frontmania-dynamic-form-control-buttons
          [saveButtonEnabled]="allowSavingSearches">
        </frontmania-dynamic-form-control-buttons>
        <frontmania-object-search-results-table [templateName$]="templateName$"
                                                [rsqlSearch$]="rsqlSearch$.asObservable()">
        </frontmania-object-search-results-table>
      </div>
    </div>
  </div>


  <ng-template #star>
    <mat-icon class="star">star</mat-icon>
  </ng-template>

  <ng-template #starBorder>
    <mat-icon>star_border</mat-icon>
  </ng-template>

</fm-page>

