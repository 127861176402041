import {Pipe, PipeTransform,} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";


@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {

  options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};

  constructor(private translateService: TranslateService) {
  }

  transform(value: number | number[], options?: Intl.DateTimeFormatOptions): string | string[] {
    if (value) {
      return Array.isArray(value) ? value.map(val => this.localizeDate(val, options)) : this.localizeDate(value, options);
    }
    return undefined;
  }

  private localizeDate(value: number, options?: Intl.DateTimeFormatOptions) {
    return new Date(value).toLocaleDateString(this.translateService.currentLang, (options) ? options : this.options);
  }
}
