import {ChoiceValue} from '../../model/choice-values.model';
import {CurrentUser} from '@frontmania/auth';
import {FieldDefinition} from '../../model/field-definition.model';

export class PrefillByRoleCountryStrategy {

  /**
   * we try to find the country code based on the first two-letter word found in rolename!
   * kfl-de-super-reader -> de
   * @param user
   * @private
   */
  private static getCountryCodesByUserRoles(user: CurrentUser): string[] | null {
    const result = [... new Set(user.roles?.map(role => role.match("\\b[a-z]{2}\\b")?.toString().toLowerCase()))].filter(value => !!value)
    //we have to deal with both values, because we don't know if the field is a language or country field.
    if (result.find(item => item.toLowerCase() === 'cs')) {
      result.push('cz');
    } else if (result.find(item => item.toLowerCase() === 'cz')) {
      result.push('cs')
    }
    return result;
  }

  private static toChoiceValueValue(valueToTest: string, items: ChoiceValue[]): string {
    return items.find(item => item.value.toLowerCase() === valueToTest)?.value
  }

  public apply(fieldDefinition: FieldDefinition, user: CurrentUser): void {
    if (fieldDefinition.choiceValues) {
      const result = [];
      const countryCodes = PrefillByRoleCountryStrategy.getCountryCodesByUserRoles(user);
      countryCodes.forEach(code => {
        const value = PrefillByRoleCountryStrategy.toChoiceValueValue(code, fieldDefinition.choiceValues);
         value ? result.push(value) : undefined;
      });
      if (result.length > 0) {
        if (fieldDefinition.multiValued) {
          fieldDefinition.presetValue = result;
        } else {
          fieldDefinition.presetValue = result.shift();
        }
      }
    }
  }
}
