import { DynamicFormOutput, ObjectAclDefinition, ObjectDetails, Options } from '@frontmania/object-master-data';

export const FORM_NAME = 'objectDetails.editForm';

export interface ObjectDetailsStateModel {
  object: ObjectDetails
  templateName: string;
  versions: ObjectDetails[];
  checkinOptions: Options;
  defaultInstanceSecurity: ObjectAclDefinition[];
  inEditView: boolean;
  versionable: boolean;
  editForm: unknown;
}

export const STATE_DEFAULTS: ObjectDetailsStateModel = {
  object: undefined,
  templateName: undefined,
  versions: [],
  checkinOptions: undefined,
  defaultInstanceSecurity: [],
  inEditView: false,
  versionable: false,
  editForm: undefined
}
export interface ObjectUpdateData {
  fields: DynamicFormOutput[];
  acl?: ObjectAclDefinition[];
}
