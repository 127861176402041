import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { AuthState } from './auth.state';
import { SetRedirectUri } from './auth.actions';
import { SecurityTemplateService } from './securityTemplates/security-template.service';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';


export function authGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const securityTemplateService: SecurityTemplateService = inject(SecurityTemplateService);
    const store:Store = inject(Store);

    return this.store.dispatch(new SetRedirectUri(state.url)).pipe(
      switchMap(() => {
        const securityTemplate = route.data.securityTemplate;
        if (!securityTemplate) {
          return this.store.select(AuthState.authorized).pipe(
            filter(auth => !!auth),
          )
        }

        return combineLatest([
          securityTemplateService.accessAllowed(securityTemplate),
          store.select(AuthState.authorized)
        ]).pipe(
          filter(([accessAllowed, loggedIn]) => !!accessAllowed && !!loggedIn),
          map(([accessAllowed, loggedIn]) => accessAllowed && loggedIn)
        )
      })
    )
  }
}
