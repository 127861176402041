import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {ChoiceValuesMappingPipe} from './api/pipes/choice-values-mapping.pipe';
import {ObjectStoreMasterDataConfig} from './object-master-data.config';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ObjectClassState} from './backend/object-class/object-class.state';
import {TemplateState} from './backend/templates/template.state';
import {ChoiceValuesState} from './backend/choice-values/choice-values.state';
import {MasterDataState} from './object-master-data.state';


@UntilDestroy()
@NgModule({
  declarations: [ChoiceValuesMappingPipe],
  imports: [CommonModule,
    NgxsModule.forFeature([
      MasterDataState,
      ObjectClassState,
      TemplateState,
      ChoiceValuesState,
    ]),
  ],
  exports: [ChoiceValuesMappingPipe]
})
export class ObjectStoreMasterDataModule {

  static forRoot(config?: ObjectStoreMasterDataConfig): ModuleWithProviders<ObjectStoreMasterDataModule> {
    return {
      ngModule: ObjectStoreMasterDataModule,
      providers: [{
        provide: ObjectStoreMasterDataConfig,
        useValue: config
      }]
    };
  }

}

