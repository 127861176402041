<ng-container [formGroup]="dynamicForm">
  <ng-container *ngIf="fieldDefinition" [ngSwitch]="true">

    <mat-form-field *ngSwitchCase="isText()" subscriptSizing="dynamic">
      <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
      <input [required]="isMandatory()" matInput formControlName="{{fieldDefinition.name}}">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="isMasterDataField()" subscriptSizing="dynamic">
      <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
      <fm-masterdata-input [required]="isMandatory()" formControlName="{{fieldDefinition.name}}" [url]="masterDataOriginUrl + fieldDefinition.masterDataSource"></fm-masterdata-input>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="isSearchInput()" subscriptSizing="dynamic">
      <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
      <fm-search-input [required]="isMandatory()" formControlName="{{fieldDefinition.name}}"></fm-search-input>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="isFloating()" subscriptSizing="dynamic">
      <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
      <input [required]="isMandatory()" matInput type="number" formControlName="{{fieldDefinition.name}}">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="isChoiceValueSelect()" subscriptSizing="dynamic">
      <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
      <mat-select #select [required]="isMandatory()" [multiple]="isMultiSelect()" formControlName="{{fieldDefinition.name}}">
        <frontmania-select-all *ngIf="isMultiSelect()" [matSelect]="select"></frontmania-select-all>
        <mat-option *ngFor="let val of choiceValueOptions" [value]="val.value">
          {{val.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="isBoolean()" subscriptSizing="dynamic">
      <mat-label>{{'objectAttributes.' + fieldDefinition.name | translate}}</mat-label>
      <mat-select [required]="isMandatory()" formControlName="{{fieldDefinition.name}}">
        <mat-option>
        </mat-option>
        <mat-option [value]="true">
          {{'objectAttributes.true' | translate}}
        </mat-option>
        <mat-option [value]="false">
          {{'objectAttributes.false' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngSwitchCase="isDate()">
      <frontmania-date-field [attr.data-cy]="'form-field-' + fieldDefinition.name" [dynamicForm]="dynamicForm" [fieldDefinition]="fieldDefinition"
                             [mandatory]="isMandatory()"></frontmania-date-field>
    </div>

  </ng-container>

</ng-container>
