<ng-container *ngIf="true">
  <div class="fm-banner flex flex-row justify-start items-center" [ngClass]="type">
    <div class="text flex-auto basis-full">
      <ng-content></ng-content>
    </div>
    <div *ngIf="!hideCloseButton" class="actions">
      <button mat-icon-button (click)="close()">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
    </div>
</ng-container>

