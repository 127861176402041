import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import { tap } from 'rxjs/operators';
import {ChoiceValueDefinition} from '../../api/model/choice-values.model';
import {ChoiceValuesService} from './choice-values.service';
import {LoadChoiceValues, RefreshChoiceValues} from './choice-values.actions';


export interface ChoiceValuesStateModel {
  choiceValues: {
    [name: string]: ChoiceValueDefinition
  }
}

export const STATE_DEFAULTS: ChoiceValuesStateModel = {
  choiceValues: {}
}

@State<ChoiceValuesStateModel>({
  name: 'choiceValuesCache',
  defaults: STATE_DEFAULTS
})
@Injectable({
  providedIn: 'root'
})
export class ChoiceValuesState {

  constructor(private store: Store,
              private choiceValuesService: ChoiceValuesService) {
  }

  @Selector()
  static allChoiceValueNames(state: ChoiceValuesStateModel) {
    return Object.getOwnPropertyNames(state.choiceValues);
  }

  static choiceValueLabel(cvName: string, cvValue: string) {
    return createSelector([ChoiceValuesState], (state: ChoiceValuesStateModel) => {
      const matchingCV = state.choiceValues[cvName];
      const matchingEntry = matchingCV.values.find(val => val.value === cvValue);
      return matchingEntry.label;
    });
  }

  static choiceValuesByNames(choiceValueNames: string[]) {
    return createSelector([ChoiceValuesState], (state: ChoiceValuesStateModel) => {
      const result: ChoiceValueDefinition[] = [];
      choiceValueNames.forEach(name => result.push(state.choiceValues[name]));
      return result;
    });
  }

  @Action(LoadChoiceValues)
  loadChoiceValues(ctx: StateContext<ChoiceValuesStateModel>, action: LoadChoiceValues) {
    let cvListToLoad = [];
    if (action.forceReload && action.choiceValueNames?.length > 0) {
      cvListToLoad = action.choiceValueNames;
    } else {
      cvListToLoad = action.choiceValueNames?.filter(cvName => !ctx.getState()?.choiceValues[cvName]);
    }

    if (cvListToLoad?.length > 0) {
      return this.choiceValuesService.load(action.language, cvListToLoad).pipe(
        tap(cvList => {
          const newCVList: { [name: string]: ChoiceValueDefinition } = {};
          cvList.forEach(cvItem => newCVList[cvItem.name] = cvItem);
          ctx.patchState({choiceValues: {...ctx.getState()?.choiceValues, ...newCVList}});
        })
      );
    }
    return;
  }

  @Action(RefreshChoiceValues)
  refreshChoiceValues(ctx: StateContext<ChoiceValuesStateModel>, action: RefreshChoiceValues) {
    let cvListToLoad = [];
    if (action.forceReload && action.choiceValueNames?.length > 0) {
      cvListToLoad = action.choiceValueNames;
    } else {
      cvListToLoad = action.choiceValueNames?.filter(cvName => !ctx.getState()?.choiceValues[cvName]);
    }

    if (cvListToLoad?.length > 0) {
      return this.choiceValuesService.load(action.language, cvListToLoad).pipe(
        tap(cvList => {
          const newCVList: { [name: string]: ChoiceValueDefinition } = {};
          cvList.forEach(cvItem => newCVList[cvItem.name] = cvItem);
          ctx.patchState({choiceValues: {...ctx.getState()?.choiceValues, ...newCVList}});
        })
      );
    }
    return;
  }
}
