import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {I18nState} from '@frontmania/i18n';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {Job, JobDefinition, ReportType} from '../api/model/job.model';
import {ReportingService} from '../service/reporting.service'

@Injectable({
  providedIn: 'root'
})
export class ObjectExportService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private reportingService: ReportingService) {
  }

  exportQuery(jobName: string, query: string, fields: string[], jobType: ReportType, searchIndex: string) {

    const locale = this.store.selectSnapshot(I18nState.selectedLocale);

    const jobDefinition = new JobDefinition(
      jobName,
      jobType,
      locale,
      this.getCurrentTimezone(),
      searchIndex,
      fields
    );

    jobDefinition.query = query;
    return this.submitJob(jobDefinition);
  }

  exportIds(jobName: string, ids: string[], fields: string[], jobType: ReportType, searchIndex: string) {

    const locale = this.store.selectSnapshot(I18nState.selectedLocale);

    const jobDefinition = new JobDefinition(
      jobName,
      jobType,
      locale,
      this.getCurrentTimezone(),
      searchIndex,
      fields
    );
    jobDefinition.query = ids.map(id => 'id==' + id).join(',');
    return this.submitJob(jobDefinition);
  }

  getAllJobs(): Observable<Job[]> {
    return this.reportingService.getAllJobs();
  }

  downloadFile(job: Job): Observable<Blob> {
    return this.httpClient.get(this.reportingService.getDownloadUrl(job), {
      responseType: 'blob'
    });
  }

  private submitJob(jobDefinition: JobDefinition) {
    return this.reportingService.createJob(jobDefinition);
  }

  deleteJob(job: Job) {
    return this.reportingService.deleteJob(job);
  }

  getCurrentTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin';
  }

}
