/**
 * This is the internal datamodel used for Templates.
 */
import {ValueType} from "./object-class.model";
import {ChoiceValue} from "./choice-values.model";
import {FillStrategy, SearchOperator, UnitTransformInfo} from './template.model';
import * as moment from 'moment';

export class FormDefinition {
  constructor(public fieldDefinitionGroup: FieldDefinitionGroup[]) {}

  getAllFieldDefinitions(): FieldDefinition[] {
    return this.fieldDefinitionGroup?.map(group => group.fieldDefinitions).flat() ?? [];
  }

}

export type FormValue = number | number [] | string | string[] | boolean | boolean[] | DateRangeField | SearchInputField | moment.Moment;

export class FieldDefinition {
  name: string;
  dateRange?: boolean;
  type: ValueType;
  multiValued?: boolean;
  mandatory?: boolean;
  choiceValues?: ChoiceValue[];
  choiceValuesName?: string;
  readonly?: boolean;
  searchOperator?: SearchOperator;
  masterDataSource?: string;
  presetValue?: unknown // a value which could be set by a fillStrategy. E.G country based on role!
  unitTransformInfo?: UnitTransformInfo;
  fillStrategy?: FillStrategy;
}

export class DynamicFormInput {
  constructor(public formDefinition: FormDefinition, public values?: FieldValue[]) {}
  public getValue(name: string): FormValue | undefined {
    return this.values?.find(el => el.name === name)?.value;
  }
}

export interface DynamicFormOutput {
  fieldDefinition: FieldDefinition;
  value: FormValue
}

export interface FieldValue {
  name: string;
  value: FormValue;
}

export class FieldDefinitionGroup {
  constructor(public fieldDefinitions: FieldDefinition[], public label?: string) {}
}

export interface DateRangeField {
  start?: moment.Moment;
  end?: moment.Moment;
  operator?: SearchOperator;
}

export interface SearchInputField {
  searchValue: string;
  searchOperator: SearchOperator;
}
