import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Actions, Select, Store } from '@ngxs/store';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { TableDataSource } from './table-data-source';
import { catchError, combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ObjectDetails, ObjectSearchResultConnector } from '@frontmania/object-master-data';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ObjectExportService, ReportType } from '@frontmania/object-export';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent, PreviewData } from './preview/preview.component';
import { PreviewType } from '../../../object-search.model';
import {
  FinishLoadingDocuments,
  InitResultTable,
  SearchDocuments,
  SetSortField,
  StartLoadingDocuments,
  UpdateSelection
} from '../../../object-search.actions';
import { ObjectSearchState } from '../../../object-search.state';
import { I18nState } from '@frontmania/i18n';
import { NotificationService, NotificationType, OpenNotification } from '@frontmania/notification';

@UntilDestroy()
@Component({

  selector: 'frontmania-object-search-results-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class ObjectSearchResultsTableComponent implements OnInit, AfterViewInit {
  subs: Subscription;
  anyFilesDocs: TableDataSource;
  @ViewChild(MatSort) sort: MatSort;
  expandedDocId: string | null;
  selection = new SelectionModel<string>(true, []);
  reportType: typeof ReportType = ReportType;

  private templateName: string;
  private exportProperties: string[];

  @Input()
  templateName$: Observable<string>;

  @Input()
  rsqlSearch$: Observable<string>;

  @Select(ObjectSearchState.isLoading)
  loading$ : Observable<boolean>

  private static determinePreviewType(doc: ObjectDetails) {

    if (doc.contentType == null) {
      return PreviewType.UNSUPPORTED
    }

    if (doc.contentType.startsWith('image/') && !doc.contentType.includes('dwg')) {
      return PreviewType.IMAGE;
    }
    if ('application/pdf' === doc.contentType) {
      return PreviewType.PDF;
    }
    if (doc.contentType.startsWith('text/')) {
      return PreviewType.TEXT;
    }

    return PreviewType.UNSUPPORTED
  }

  constructor(public dialog: MatDialog,
              private actions$: Actions,
              private store: Store,
              private route: Router,
              private objectSearchResultConnector: ObjectSearchResultConnector,
              private translateService: TranslateService,
              private objectExportService: ObjectExportService,
              private cdr: ChangeDetectorRef,
              private notificationErrorHandler: NotificationService) {
    this.anyFilesDocs = new TableDataSource(this.store);
  }

  ngOnInit(): void {
    //we load all required data and init the table, everytime the templateName changes!
    this.templateName$.pipe(
      untilDestroyed(this),
      filter(template => !!template),
      switchMap(templateName => {
        this.templateName = templateName;
        return this.objectSearchResultConnector.getTemplate(templateName)
      }),
      switchMap(template => combineLatest([
        this.objectSearchResultConnector.getResultTableColumns(template.templateName),
        this.objectSearchResultConnector.getPropertyDefinitions(template.objectClasses),
        this.objectSearchResultConnector.getSearchIndexName(template.objectClasses),
        of(template)
      ])),
    ).subscribe(([tableColumns, propertyDefinitions, searchIndexName, template]) => {
      this.exportProperties = propertyDefinitions.map((def) => def.name);
      this.store.dispatch(new InitResultTable(tableColumns, propertyDefinitions, template.options, searchIndexName));
    });

    if (this.rsqlSearch$) {
      this.rsqlSearch$.pipe(
        untilDestroyed(this),
        tap(() => {
          this.selection?.clear(true);
          this.store.dispatch(new StartLoadingDocuments())
        }),
        switchMap((rql) => this.store.dispatch(new SearchDocuments(rql))),
        catchError((error, caught) => {
          this.notificationErrorHandler.handleError(error);
          this.store.dispatch(new FinishLoadingDocuments());
          this.cdr.detectChanges();
          return caught;
        }),
        tap(() => this.store.dispatch(new FinishLoadingDocuments())),
        tap(() => this.cdr.detectChanges()),
      ).subscribe();
    }

    this.store.selectOnce(ObjectSearchState.selection)
      .subscribe(selectedIds => {
        if (selectedIds) {
          this.selection = new SelectionModel<string>(true, selectedIds);
        } else {
          this.selection = new SelectionModel<string>(true, []);
        }
      });

    this.selection.changed.pipe(untilDestroyed(this)).subscribe(() => {
      return this.store.dispatch(new UpdateSelection(this.selection.selected));
    });
  }

  ngAfterViewInit(): void {
    merge(this.sort.sortChange).pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      tap((sort: Sort) => this.store.dispatch(new SetSortField(sort))),
    ).subscribe();
  }

  toggleSelection(id: string) {
    this.selection.toggle(id)
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.store.selectOnce(ObjectSearchState.loadedDocuments).subscribe(docs => {
        const ids: string[] = docs.map(doc => doc.id);
        this.selection = new SelectionModel<string>(true, ids);
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.store.selectSnapshot(ObjectSearchState.totalNumberOfDocumentsByQuery) ?? 0
    return numSelected === numRows;
  }

  selectRow(doc: ObjectDetails) {
    this.route.navigate(["objectDetails", doc.id]);
  }

  toggleExpand($event, doc: ObjectDetails) {
    $event.stopPropagation();
    this.expandedDocId = this.expandedDocId === doc.id ? null : doc.id

  }

  export(type: ReportType) {

    const datetime = new Date().toLocaleDateString(this.translateService.currentLang,
      {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});

    const illegalChars = /[/\\:*?"<>|,]/gi;
    const exportname = (this.translateService.instant("objectSearch." + this.templateName) + '-' + datetime).replace(illegalChars, '') + (type === ReportType.CSV ? "-csv" : "");

    let resultObservable;
    if (this.selection.isEmpty()) {
      resultObservable = this.objectExportService.exportQuery(exportname,
          this.store.selectSnapshot(ObjectSearchState.rsqlQuery),
          this.exportProperties,
          type,
          this.store.selectSnapshot(ObjectSearchState.index));
    } else {
      resultObservable = this.objectExportService.exportIds(exportname,
        this.selection.selected,
        this.exportProperties,
        type,
        this.store.selectSnapshot(ObjectSearchState.index));
    }
    resultObservable.pipe(
        untilDestroyed(this),
        catchError((error) => this.store.dispatch(new OpenNotification(NotificationType.ERROR, "objectExport.submitError", {reason: error?.message})))
    ).subscribe(() => {
      this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, "objectExport.success", {jobName: exportname}));
    })
    this.selection.clear();
  }

  tableHidden() {
    return combineLatest(([
      this.store.select(ObjectSearchState.isLoading),
      this.store.select(ObjectSearchState.loadedDocuments)
    ])).pipe(
      distinctUntilChanged(),
      map(([loading, documents]) => !loading && documents === undefined )
    )
  }

  showPreview($event, doc: ObjectDetails) {
    $event.stopPropagation();
    this.dialog.open(PreviewComponent, {
      height: "75%",
      minWidth: '50%',
      data: {objectId: doc.id, type: ObjectSearchResultsTableComponent.determinePreviewType(doc)} as PreviewData
    });
  }

  supportsPreview(doc: ObjectDetails) {
    if (doc.contentSizeInBytes < 1) {
      return false;
    }
    return ObjectSearchResultsTableComponent.determinePreviewType(doc) !== PreviewType.UNSUPPORTED;
  }

  asStringValue(value: unknown) {
    return value as string;
  }

  selectedLocale(): string {
    return this.store.selectSnapshot(I18nState.selectedLocale);
  }

}
