import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'fm-banner',
  templateUrl: './fm-banner.component.html',
  styleUrls: ['./fm-banner.component.scss'],
})
export class FmBannerComponent {
  @Output()
  bannerClose: Subject<boolean> = new Subject<boolean>();
  @Input()
  type: BannerType = 'warn';
  @Input()
  closed = false;

  @Input()
  hideCloseButton = false;


  close() {
    this.bannerClose.next(true);
    this.closed = true;
  }
}

export type BannerType = 'warn' | 'info';
