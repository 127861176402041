<div class="form-container" *ngIf="formReady">
  <form [ngxsForm]="formName" [formGroup]="formModel" (ngSubmit)="submit()">

    <div class="form-layout flex flex-col justify-start">
      <div class="form-group flex flex-col"
           *ngFor="let group of formDefinitionInput.formDefinition.fieldDefinitionGroup">
        <div class="form-group-header">{{(group.label | translate) || '&nbsp;'}}</div>
        <div class="form-group-content flex flex-col justify-start sm:flex-row sm:flex-wrap basis-full gap-5">
          <ng-container *ngFor="let fieldDef of fieldDefinitionsOfGroup(group)">
            <frontmania-form-field [attr.data-cy]="'form-field-' + fieldDef.name"
                                   class="basis-full flex-initial sm:basis-[calc(50%-20px)] md:basis-[calc(33%-20px)] lg:basis-[calc(25%-20px)]"
                                   [fieldDefinition]="fieldDef"
                                   [dynamicForm]="formModel">
            </frontmania-form-field>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
