import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {LoadingService} from '../../app-loading/loading.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fm-titlebar',
  templateUrl: './fm-titlebar.component.html',
  styleUrls: ['./fm-titlebar.component.scss']
})
export class FmTitlebarComponent {

  @Input()
  backButtonVisible = true

  isLoading = false;



  constructor(private loadingService: LoadingService, private ref: ChangeDetectorRef) {
    loadingService.isLoading().pipe(untilDestroyed(this)).subscribe(loading => {
        this.isLoading = loading;
        ref.detectChanges();
      }
    );
  }
}
