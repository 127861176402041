import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {FileSizePipe} from './custom-pipes/file-size/file-size.pipe';
import {SizeUnit} from './custom-pipes/file-size/size-unit.enum';
import {UnitTransformConverter, UnitTransformInfo} from '@frontmania/object-master-data';

@Pipe({
  name: 'superUnit'
})
export class SuperUnitPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private fileSizePipe: FileSizePipe,
    private datePipe: DatePipe
  ) {
  }

  public transform(value: unknown, locale: string, unitTransform?: UnitTransformInfo): unknown  {
    if (!value) {
      return undefined;
    }

    if (!unitTransform) {
      return Array.isArray(value) ? value.join(', ') : value;
    }

    if (Array.isArray(value)) {
      return value.map(entry => this.transformValueInternal(entry, locale, unitTransform)).join(', ')
    }
    else {
      return this.transformValueInternal(value, locale, unitTransform);
    }
  }


  private transformValueInternal(value: unknown, locale: string, unitTransform: UnitTransformInfo) {

      switch (unitTransform.converter) {
        case UnitTransformConverter.CURRENCY: return this.currency(value as number, unitTransform, locale);
        case UnitTransformConverter.NUMERIC: return this.numeric(value  as number, unitTransform, locale);
        case UnitTransformConverter.FILE_SIZE: return this.fileSize(value  as number, unitTransform, locale);
        case UnitTransformConverter.DATE:  return this.date(value as number, locale);
      }

      throw new Error(`No Converter found for: '${unitTransform.converter}'!`)
  }


  private currency(value: number, unitTransform: UnitTransformInfo, locale: string) {
    return this.currencyPipe.transform(value, unitTransform.unitSymbol, undefined, unitTransform.digitInfo, locale) || ''
  }

  private numeric(value: number, unitTransform: UnitTransformInfo, locale: string) {
    return this.decimalPipe.transform(value, unitTransform.digitInfo, locale) || '';
  }

  private fileSize(value: number, unitTransform: UnitTransformInfo, locale: string) {
    return this.fileSizePipe.transform(value, locale, SizeUnit.Auto, unitTransform.digitInfo) || '';
  }

  private date(value: number, locale: string) {
    return this.datePipe.transform(value, 'shortDate', undefined, locale) || '';
  }

}
