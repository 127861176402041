<fm-page>
  <fm-titlebar fmTitlebar></fm-titlebar>
  <div fmContent class="flex flex-row justify-center">
    <div class="layout-container flex flex-col gap-4">
      <frontmania-account-details class="basis-full" [user$]="currentUser$">
      </frontmania-account-details>

      <ng-container *ngIf="showSettings">
        <frontmania-user-settings class="basis-full"
                                  (deleteSavedSearch)="deleteSearch($event)"
                                  [currentSettings$]="currentSettings$">
        </frontmania-user-settings>
      </ng-container>
    </div>
  </div>
</fm-page>



