import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { I18nState } from '@frontmania/i18n';
import { CountObjectsByClasses, InitObjectSearch, NextPage } from '../../object-search.actions';
import { ObjectSearchState } from '../../object-search.state';
import { AddSearch, SavedSearchField, SearchState, SetStartPage, UserProfileState } from '@frontmania/user-profile';
import { ObjectSearchConfig } from '../../object-search.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SearchData } from '../../object-search.model';
import { ObjectSearchService } from '../../api/object-search.service';
import { ObjectSearchConnector } from '@frontmania/object-master-data';
import { AuthState } from '@frontmania/auth';


@UntilDestroy()
@Component({
  selector: 'frontmania-object-search',
  templateUrl: './object-search.component.html',
  styleUrls: ['./object-search.component.scss']
})
export class ObjectSearchComponent implements OnInit {

  @Select(ObjectSearchState.templateName)
  templateName$: Observable<string>

  fieldsFromSavedSearch$$: BehaviorSubject<SavedSearchField[]> = new BehaviorSubject<SavedSearchField[]>(undefined);
  rsqlSearch$: Subject<string> = new Subject<string>();
  isCurrentStartPage: boolean;
  templateName: string;
  showFavourites: boolean;
  savedSearches$: Observable<SearchState[]>;
  allowSavingSearches: boolean;
  showBackButton: boolean

  constructor(private route: ActivatedRoute,
              private objectSearchService: ObjectSearchService,
              private objectSearchConnector: ObjectSearchConnector,
              private store: Store,
              private router: Router,
              private config: ObjectSearchConfig) {
    this.showFavourites = this.config.showFavoritesButton;
    this.allowSavingSearches = this.config.allowSavingSearches;
    this.showBackButton = this.config.showBackButtonOnSearchForm ?? true
  }

  ngOnInit(): void {
    combineLatest([
      this.route.paramMap,
      this.store.select(AuthState.authorized),
      this.store.select(UserProfileState.startPage)
    ]).pipe(
      untilDestroyed(this),
      filter(([, auth,]) => !!auth),
      tap(([paramMap, ,  currentStartPage]) => {
        this.isCurrentStartPage = this.router.url === currentStartPage;
        this.templateName = paramMap.get('templateName');
      }),
      tap(() => this.savedSearches$ = this.store.select(UserProfileState.savedSearches(this.templateName))),
      filter(() => {
        const previousTemplateName = this.store.selectSnapshot(ObjectSearchState.templateName);
        return this.templateName !== previousTemplateName;
      }),
      withLatestFrom(this.store.select(I18nState.selectedLocale)),
      switchMap(([, language]) => this.store.dispatch(new InitObjectSearch(language, this.templateName)))
    ).subscribe();

    this.templateName$.pipe(
      filter(template => !!template),
      untilDestroyed(this),
      distinctUntilChanged(),
      switchMap(template => this.objectSearchConnector.getSearchTemplate(template)),
      switchMap(template => this.store.dispatch(new CountObjectsByClasses(template?.objectClasses)))
    ).subscribe();
  }

  /**
   * called from object-search-form, when Search-Button is pressed!
   * @param rsqlSearch
   */
  newSearchQueryReceived(rsqlSearch: string) {
    this.rsqlSearch$.next(rsqlSearch);
  }

  toggleStartPage() {
    this.store.dispatch(new SetStartPage(this.router.url));
  }

  saveCurrentSearch(searchData: SearchData) {
    this.store.dispatch(new AddSearch({
      id: searchData.id,
      name: searchData.name,
      templateName: this.templateName,
      fields: searchData.formFields,
      objectClassNames: searchData.objectClassNames
    }));
  }

  selectSearch(id: string) {
    if (id) {
      this.objectSearchService.clearState();
      const search = this.store.selectSnapshot(UserProfileState.selectSearch(id));
      this.fieldsFromSavedSearch$$.next(search.fields);
    } else {
      this.fieldsFromSavedSearch$$.next(undefined);
    }

  }

  onScroll() {
    this.store.dispatch(new NextPage());
  }
}
