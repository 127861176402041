<mat-tab-group mat-stretch-tabs="false">
  <mat-tab [label]="'objectDetails.tabs.document-properties' | translate">
    <div class="tab-content">
      <frontmania-dynamic-form
        [formDefinitionInput]="formDefinition$ | async"
        [formName]="formName"
        (submitEvent)="submitForm($event)"
        (resetEvent)="cancel()">
      </frontmania-dynamic-form>
    </div>

  </mat-tab>
  <mat-tab [label]="'objectDetails.tabs.permissions' | translate">
    <div class="tab-content">
      <frontmania-acl-editor *ngIf="showPermissions$ | async"
                             [aclData$]="initialAcl$"
                             [reset$]="triggerReset$$.asObservable()"
                             (updateAcl)="setCurrentAcl($event)">
      </frontmania-acl-editor>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="button-area">
  <frontmania-dynamic-form-control-buttons class="button-area"
                                           [resetButtonVisible]="false"
                                           [cancelButtonVisible]="true"
  ></frontmania-dynamic-form-control-buttons>
</div>

