<div class="layout-container flex flex-col">
  <div mat-dialog-content class="flex flex-col">
    <mat-form-field>
      <mat-label>{{'objectSearch.saveSearchDialog.hint' | translate}}</mat-label>
      <input data-cy="search-name-input" matInput #inputText [(ngModel)]="data.searchIdentifier">
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="flex flex-col">
    <button class="basis-full" data-cy="save-search-confirm-button" mat-raised-button color="primary" [disabled]="!inputText.value"
            [mat-dialog-close]="data.searchIdentifier">{{'objectSearch.saveSearchDialog.ok' | translate}}
    </button>
  </div>
</div>
