export enum NotificationType {
  ERROR, SUCCESS
}

export interface NotificationPlaceholders {
  [key: string]: string
}

export interface NotificationData {
  type: NotificationType,
  messageKey: string,
  placeholders: NotificationPlaceholders
}
