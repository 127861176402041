import {NotificationPlaceholders, NotificationType} from "./notification.model";

export class CloseNotification {
  static readonly type = '[Notification] Close';

}

export class OpenNotification {
  static readonly type = '[Notification] Open';

  constructor(public type: NotificationType, public messageKey: string, public placeholders?: NotificationPlaceholders) {
  }
}
