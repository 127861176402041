import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NgxsModule, Select } from '@ngxs/store';
import { I18nState } from './i18n.state';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { I18nModuleConfig } from './i18n.config';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorLanguageSwitcher } from './paginator-language-switcher';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { LocalizeDatePipe } from './localize-date.pipe';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import { MatSelectModule } from '@angular/material/select';

registerLocaleData(localeDe, localeEn);

@NgModule({
  declarations: [LanguageSelectComponent, LocalizeDatePipe],
  imports: [
    CommonModule,
    NgxsModule.forFeature([I18nState]),
    TranslateModule.forChild(),
    MatSelectModule
  ],
  providers: [
    I18nModuleConfig,
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorLanguageSwitcher
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [
        MAT_DATE_LOCALE
      ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    LocalizeDatePipe

  ],
  exports: [
    LanguageSelectComponent,
    TranslateModule,
    LocalizeDatePipe
  ]
})
export class I18nModule {

  @Select(I18nState.selectedLocale) selectedLocale$;

  constructor(config: I18nModuleConfig, private dateAdapter: DateAdapter<Moment>) {

    if (!config.supportedLocales) {
      throw new Error('i18NModule is not configured correctly! Use I18NModule.forRoot(...) to configure supported locales!');
    }

    this.selectedLocale$.subscribe((locale) => {
      if (locale) {
        this.dateAdapter.setLocale(locale);
      }
    });
  }

  static forRoot(config?: I18nModuleConfig): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [{
        provide: I18nModuleConfig,
        useValue: config
      }]
    };
  }
}
