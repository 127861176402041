import { Component, ElementRef, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  ComponentSearchOperator,
  DEFAULT_COMPONENT_SEARCH_OPERATORS,
  SearchInputValue,
  SearchOperator
} from './fm-search-input.model';
import { Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fm-search-input',
  templateUrl: './fm-search-input.component.html',
  styleUrls: ['./fm-search-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: FmSearchInputComponent}]
})
export class FmSearchInputComponent implements MatFormFieldControl<SearchInputValue>, ControlValueAccessor, OnDestroy {
  static nextId = 0;
  readonly autofilled: boolean;
  readonly controlType: string = 'fm-search-input'
  private _disabled: boolean;
  private _placeholder: string;
  private _touched: boolean;
  private _focused = false;
  private _readonly: boolean;
  private _required: boolean;
  searchOperators: ComponentSearchOperator[] = DEFAULT_COMPONENT_SEARCH_OPERATORS;

  private _stateChanges: Subject<void> = new Subject<void>();
  get stateChanges() {
    return this._stateChanges.asObservable();
  }
  userAriaDescribedBy: string;
  id = `fm-search-input-${FmSearchInputComponent.nextId++}`;

  inputParts: FormGroup;

  onTouched = () => {
    //do nothing by default
  };

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  onChange = (value: SearchInputValue | null) => {
    //do nothing by default
  };

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  onContainerClick(event: MouseEvent): void {
    // not required
  }

  constructor(private fb: FormBuilder,
              private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl,
              @Optional() private translateService: TranslateService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.inputParts =  fb.group({
      searchValue: '',
      searchOperator: '=='
    });
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = disabled;
    disabled ? this.inputParts.controls['searchValue'].disable() : this.inputParts.controls['searchValue'].enable();
    this._stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this._stateChanges.next();
  }

  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(ro) {
    this._readonly = ro;
    this._stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = req;
    this._stateChanges.next();
  }

  @Input()
  get value() {
    return this.inputParts.value as SearchInputValue;
  }
  set value(value:SearchInputValue | null) {
    const val: SearchInputValue = value ?? {searchValue: '', searchOperator:SearchOperator.STRING_EQ};
    this.inputParts.setValue(val);
    this._stateChanges.next();
  }

  get errorState(): boolean {
    return this.inputParts.invalid && this._touched;
  }

  get empty(): boolean {
    return !this.inputParts.controls['searchValue'].value
  }

  get touched(): boolean {
    return this._touched;
  }

  get focused(): boolean {
    return this._focused;
  }

  get shouldLabelFloat() {
    return this._focused || !this.empty;
  }


  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef?.nativeElement?.querySelector('.fm-search-input');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  ngOnDestroy(): void {
    this._stateChanges.complete();
  }

  focusIn() {
    if (!this._focused && !this._disabled) {
      this._focused = true;
      this._stateChanges.next();
    }
  }

  focusOut() {
    this._touched = true;
    this._focused = false;
    this.onTouched();
    this._stateChanges.next();
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (value: SearchInputValue | null) => void): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(value:SearchInputValue | null): void {
    this.value = value;
  }

  _handleInput() {
    this.onChange(this.value);
  }

  _translateTooltip(operator: ComponentSearchOperator): Observable<string | null> {
    if (this.translateService) {
      return this.translateService.get(`fmSearchInput.${operator.value.toString()}`)
    }
    return of(null);
  }
}
