<ng-container *ngIf="(fieldDefinitionGroups$ | async) as groups">
  <mat-tab-group mat-stretch-tabs="false">

    <mat-tab [label]="(firstFieldDefinitionGroup.label || 'objectDetails.tabs.document-properties') | translate">
      <div class="property-display">
        <div class="flex flex-col justify-start sm:flex-row sm:flex-wrap sm:gap-5 basis-full">
          <ng-container *ngFor="let field of firstFieldDefinitionGroup.fieldDefinitions">
            <frontmania-object-details-item class="flex-initial basis-full sm:basis-[calc(50%-20px)] md:basis-[calc(33%-20px)] lg:basis-[calc(25%-20px)]" [fdValue]="toFieldDefinitionValue(field)">
            </frontmania-object-details-item>
          </ng-container>
        </div>
      </div>
    </mat-tab>

    <ng-container *authVisibleFor="objectDetailsAdminAccess">
      <mat-tab *ngFor="let group of groups; let i = index" [label]="(group.label || 'objectDetails.tabs.document-properties') | translate">
        <div class="property-display">
          <div class="flex flex-col justify-start sm:flex-row sm:flex-wrap sm:gap-5 basis-full">
            <ng-container *ngFor="let field of group.fieldDefinitions">
              <frontmania-object-details-item class="flex-initial basis-full sm:basis-[calc(50%-20px)] md:basis-[calc(33%-20px)] lg:basis-[calc(25%-20px)]" [fdValue]="toFieldDefinitionValue(field)">
              </frontmania-object-details-item>
            </ng-container>
          </div>
        </div>
      </mat-tab>
    </ng-container>

    <ng-container *authVisibleFor="objectDetailsAdminAccess">
      <mat-tab [label]="'objectDetails.tabs.permissions' | translate" *ngIf="showPermissions$ | async">
        <frontmania-acl-editor
          [aclData$]="aclData$"
          [readOnly]="true">
        </frontmania-acl-editor>
      </mat-tab>
    </ng-container>

    <mat-tab [label]="'objectDetails.tabs.versions' | translate" *ngIf="hasVersions$ | async">
        <frontmania-object-details-version></frontmania-object-details-version>
    </mat-tab>

    <mat-tab [label]="'objectDetails.tabs.attachments' | translate" *ngIf="hasAttachments$ | async">
      <ng-template matTabContent>
        <frontmania-object-details-attachments-dialog></frontmania-object-details-attachments-dialog>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
