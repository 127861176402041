import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {SearchState, UserSettingsContent} from "../../../user-profile.model";
import {DeleteConfirmDialogComponent} from "./delete-confirm-dialog/delete-confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'frontmania-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent implements OnInit {

  @Input()
  currentSettings$: Observable<UserSettingsContent>
  @Output()
  deleteSavedSearch: EventEmitter<string> = new EventEmitter<string>();
  settings: UserSettingsContent;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
    this.currentSettings$.pipe(
      untilDestroyed(this)
    ).subscribe(settings => {
      this.settings = settings;
    })
  }

  deleteSearch(id: string, name: string) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {name: name}
    });
    dialogRef.afterClosed().pipe(
      untilDestroyed(this)
    ).subscribe(searchName => {
      if (searchName === name) {
        this.deleteSavedSearch.emit(id);
      }
    });
  }

  format(startPage: string) {
    return startPage.substr(startPage.lastIndexOf("/") + 1);
  }

  group(searches: SearchState[]): { [key: string]: SearchState[] } {
    const groupedSearches = {};
    searches.forEach(search => {
      if (!groupedSearches[search.templateName]) {
        groupedSearches[search.templateName] = [search];
      } else {
        groupedSearches[search.templateName].push(search);
      }
    });
    return groupedSearches;
  }

  sort(value: SearchState[]) {
    const stateCopy = [...value];
    return stateCopy.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
}
