export interface MaintenanceConfig {
  enabled: boolean,
  //Timestamp when the maintenance officially starts
  maintenanceDate: number,
  //Milliseconds before the banner should be visible but closable until...
  showInfoLeadTime: number,
  //Milliseconds before the banner should be visible and closable for the current session
  forceShowInfoLeadTime: number,
  // maintenance duration used in the banner text
  durationInHours: number
}

export interface Countdown {
  hours: number,
  minutes: number;
  seconds: number;
}
