import {Field, Options, PropertyDefinition} from '@frontmania/object-master-data';
import { ObjectCount } from './object-search.model';
import { Sort } from '@angular/material/sort';

export class InitResultTable {
  static readonly type = '[ObjectSearch] Init Result Table';

  constructor(public templateFields: Field[], public classFields: PropertyDefinition[], public options?: Options, public searchIndexName?: string) {
  }
}

export class SearchDocuments {
  static readonly type = '[ObjectSearch] SearchDocuments';
  constructor(public rsqlQuery: string, public appendResult = false) {}
}

export class SetSortField {
  static readonly type = '[ObjectSearch] SetSortField';
  constructor(public sorting: Sort) {}
}

export class NextPage {
  static readonly type = '[ObjectSearch] NextPage';
}

export class CountObjectsByQuery {
  static readonly type = '[ObjectSearch] CountObjectsByQuery'
  constructor(public rsql: ObjectCount) {}
}

export class CountObjectsByClasses {
  static readonly type = '[ObjectSearch] CountObjectsByClasses'
  constructor(public objectClasses: string[]) {}
}

export class ClearResultTable {
  static readonly type = '[ObjectSearch] ClearResultTable';
}

export class StartLoadingDocuments {
  static readonly type = '[ObjectSearch] StartLoadingDocuments';
}

export class FinishLoadingDocuments {
  static readonly type = '[ObjectSearch] FinishLoadingDocuments';
}


export class UpdateSelection {
  static readonly type = '[ObjectSearch] UpdateSelection';

  constructor(public selection: string[]) {
  }
}

export class InitObjectSearch {
  static readonly type = '[ObjectSearch] Init';

  constructor(public language: string, public templateName: string) {
  }
}
