/**
 * This model-class represents the backend datastructure of an Export Job
 * It will be mapped to the API ObjectClass located in the API package.
 */

export enum ReportType {
  CSV = 'CSV',
  ZIP = 'ZIP'
}

export class JobDefinition {
  constructor(public name: string,
              public reportType: ReportType,
              public locale: string,
              public timeZone: string,
              public searchIndex: string,
              public fieldsToExport?: string[],
              public query?: string,
              public scope?: string) {
  }
}

export interface IJob {
  id: string;
  jobDefinition: JobDefinition;
  expiryDate: number;
  stateTransitions: IStateTransitions[];

  currentState: string;
  startDate: number;
  endDate: number;
  runTime: number;
}

export class Job {
  id: string;
  jobDefinition: JobDefinition;
  expiryDate: number;
  stateTransitions: IStateTransitions[];

  constructor(job?: IJob,
    id?: string,
    jobDefinition?: JobDefinition,
    expiryDate?: number,
    stateTransitions?: IStateTransitions[]) {
      this.id = (id || job['id']);
      this.jobDefinition = (jobDefinition || job['jobDefinition']);
      this.expiryDate = (expiryDate || job['expiryDate']);
      this.stateTransitions = (stateTransitions || job['stateTransitions']);
    }

  get currentState(): string {
    return this.stateTransitions[(this.stateTransitions.length - 1)].state;
  }

  get startDate(): number {
    return this.stateTransitions[0].transitionDate;
  }

  get endDate(): number {
    return this.stateTransitions[(this.stateTransitions.length - 1)].transitionDate;
  }

  get runTime(): number {
    return (this.endDate - this.startDate);
  }
}

export enum JobState {
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  DELETING = 'DELETING'
}

export interface IStateTransitions {
  state: JobState;
  transitionDate: number;
}
