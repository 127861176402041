import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Backend,
  FieldDefinition,
  FieldDefinitionGroup, DynamicFormOutput,
  FormValue, ObjectEditConnector
} from '@frontmania/object-master-data';
import { Observable, switchMap, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ObjectDetailsState } from '../../object-details.state';
import { SecurityTemplates } from '@frontmania/auth';
import { AclMapper } from '../../service/acl-mapper';
import ObjectDetails = Backend.ObjectDetails;

@UntilDestroy()
@Component({
  selector: 'frontmania-object-details-view',
  templateUrl: './object-details-view.component.html',
  styleUrls: ['./object-details-view.component.scss']
})
export class ObjectDetailsViewComponent {

  @Select(ObjectDetailsState.hasVersions)
  hasVersions$: Observable<boolean>

  /**
   * A crazy hack: Each FieldDefinitionGroup is rendered as a new TabSheet.
   * This could be done in a "ngFor" in the template. But then, the MatTabGroup is not instantiated correctly.
   * Because while it renders, the ngFor is still running and MatTabGroup doesn't know anything about these tabs; hence
   * the selectedIndex is incorrect and never set to the first element of the document attributes!
   *
   * Solution: We take the first FieldDefinitionGroup and render it statically within our MatTabGroup.
   * Now it is available during rendering and the index will be set correctly! Puuuuh!
   */
  firstFieldDefinitionGroup: FieldDefinitionGroup;
  fieldDefinitionGroups$ = this.store.select(ObjectDetailsState.templateName).pipe(
    untilDestroyed(this),
    filter(templateName => !!templateName),
    switchMap(templateName => this.objectDetailsConnector.getFormDefinition(templateName)),
    filter(formDef => formDef?.fieldDefinitionGroup?.length > 0),
    map(formDef => {
      const groups = [...formDef.fieldDefinitionGroup];
      this.firstFieldDefinitionGroup = groups.shift()
      return groups;
    })
  )

  aclData$ = this.store.select(ObjectDetailsState.currentObject).pipe(
    withLatestFrom(this.store.select(ObjectDetailsState.defaultInstanceSecurity)),
    filter(([details, defaultInstanceSecurity]) => !!details && !!defaultInstanceSecurity),
    map(([details, defaultInstanceSecurity]) => AclMapper.mapAcl(details, defaultInstanceSecurity))
  );

  showPermissions$ = this.store.select(ObjectDetailsState.checkinOptions).pipe(
    map(options => options?.showPermissions)
  )

  hasAttachments$ = this.store.select(ObjectDetailsState.currentObject).pipe(
    map(object => object?.hasAttachments)
  )

  constructor(private dialog: MatDialog, private store: Store, private objectDetailsConnector: ObjectEditConnector) {
  }

  get objectDetailsAdminAccess() {
    return SecurityTemplates.OBJECT_DETAILS_ADMIN_ACCESS;
  }

  toFieldDefinitionValue(fDef: FieldDefinition): DynamicFormOutput {
    const objectDetails: ObjectDetails= this.store.selectSnapshot(ObjectDetailsState.currentObject);
    if (objectDetails) {
      return {
        fieldDefinition: fDef,
        value: objectDetails[fDef.name] as FormValue ?? undefined
      }
    }
  }
}
