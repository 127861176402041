import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {CurrentUser} from "@frontmania/auth";

@Component({
  selector: 'frontmania-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent {

  @Input()
  user$: Observable<CurrentUser>

  sort(roles: string[]) {
    if (!roles) {
      return [];
    }
    const copy = [...roles];
    return copy.sort();
  }
}
