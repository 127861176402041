import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ObjectAclDefinition, ObjectAclStateType, ObjectAclType} from '@frontmania/object-master-data';
import {AddAcl, ClearAcl, InitAcl, RemoveAcl, RemoveForm, UpdatePermission} from './dynamic-forms.actions';
import {insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import { DynamicFormModel } from './dynamic-forms.model';

interface DynamicFormsStateModel {
  currentAcl: ObjectAclDefinition[],
  form: DynamicFormModel
}

const STATE_DEFAULTS = {
  form: undefined,
  currentAcl: []
}

@State<DynamicFormsStateModel>({
  name: 'dynamicForms',
  defaults: STATE_DEFAULTS
})
@Injectable({
  providedIn: 'root'
})
export class DynamicFormsState {

  static formModel(formName: string) {
    return createSelector([DynamicFormsState], (state: DynamicFormsStateModel) => {
      return state[formName].model;
    });
  }

  @Selector()
  static currentAcl(state: DynamicFormsStateModel) {
    return this.sort([...state.currentAcl]);
  }

  private static sort(initialAcl: ObjectAclDefinition[]) {
    return initialAcl.sort((one, two) => {
      if (one.name < two.name) {
        return -1;
      }
      if (one.name > two.name) {
        return 1;
      }
      return 0;
    })
  }

  constructor(private store: Store) {
  }

  @Action(InitAcl)
  initAcl(ctx: StateContext<DynamicFormsStateModel>, action: InitAcl) {
    return ctx.patchState({...ctx.getState(), currentAcl: action.acl});
  }

  @Action(ClearAcl)
  removeFromSelectedAcl(ctx: StateContext<DynamicFormsStateModel>) {
    const currentAcl = this.store.selectSnapshot(DynamicFormsState.currentAcl);
    const newAcl = currentAcl?.filter(acl => acl.stateType === ObjectAclStateType.DEFAULT);
    ctx.patchState({currentAcl: newAcl});
  }

  @Action(UpdatePermission)
  updatePermissions(ctx: StateContext<DynamicFormsStateModel>, action: UpdatePermission) {
    const currentAcl = ctx.getState().currentAcl;
    const matchingAcl = currentAcl.find(acl => acl.name === action.name);
    const newPermissions: string[] = matchingAcl.permissions.filter(per => per !== action.permission) || [];
    if (action.add) {
      newPermissions.push(action.permission)
    }

    if (matchingAcl) {
      return ctx.setState(patch({
        currentAcl: updateItem<ObjectAclDefinition>(itm => itm.name === action.name, {...matchingAcl, permissions: newPermissions})
      }));
    } else {
      return ctx.setState(patch({
        currentAcl: insertItem<ObjectAclDefinition>({name: action.name, type: ObjectAclType.GROUP, stateType: ObjectAclStateType.ADDED, permissions: newPermissions})
      }));
    }
  }

  @Action(AddAcl)
  updateAcl(ctx: StateContext<DynamicFormsStateModel>, action: AddAcl) {
    const currentAcl = ctx.getState().currentAcl || [];
    if (currentAcl.find(item => item.name === action.newAcl.name)) {
      return ctx.setState(patch({
        currentAcl: updateItem<ObjectAclDefinition>(itm => itm.name === action.newAcl.name, action.newAcl)
      }));
    } else {
      return ctx.setState(patch({
        currentAcl: insertItem<ObjectAclDefinition>(action.newAcl)
      }));
    }
  }

  @Action(RemoveAcl)
  removeAddedAcl(ctx: StateContext<DynamicFormsStateModel>, action: RemoveAcl) {
    return ctx.setState(patch({
      currentAcl: removeItem<ObjectAclDefinition>(item => item.name === action.name)
    }));
  }

  @Action(RemoveForm)
  private removeFom(ctx: StateContext<DynamicFormsStateModel>, action: RemoveForm) {

    const currentState = {...ctx.getState()};
    currentState[action.formName] = undefined;
    ctx.patchState({...ctx.getState(), ...currentState});
    ctx.patchState({currentAcl: []});

  }

}
