
<ng-container *ngIf="(currentVersions$ | async) as versions ">

  <mat-table class="fm-row-hover" [dataSource]="versions">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
      <mat-cell *matCellDef="let version">{{version.versionNumber}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentTitle">
      <mat-header-cell *matHeaderCellDef>Document title</mat-header-cell>
      <mat-cell *matCellDef="let version"> {{version.documentTitle}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedBy">
      <mat-header-cell *matHeaderCellDef>Modified by</mat-header-cell>
      <mat-cell *matCellDef="let version"> {{version.modifiedBy}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedOn">
      <mat-header-cell *matHeaderCellDef>Modified on</mat-header-cell>
      <mat-cell *matCellDef="let version">{{version.modifiedOn | localizeDate}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns; let i = index;" routerLinkActive="is-selected" [routerLink]="['/objectDetails', versions[i].id]"></mat-row>

  </mat-table>
</ng-container>
