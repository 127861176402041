import {Component, OnInit} from '@angular/core';
import {AppState} from '../app.state';
import {combineLatest, Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {ShowSideMenu, StartPageLoaded, ToggleMenuByName} from '../app.actions';
import {BreakpointObserver} from '@angular/cdk/layout';
import {filter} from "rxjs/operators";
import {AuthState} from "@frontmania/auth";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Route, Router} from "@angular/router";
import {UserProfileState} from "@frontmania/user-profile";
import {MenuItem} from "../app.model";

@UntilDestroy()
@Component({
  selector: 'ap-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  readonly MAIN_SIDE_NAV = AppState.MAIN_SIDE_NAV;

  @Select(AppState.menuState('mainSideNav')) sideNavExpanded$: Observable<boolean>;

  allowedMenuItems: MenuItem[];
  showSideMenu: boolean;
  private _showRolesWarning;

  constructor(private store: Store, public breakpointObserver: BreakpointObserver, private router: Router) {
  }

  get showRolesWarning() {
    return this._showRolesWarning
  }

  isLargeScreen() {
    return this.breakpointObserver.isMatched('(min-width: 720px)');
  }

  toggleMenuByName(name: string) {
    this.store.dispatch(new ToggleMenuByName(name));
  }

  ngOnInit(): void {
    combineLatest([this.store.select(AppState.sideMenu), this.store.select(AuthState.currentUser)]).pipe(
      filter(([sideMenu, currentUser]) => !!sideMenu && !!currentUser?.roles),
      untilDestroyed(this)
    ).subscribe(([sideMenu, currentUser]) => {

      this.allowedMenuItems = sideMenu.items.filter(item => this.allowedToSee(item.acl, currentUser.roles));
      this.showSideMenu = this.determineIfSideMenuNeeded();
      const startPageAlreadyLoaded = this.store.selectSnapshot(AppState.startPageLoaded);

      if (this.allowedMenuItems?.length > 0 && !startPageAlreadyLoaded) {
        this.updateRouterConfig();
        this.store.dispatch(new StartPageLoaded());
      }
      this.store.dispatch(new ShowSideMenu(this.showSideMenu));

      this._showRolesWarning = this.allowedMenuItems.length === 0 && !this.showSideMenu;

    });
  }

  private determineIfSideMenuNeeded() {
    const moreThanOneMainItem = this.allowedMenuItems?.length > 1;
    const oneMainItemWithSubItems = this.allowedMenuItems?.length === 1 && this.allowedMenuItems[0].subItems?.length > 0;
    return moreThanOneMainItem || oneMainItemWithSubItems;
  }

  private updateRouterConfig() {
    const startPageFromProfile = this.store.selectSnapshot(UserProfileState.startPage);
    const startRoute = startPageFromProfile ? startPageFromProfile : this.determineDefaultRoute();

    const config: Route = {
      path: '',
      redirectTo: startRoute,
      pathMatch: 'full'
    };
    const newConfig = [config, ...this.router.config];
    this.router.resetConfig(newConfig);
    this.router.navigateByUrl(startRoute);

  }

  private determineDefaultRoute() {
    const firstItem = this.allowedMenuItems[0];
    if (firstItem.defaultRoute) {
      return firstItem.defaultRoute;
    }
    if (!firstItem.subItems) {
      return firstItem.route;
    }
    return firstItem.subItems[0].route;
  }

  subMenuExpanded(mainMenuName: string) {
    return this.store.selectSnapshot(AppState.menuState(mainMenuName));
  }

  private allowedToSee(acls: string[], roles: string[]): boolean {
    return acls.filter(acl => {
      const aclRe = new RegExp(acl);
      return roles?.filter(role => aclRe.test(role)).length > 0;
    }).length > 0;
  }


  hasSubitems(mainItem: MenuItem) {
    return mainItem.subItems?.length > 0;
  }

  showSubItemsDirectly() {
    return this.allowedMenuItems?.length === 1;
  }

  isUserStartPage(route: string) {
    const startPageFromProfile = this.store.selectSnapshot(UserProfileState.startPage)?.substr(1);
    return route === startPageFromProfile;
  }
}
