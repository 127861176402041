<div class="fm-masterdata-input">
  <input type="text"
         matInput
         [required]="required"
         [matAutocomplete]="auto"
         #masterDataInput
         [formControl]="formValue"
         [placeholder]="placeholder"
          (focusout)="markeAsTouched()">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let masterdata of searchResults" [value]="masterdata">
      {{masterdata}}
    </mat-option>
  </mat-autocomplete>
</div>
