import {Component} from '@angular/core';
import {I18nState} from '../i18n.state';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {SelectLocale} from '../i18n.actions';

@Component({

  selector: 'i18n-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent {

  @Select(I18nState.availableLocales)
  availableLocales$: Observable<string[]>;

  @Select(I18nState.selectedLocale)
  selectedLocale$: Observable<string>;


  constructor(private store: Store) {
  }

  onLanguageSelect(newLocale: string) {
    this.store.dispatch(new SelectLocale(newLocale));
  }
}
