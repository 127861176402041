import {Component, Inject, NgZone} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {NotificationData} from "../notification.model";

@Component({
  selector: 'frontmania-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {

  notificationContent: NotificationData;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
              private snackBarRef: MatSnackBarRef<NotificationComponent>,
              private ngZone: NgZone) {
    this.notificationContent = data;
  }

  close() {
    this.ngZone.run( () => {
      this.snackBarRef.dismiss();
    });
  }

}
