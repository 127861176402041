import {Action, createSelector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Template, TemplateType} from '../../api/model/template.model';
import {TemplateService} from './template.service';
import {LoadCheckinTemplate, LoadEditTemplate, LoadSearchTemplate} from './template.actions';


export interface TemplateStateModel {
  searchTemplates: {
    [templateName: string]: Template
  },
  checkinTemplates: {
    [templateName: string]: Template
  },
  editTemplates: {
    [objectClassName: string]: Template
  }
}

export const STATE_DEFAULTS: TemplateStateModel = {
  searchTemplates: {},
  checkinTemplates: {},
  editTemplates: {}
}

@State<TemplateStateModel>({
  name: 'templateCache',
  defaults: STATE_DEFAULTS
})
@Injectable({
  providedIn: 'root'
})
export class TemplateState {

  constructor(private store: Store,
              private templateService: TemplateService) {
  }

  static checkinTemplateByName(templateName: string) {
    return createSelector([TemplateState], (state: TemplateStateModel) => {
      return state.checkinTemplates[templateName];
    });
  }

  static editTemplateByClassName(objectClassName: string) {
    return createSelector([TemplateState], (state: TemplateStateModel) => {
      return state.editTemplates[objectClassName];
    });
  }

  static searchTemplateByName(templateName: string) {
    return createSelector([TemplateState], (state: TemplateStateModel) => {
      return state?.searchTemplates[templateName];
    });
  }

  static resultTableColumns(templateName: string) {
    return createSelector([TemplateState], (state: TemplateStateModel) => {
      return state?.searchTemplates[templateName].resultTableColumns;
    });
  }

  @Action(LoadEditTemplate)
  loadEditTemplate(ctx: StateContext<TemplateStateModel>, action: LoadEditTemplate) {
    if (ctx.getState().editTemplates[action.objectClassName]) {
      return; // class is already in state... no load required!
    }
    return this.templateService.load(action.objectClassName, TemplateType.EDIT).pipe(
      tap(template => {
        ctx.patchState({editTemplates: {...ctx.getState().editTemplates, [action.objectClassName]: template}});
      })
    );
  }

  @Action(LoadSearchTemplate)
  loadSearchTemplate(ctx: StateContext<TemplateStateModel>, action: LoadSearchTemplate) {
    if(ctx.getState()?.searchTemplates[action.templateName]) {
      return; // class is already in state... no load required!
    }
    return this.templateService.load(action.templateName, TemplateType.SEARCH).pipe(
      tap(template => {
        ctx.patchState({searchTemplates: {...ctx.getState()?.searchTemplates, [action.templateName]: template}});
      })
    );
  }

  @Action(LoadCheckinTemplate)
  loadCheckinTemplate(ctx: StateContext<TemplateStateModel>, action: LoadCheckinTemplate) {
    if(ctx.getState().checkinTemplates[action.templateName]) {
      return; // class is already in state... no load required!
    }
    return this.templateService.load(action.templateName, TemplateType.CHECKIN).pipe(
      tap(template => {
        ctx.patchState({checkinTemplates: {...ctx.getState().checkinTemplates, [action.templateName]: template}});
      })
    );
  }
}
