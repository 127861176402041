import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MaintenanceInfoService } from '../../api/maintenance-info.service';
import { tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fm-maintenance-page',
  templateUrl: './fm-maintenance-page.component.html',
  styleUrls: ['./fm-maintenance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmMaintenancePageComponent implements OnInit {

  countdown: Countdown = {hours: 0, minutes: 0, seconds: 0};

  constructor(private mtService: MaintenanceInfoService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.mtService.countdown().pipe(
      untilDestroyed(this),
      tap(cdown => this.countdown = cdown),
      tap(() => this.cdRef.detectChanges())
    ).subscribe()
  }
}

export interface Countdown {
  hours: number,
  minutes: number;
  seconds: number;
}
