import {Injectable} from '@angular/core';

@Injectable()
export class AuthModuleConfig {
  scope: string;
  clientId: string;
  issuer: string;
  autoLogin: boolean;
  securityTemplateEndpoint?: string;
  appIdentifier: string;
  logoutEnabled: boolean;
}
