import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DynamicFormOutput, ValueType} from '@frontmania/object-master-data';
import {Store} from '@ngxs/store';
import {I18nState} from '@frontmania/i18n';

@Component({
  selector: 'frontmania-object-details-item',
  templateUrl: './object-details-item.component.html',
  styleUrls: ['./object-details-item.component.scss']
})
export class ObjectDetailsItemComponent {


  @Input() fdValue: DynamicFormOutput;
  @ViewChild("textDiv") textDiv: ElementRef;

  constructor(private store: Store) {
  }

  selectedLocale(): string {
    return this.store.selectSnapshot(I18nState.selectedLocale);
  }


  isBooleanValue(value: ValueType) {
    return value === ValueType.BOOLEAN
  }

  asStringValue(value: unknown) {
    return value as string;
  }

}
