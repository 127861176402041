import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {SuperUnitPipe} from './super-unit.pipe';
import {FileSizePipe} from './custom-pipes/file-size/file-size.pipe';

@NgModule({
  declarations: [
    SuperUnitPipe,
    FileSizePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SuperUnitPipe,
    FileSizePipe
  ],
  providers: [
    SuperUnitPipe,
    FileSizePipe,
    CurrencyPipe,
    DecimalPipe,
    DatePipe
  ]
})
export class UnitsModule {}
