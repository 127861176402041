import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Job} from '../../api/model/job.model';
import {Store} from '@ngxs/store';
import {I18nState} from '@frontmania/i18n';

@Component({
  selector: 'frontmania-export-job-info',
  templateUrl: 'job-info.component.html',
  styleUrls: ['./job-info.component.scss']
})
export class JobInfoComponent {

  constructor(private store: Store,
              public dialogRef: MatDialogRef<JobInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Job) {
  }

  selectedLanguage() {
    return this.store.selectSnapshot(I18nState.selectedLocale)
  }

}
