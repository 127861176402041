import {SearchState} from "./user-profile.model";

export class SetStartPage {
  static type = '[UserProfile] SetStartPage';

  constructor(public startPage: string) {
  }
}

export class AddSearch {
  static type = '[UserProfile] AddSearch';

  constructor(public searchState: SearchState) {
  }
}

export class DeleteSearch {
  static type = '[UserProfile] DeleteSearch';

  constructor(public id: string) {
  }
}

export class LoadSettings {
  static type = '[UserProfile] LoadSettings';

}
