import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {ObjectClassState} from '../../backend/object-class/object-class.state';
import {TemplateState} from '../../backend/templates/template.state';
import {combineLatest, distinctUntilChanged, Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {ChoiceValueDefinition} from '../model/choice-values.model';
import {ChoiceValuesState} from '../../backend/choice-values/choice-values.state';
import {FieldDefinitionService} from '../../backend/field-definition.service';
import {ObjectAclDefinition} from '../model/object-class.model';
import {FormDefinition} from '../model/field-definition.model';
import {Options, Template} from '../model/template.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectEditConnector {

  constructor(private store: Store, private filedDefService: FieldDefinitionService) {
  }

  getDefaultInstanceSecurity(objectClassNames: string[]): Observable<ObjectAclDefinition[]> {
    return this.store.selectOnce(ObjectClassState.defaultInstanceSecurity(objectClassNames));
  }

  public getCheckInOptions(className: string): Observable<Options> {
    const template = this.store.selectSnapshot(TemplateState.editTemplateByClassName(className));
    return of(template?.options);
  }

  public getEditTemplate(className: string): Observable<Template> {
    return this.store.select(TemplateState.editTemplateByClassName(className)).pipe(distinctUntilChanged());
  }

  public getFormDefinition(objectClassName: string): Observable<FormDefinition> {
    const template = this.store.selectSnapshot(TemplateState.editTemplateByClassName(objectClassName));
    //if no template exists, we get the _default_ template. If we get this, we have to use the objectClass directly
    // //instead of the attribute objectClasses in the template. Because this includes also the name _default_
    const objectClasses: string[] = template.objectClasses.includes("_default_") ? [objectClassName] : template.objectClasses;
    return combineLatest([
      this.store.selectOnce(ObjectClassState.propertyDefinitions(objectClasses)),
      this.getChoiceValues(objectClasses)
    ]).pipe(
      switchMap(([propertyDefinitions, choiceValues]) => this.filedDefService.toFormDefinition(template.formGroups, propertyDefinitions, choiceValues))
    )
  }

  private getChoiceValues(objectClassNames: string[]): Observable<ChoiceValueDefinition[]> {
    return this.store.selectOnce(ObjectClassState.choiceValueNamesOfClasses(objectClassNames)).pipe(
      filter(choiceValueNames => !!choiceValueNames),
      switchMap(choiceValueNames => this.store.selectOnce(ChoiceValuesState.choiceValuesByNames(choiceValueNames)))
    );
  }

  isVersionable(classDefinitionName: string) {
    const objectClass = this.store.selectSnapshot(ObjectClassState.objectClass(classDefinitionName));
    const template = this.store.selectSnapshot(TemplateState.editTemplateByClassName(classDefinitionName));

    return of(!!objectClass.versionable && !template.options?.disableVersioningButton)
  }
}
