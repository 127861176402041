import { Component } from '@angular/core';

@Component({
  selector: 'fm-page',
  templateUrl: './fm-page.component.html',
  styleUrls: ['./fm-page.component.scss']
})
export class FmPageComponent {

}
