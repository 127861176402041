import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MasterdataInputValidator } from './fm-masterdata-input.validator';
import { MasterDataService } from './master-data.service';

@Component({
  selector: 'fm-masterdata-input',
  templateUrl: './fm-masterdata-input.component.html',
  styleUrls: ['./fm-masterdata-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FmMasterdataInputComponent
    }
  ]
})
export class FmMasterdataInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnInit, AfterViewInit, OnDestroy {
  static nextId = 0;
  readonly controlType = 'fm-masterdata-input';
  readonly userAriaDescribedBy: string;
  id = `fm-masterdata-input-${FmMasterdataInputComponent.nextId++}`;
  @ViewChild('masterDataInput') masterDataInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatInput)
  private _matInputDirective: MatInput;
  private _touched = false;
  private _stateChanges: Subject<void> = new Subject<void>();
  private _placeholder: string;
  private _required: boolean;
  @Input()
  url: string;

  searchResults: string[] = [];
  formValue: FormControl;

  //we cannot use untilDestroy package because it does not fit provider, configured in the upper decorator!
  private _destroy$ = new Subject();
  private _onTouched = () => {
    //do nothing by default
  };

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  private _onChange = (value: string | null) => {
    //do nothing by default
  };

  constructor(
    private masterDataService: MasterDataService,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formValue = new FormControl();
    this.formValue.statusChanges.pipe(
      takeUntil(this._destroy$),
      tap(() => this._stateChanges.next())
    ).subscribe()

    this.formValue.valueChanges.pipe(
      takeUntil(this._destroy$),
      tap(value => this._onChange(value))
    ).subscribe();
  }

  ngOnInit(): void {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.formValue.addAsyncValidators(MasterdataInputValidator.valueValidator(this, this.masterDataService, this.url));
    }

    if (!this.url) {
      throw new Error('No url set! Please set attribute "url"!');
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.masterDataInput.nativeElement, 'keyup')
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(250),
        distinctUntilChanged(),
        map(() => this.formValue.value),
        switchMap(value => this.masterDataService.loadMasterData(value || '', this.url)),
        tap(data => this.searchResults = data)
      ).subscribe();
  }

  get stateChanges() {
    return this._stateChanges.asObservable();
  }

  @Input()
  get value() {
    return this.formValue.value;
  }

  set value(val: string) {
    this.formValue.setValue(val);
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  get focused() {
    return this._matInputDirective?.focused;
  }

  get empty() {
    return this._matInputDirective?.empty;
  }

  get shouldLabelFloat() {
    return this.formValue.value || this._matInputDirective?.shouldLabelFloat;
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = req;
  }

  get disabled() {
    return this._matInputDirective?.disabled;
  }

  get errorState() {
    return this.formValue.invalid && this._touched;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef?.nativeElement?.querySelector('.fm-masterdata-input');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(): void {
    this._matInputDirective.onContainerClick();
  }

  writeValue(value: string): void {
    this.formValue.setValue(value);
  }

  registerOnChange(fn: (value) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formValue.disable() : this.formValue.enable();
  }

  markeAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(undefined)
    this._destroy$.complete();
  }

}
