import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  LoadCheckinMasterData,
  LoadEditMasterData,
  LoadObjectSearchMasterData,
  RefreshAllChoiceValues,
  UpdateLanguage
} from '../object-master-data.actions';

@Injectable({
  providedIn: 'root'
})
export class ObjectMasterDataService {

  constructor(private store: Store) {
  }

  /**
   * load master-data required by object-search module
   * @param language
   * @param templateName
   */
  loadObjectSearchMasterData(language: string, templateName: string): Observable<void> {
    return this.store.dispatch(new LoadObjectSearchMasterData(language, templateName));
  }

  /**
   * load master-data required by object-checkin module
   * @param language
   * @param templateName
   */
  loadCheckinMasterData(language: string, templateName: string): Observable<void> {
    return this.store.dispatch(new LoadCheckinMasterData(language, templateName));
  }

  loadEditMasterData(language: string, objectClassName: string): Observable<void> {
    return this.store.dispatch(new LoadEditMasterData(language, objectClassName));
  }

  /**
   * with a language update all master data with language-specific values are reloaded from backend
   * @param language
   */
  updateLanguage(language: string): Observable<void> {
    return this.store.dispatch(new UpdateLanguage(language));
  }

  /**
   * whenever a choice-values or choice-value is updated this will trigger update on all languages
   * in the store
   */
  refreshAllChoiceValues(language: string): Observable<void> {
    return this.store.dispatch(new RefreshAllChoiceValues(language));
  }
}
