import {Field, FormGroup, Template} from '../../api/model/template.model';
import {Backend} from './template.model';

export class TemplateMapper {

  static mapTemplate(source: Backend.Template): Template {
    return {
      templateName: source.templateName,
      documentClass: source.documentClass,
      objectClasses: [...source.objectClasses],
      resultTableColumns: TemplateMapper.mapFieldArray(source.resultTableColumns),
      aggregations: TemplateMapper.mapFieldArray(source.aggregations),
      formGroups: TemplateMapper.toFormGroup(source.form.groups),
      options: source.options
    }
  }

  private static mapFieldArray(source: Backend.Field[]): Field[] {
    if (!source) {
      return undefined;
    }
    return source.map(field => {
      return {
        name: field.dmsPropertyName,
        readonly: field.readonly,
        searchOperator: field.searchOperator,
        dateRange: field.dateRange,
        masterDataSource: field.masterDataSource,
        fillStrategy: field.fillStrategy,
        unitTransformInfo: field.unitTransformInfo
      }
    });
  }

  private static toFormGroup(source: Backend.Group[]): FormGroup[] {
    return source.map(group => {
      const newGroup = new FormGroup();
      newGroup.label = group.label;
      newGroup.formFields = TemplateMapper.mapFieldArray(group.columns.flat());
      return newGroup
    });
  }


}
