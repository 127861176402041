import {Component, OnInit} from '@angular/core';
import {UserProfileState} from "../../user-profile.state";
import {Select, Store} from "@ngxs/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Observable} from "rxjs";
import {UserSettingsContent} from "../../user-profile.model";
import {AuthState, CurrentUser} from "@frontmania/auth";
import {UserProfileConfig} from "../../user-profile.config";
import {DeleteSearch} from "../../user-profile.actions";

@UntilDestroy()
@Component({
  selector: 'frontmania-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  @Select(AuthState.currentUser) currentUser$: Observable<CurrentUser>;

  currentSettings$: Observable<UserSettingsContent>
  showSettings: boolean;

  constructor(private store: Store,
              private config?: UserProfileConfig) {
    this.showSettings = !!this.config?.userProfileUrl && !!this.config?.name;
  }

  ngOnInit(): void {

    this.currentSettings$ = this.store.select(UserProfileState.currentSettings).pipe(
      untilDestroyed(this)
    );
  }


  deleteSearch(id: string) {
    this.store.dispatch(new DeleteSearch(id))
  }
}
