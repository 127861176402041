import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@frontmania/material';
import { FmMaintenanceInfoBannerComponent } from './components/fm-maintenance-info-banner/fm-maintenance-info-banner.component';
import { I18nModule } from '@frontmania/i18n';
import { FmMaintenancePageComponent } from './components/fm-maintenance-page/fm-maintenance-page.component';

@NgModule({
  imports: [CommonModule, MaterialModule, I18nModule.forRoot()],
  declarations: [
    FmMaintenanceInfoBannerComponent,
    FmMaintenancePageComponent,
  ],
  exports: [
    FmMaintenanceInfoBannerComponent,
    FmMaintenancePageComponent
  ],
})
export class MaintenanceInfoModule {}
