<div class="table-count-info flex flex-row justify-start items-center">
  <span>
    {{ 'objectSearch.foundDocuments' | translate }}: <span class="document-count">{{ loadedNumberOfDocuments$ | async }}</span>&nbsp;/&nbsp;
  </span>
  <span class="space-right">
    <span *ngIf="(totalNumberOfDocumentsByQuery$ | async) !== undefined; else loading" class="document-count">{{ totalNumberOfDocumentsByQuery$ | async }}</span>
  </span>
  <span>
    {{ 'objectSearch.overallDocuments' | translate }}:&nbsp;
  </span>
  <span>
    <span *ngIf="(totalNumberOfDocuments$ | async) !== undefined; else loading" class="document-count">{{ totalNumberOfDocuments$ | async }}</span>
  </span>
</div>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate" diameter="14"></mat-progress-spinner>
</ng-template>
