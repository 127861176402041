export class LoadSearchTemplate {
  static readonly type = '[ObjectMasterData] LoadSearchTemplate';

  constructor(public templateName: string) {
  }
}

export class LoadCheckinTemplate {
  static readonly type = '[ObjectMasterData] LoadCheckinTemplate';

  constructor(public templateName: string) {
  }
}

export class LoadEditTemplate {
  static readonly type = '[ObjectMasterData] LoadEditTemplate';

  constructor(public objectClassName: string) {
  }
}
