<div class="form-buttons flex flex-col sm:flex-row gap-2">
  <button data-cy="submit-button" *ngIf="sendButtonVisible" [disabled]="(internalActionButtonDisabled$$.asObservable() | async)" mat-raised-button color="primary" (click)="sendForm()">
    <mat-icon>arrow_upward</mat-icon> {{'form.controls.btnSubmit' | translate}}
  </button>
  <button data-cy="reset-button" *ngIf="resetButtonVisible" mat-raised-button [disabled]="(resetButtonDisabled$ | async)" color="primary" type="reset" (click)="resetForm()">
    <mat-icon>undo</mat-icon> {{'form.controls.btnReset' | translate}}
  </button>
  <button data-cy="cancel-button" *ngIf="cancelButtonVisible" mat-raised-button color="primary" type="reset" (click)="cancelForm()">
    <mat-icon>cancel</mat-icon> {{'form.controls.btnCancel' | translate}}
  </button>
  <button data-cy="save-search-button" *ngIf="saveButtonEnabled" mat-raised-button [disabled]="(internalActionButtonDisabled$$.asObservable() | async)" type="button" color="primary" (click)="saveForm()">
    <mat-icon>save</mat-icon> {{'form.controls.btnSave' | translate}}
  </button>
</div>
