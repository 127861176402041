export interface CurrentUser {
  roles: string[];
  email: string;
  fullName: string;
  userName: string;
}

export interface User {
  authorities?: string[] | string;
  email?: string;
  family_name?: string;
  given_name?: string;
  name?: string;
  preferred_username?: string;
  sub?: string;
  Vorname?: string;
  Nachname?: string;
  username: string;
}

export interface SecurityTemplate {
  scope: string;
  name: string;
  description?: string;
  accessRolePatterns: string[];
}
