import {Injectable} from '@angular/core';
import {ChoiceValue, ChoiceValueDefinition} from "../api/model/choice-values.model";
import {FieldDefinition, FieldDefinitionGroup, FormDefinition} from '../api/model/field-definition.model';
import {Field, FormGroup} from '../api/model/template.model';
import {PropertyDefinition} from "../api/model/object-class.model";
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldDefinitionService {

  private static toFieldDefinitions(templateFields: Field[], classFields: PropertyDefinition[], choiceValues: ChoiceValueDefinition[]): FieldDefinition[] {
    const result: FieldDefinition[] = [];
    if (!!templateFields && !!classFields) {
      templateFields.forEach(templateField => {
        const classField = classFields.find(field => field.name === templateField.name);
        if (classField) {
          let cvValues;
          if (choiceValues) {
            const foundChoiceValues = choiceValues.find(cv => cv.name === classField.choiceValuesName);
            if (foundChoiceValues) {
              cvValues = foundChoiceValues.values.map(cvItem => cvItem as ChoiceValue).sort((cv1, cv2) => (cv1.label < cv2.label) ? -1 : 1);
            }
          }
          result.push({
            choiceValues: cvValues,
            dateRange: templateField.dateRange,
            masterDataSource: templateField.masterDataSource,
            name: templateField.name,
            readonly: templateField.readonly,
            searchOperator: templateField.searchOperator,
            type: classField.valueType,
            multiValued: classField.multiValued,
            mandatory: classField.mandatory,
            choiceValuesName: classField.choiceValuesName,
            unitTransformInfo: templateField.unitTransformInfo,
            fillStrategy: templateField.fillStrategy
          })
        }
      });
    }
    return result;
  }

  toFormDefinition(formGroups: FormGroup[], classFields: PropertyDefinition[], choiceValues: ChoiceValueDefinition[]): Observable<FormDefinition> {
    const fieldDefGroups = formGroups.map(group => new FieldDefinitionGroup(FieldDefinitionService.toFieldDefinitions(group.formFields, classFields, choiceValues), group.label));
    return of(new FormDefinition(fieldDefGroups));
  }

}
