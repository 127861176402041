import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LoadingService} from '../../app-loading/loading.service';

@UntilDestroy()
@Component({
  selector: 'fm-toolbar',
  templateUrl: './fm-toolbar.component.html',
  styleUrls: ['./fm-toolbar.component.scss']
})
export class FmToolbarComponent {

  @Input()
  userName: string
  @Input()
  selectedLocale: string;
  @Input()
  availableLocales: string[];
  @Input()
  exportAdminViewEnabled = false;

  @Input()
  logoutButtonVisible = true;

  @Output()
  logoutEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  languageSelectEvent: EventEmitter<string> = new EventEmitter<string>()

  isLoading: boolean;

  constructor(private loadingService: LoadingService, private ref: ChangeDetectorRef) {
    loadingService.isLoading().pipe(untilDestroyed(this)).subscribe(loading => {
        this.isLoading = loading;
        ref.detectChanges();
      }
    );
  }

  logOut() {
    this.logoutEvent.next();
  }

  setLanguage(locale: string) {
    this.languageSelectEvent.next(locale);
  }

}
