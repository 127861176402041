<fm-page class="flex flex-col" *ngIf="(currentObject$ | async) as currentObject">
  <fm-titlebar fmTitlebar>
    <div class="flex flex-row gap-2 justify-between items-center">
      <div>
        <span data-cy="current-content-name">{{currentObject.contentName}}</span>
      </div>
      <div class="buttons flex flex-row justify-end">
        <button data-cy="download-button" *ngIf="showDownloadButton$ | async" mat-button
                (click)="download(currentObject.id, currentObject.contentName)">
          <mat-icon>save_alt</mat-icon><span class="hidden sm:inline">&nbsp;{{'objectDetails.controls.download' | translate}}</span>
        </button>

        <button data-cy="object-details-edit-button" *ngIf="showEditButton$ | async" mat-button
                (click)="switchView(true)">
          <mat-icon>edit</mat-icon><span class="hidden sm:inline">&nbsp;{{'objectDetails.controls.edit' | translate}}</span>
        </button>

        <button data-cy="add-version-button" mat-button (click)="fileInput.click()" *ngIf="showNewVersionButton$ | async">
          <mat-icon>update</mat-icon><span class="hidden sm:inline">&nbsp;{{'objectDetails.controls.addVersion' | translate}}</span>
          <input hidden (change)="onFileSelectedForNewVersion($event)" #fileInput type="file" id="file">
        </button>
      </div>
    </div>
  </fm-titlebar>
  <div fmContent class="layout-container">
    <frontmania-object-details-view *ngIf="(editModeEnabled$ | async) === false">
    </frontmania-object-details-view>

    <frontmania-object-details-editing *ngIf="(editModeEnabled$ | async)"
                                       (submitEvent)="uploadDetails($event)"
                                       (cancelEvent)="switchView(false)">
    </frontmania-object-details-editing>
  </div>


</fm-page>
