import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthModuleConfig} from './auth.config';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CookieModule} from 'ngx-cookie';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {OAuthModule} from 'angular-oauth2-oidc';
import {AuthInterceptor} from './auth.interceptor';
import {getActionTypeFromInstance, NGXS_PLUGINS, NgxsModule} from "@ngxs/store";
import {AuthState} from "./auth.state";
import {Logout} from "./auth.actions";
import {VisibleForDirective} from './securityTemplates/visible-for.directive';
import { AppIdentifierInterceptor } from './app-identifier.interceptor';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forFeature([AuthState]),
    OAuthModule.forRoot(), // TODO: maybe use {resourceServer: {sendAccessToken: true}} + whitelist instead of custom interjector
    CookieModule.forChild(),
    NgxWebstorageModule.forRoot(),
    LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG}),
  ],
  declarations: [
    VisibleForDirective
  ],
  exports: [
    VisibleForDirective
  ],
  providers: [
    AuthModuleConfig
  ]
})
export class AuthModule {

  static forRoot(authConfig?: AuthModuleConfig): ModuleWithProviders<AuthModule> {
    return {
      "ngModule": AuthModule,
      "providers": [
        {
          provide: AuthModuleConfig,
          useValue: authConfig
        },
        {
          provide: NGXS_PLUGINS,
          useValue: logoutPlugin,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AppIdentifierInterceptor,
          multi: true
        }
      ]
    }
  }
}

function logoutPlugin(state, action, next) {
  if (getActionTypeFromInstance(action) === Logout.type) {
    state = {};
  }
  return next(state, action);
}
