import {ErrorHandler, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {NotificationComponent} from '../component/notification.component';
import {NotificationConfig} from '../notification.config';
import {NotificationPlaceholders, NotificationType} from '../notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements ErrorHandler {

  private snackBarConfig = new MatSnackBarConfig();
  private snackBarRef: MatSnackBarRef<NotificationComponent>;

  private static mapToMessageKey(status: number) {
    switch (status) {
      case 404: {
        return "errorMessage.notFound";
      }
      case 401: {
        return "errorMessage.notAuthorized"
      }
      case 403: {
        return "errorMessage.forbidden"
      }
      default: {
        return "errorMessage.contactAdmin"
      }
    }
  }

  constructor(private config: NotificationConfig,
              private snackBar: MatSnackBar,
              private logger: NGXLogger) {
    if (this.snackBarRef) {
      this.closeNotification();
    }
    this.snackBarConfig.duration = 3000;
    if (this.config.fadeOutAfterMs > 3000) {
      this.snackBarConfig.duration = this.config.fadeOutAfterMs;
    }
  }

  handleError(error: Error | HttpErrorResponse): void {
    console.error(error);
    if (error['handled']) {
      // ignore error
      // https://github.com/ngxs/store/blob/master/docs/advanced/errors.md#handling-errors-within-an-action
      // https://github.com/ngxs/store/issues/803#issuecomment-935854360
      return;
    }

    error['handled'] = true;
    let messageKey: string;
    const placeholders = {};
    if (error instanceof HttpErrorResponse) {
      messageKey = NotificationService.mapToMessageKey(error.status);
      placeholders["url"] = error.url;
    } else {
      messageKey = error.message ? error.message : error.toString();
    }

    this.openNotification(NotificationType.ERROR, messageKey, placeholders)

  }

  openNotification(type: NotificationType, messageKey: string, placeholders?: NotificationPlaceholders) {
    if (type === NotificationType.ERROR) {
      this.logger.error(messageKey);
    }
    this.snackBarConfig.panelClass = type == NotificationType.ERROR ? 'panelClass-error' : 'panelClass-success'
    this.snackBarConfig.data = {
      type: type,
      messageKey: messageKey,
      placeholders: placeholders,
    };
    this.snackBarRef = this.snackBar.openFromComponent(NotificationComponent, this.snackBarConfig);
  }

  closeNotification() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

}
