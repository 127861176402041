import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ObjectAclDefinition, ObjectAclStateType, ObjectAclType} from '@frontmania/object-master-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {concatMap, debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable, of} from 'rxjs';
import {Store} from '@ngxs/store';
import {AddAcl, ClearAcl, InitAcl, RemoveAcl, UpdatePermission} from '../../dynamic-forms.actions';
import {DynamicFormsState} from '../../dynamic-forms.state';
import {LdapSearchService} from '../../util/ldap-search.service';


@UntilDestroy()
@Component({
  selector: 'frontmania-acl-editor',
  templateUrl: './acl-editor.component.html',
  styleUrls: ['./acl-editor.component.scss']
})
export class AclEditorComponent implements OnInit, OnDestroy {

  private static readonly DOWNLOAD_CONTENT = 'DOWNLOAD_CONTENT';
  private static readonly READ = 'READ';
  private static readonly WRITE = 'WRITE';
  private static readonly DELETE = 'DELETE';

  @Output()
  updateAcl: EventEmitter<ObjectAclDefinition[]> = new EventEmitter<ObjectAclDefinition[]>();

  @ViewChild('aclSearch') aclSearchInput: ElementRef<HTMLInputElement>;

  @Input()
  aclData$: Observable<ObjectAclDefinition[]>;

  permissionConfig: string[] = [AclEditorComponent.READ, AclEditorComponent.WRITE, AclEditorComponent.DOWNLOAD_CONTENT]

  @Input()
  reset$: Observable<boolean>

  @Input()
  readOnly: boolean;

  private _defaultColumnNames = ['select', 'name'];

  get tableColumns () {
    return [...this._defaultColumnNames, ...this.permissionConfig];
  }

  objectAclTableModel: MatTableDataSource<ObjectAclDefinition>;

  selection: SelectionModel<ObjectAclDefinition>;
  searchResults: string[];

  constructor(private store: Store, private ldapSearchService: LdapSearchService, private changeDetector : ChangeDetectorRef) {
    this.selection = new SelectionModel<ObjectAclDefinition>(true, undefined);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearAcl());
  }

  ngOnInit(): void {
    this.listenToAclChanges();
    this.initAclData();
    this.handleResets();

  }

  private initAclData() {
    this.aclData$.pipe(
      untilDestroyed(this),
      filter((aclData: ObjectAclDefinition[]) => !!aclData && aclData.length > 0),
      concatMap((aclData: ObjectAclDefinition[]) => this.store.dispatch(new InitAcl(aclData))),
    ).subscribe();
  }

  private listenToAclChanges() {
    this.store.select(DynamicFormsState.currentAcl).pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      map(acls => this.sortByStateType(acls))
    ).subscribe((acl) => {
      this.objectAclTableModel = new MatTableDataSource<ObjectAclDefinition>(acl);
      this.updateAcl.emit(acl);
    });
  }

  private handleResets() {
    if (this.reset$) {
      this.reset$.pipe(
        untilDestroyed(this),
        filter(val => !!val)
      ).subscribe(() => {
        this.store.dispatch(new ClearAcl());
      });
    }
  }

  private sortByStateType(acls: ObjectAclDefinition[]) {
    return acls?.sort((a,b) => a.stateType - b.stateType)
  }

  onPermissionSelect(aclEntry: ObjectAclDefinition, permission: string, add: boolean) {
    this.executeAction(this.store.dispatch(new UpdatePermission(aclEntry.name, permission, add)));
  }

  remove(aclEntryName: string) {
    this.executeAction(this.store.dispatch(new RemoveAcl(aclEntryName)));
  }

  selected(event: MatAutocompleteSelectedEvent) {

    const selectedValue = event.option.viewValue;
    const newObjectAclDefinition = {
      name: selectedValue,
      type: ObjectAclType.GROUP,
      stateType: ObjectAclStateType.ADDED,
      permissions: [AclEditorComponent.READ]
    } as ObjectAclDefinition;
    this.executeAction(this.store.dispatch(new AddAcl(newObjectAclDefinition)));
    this.aclSearchInput.nativeElement.value = "";

  }

  private executeAction(actionObservable: Observable<unknown>) {
    actionObservable.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      const currentAcl = this.store.selectSnapshot(DynamicFormsState.currentAcl);
      if (!!currentAcl && currentAcl.length > 0) {
        this.updateAcl.emit(currentAcl);

      }
    });
  }

  search(query: string): void {
    of(query)
      .pipe(
        filter((queryString) => !!queryString && queryString !== ''),
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(() => {
          return this.ldapSearchService.loadRoles(query)
        }),
        untilDestroyed(this)
      )
      .subscribe((data => {
        this.searchResults = data;
      }));
  }

  isAdded(aclEntry: ObjectAclDefinition) {
    return aclEntry.stateType === ObjectAclStateType.ADDED;
  }

  isPermissionReadOnly(permission: string) {
    return permission === AclEditorComponent.READ;
  }
}
