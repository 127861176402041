import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { MaintenanceInfoService } from '../../api/maintenance-info.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fm-maintenance-info-banner',
  templateUrl: './fm-maintenance-info-banner.component.html',
  styleUrls: ['./fm-maintenance-info-banner.component.scss'],
})
export class FmMaintenanceInfoBannerComponent {

  @Input()
  locale: string;

  constructor(private maintenanceService: MaintenanceInfoService, @Inject(LOCALE_ID) defaultLocale: string) {
    this.locale = defaultLocale;
  }

  get bannerClosed() {
    return this.maintenanceService.isBannerClosed();
  }

  get maintenanceDateTimestamp() {
    return this.maintenanceService.maintenanceTimestamp();
  }

  get duration() {
    return this.maintenanceService.duration();
  }

  bannerClose() {
      this.maintenanceService.persistCloseState();
  }

}
