import {Observable} from "rxjs";
import {ChoiceValueDefinition} from "../model/choice-values.model";
import {ObjectClassState} from "../../backend/object-class/object-class.state";
import {filter, switchMap} from "rxjs/operators";
import {ChoiceValuesState} from "../../backend/choice-values/choice-values.state";
import {Store} from "@ngxs/store";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ChoiceValuesAdminConnector{
  constructor(private store: Store) {}

  getChoiceValues(objectClassNames: string[]): Observable<ChoiceValueDefinition[]> {
    return this.store.selectOnce(ObjectClassState.choiceValueNamesOfClasses(objectClassNames)).pipe(
      filter(choiceValueNames => !!choiceValueNames && choiceValueNames.length > 0),
      switchMap(choiceValueNames => this.store.selectOnce(ChoiceValuesState.choiceValuesByNames(choiceValueNames)))
    );
  }
}
