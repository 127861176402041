import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { Store } from '@ngxs/store';
import { ObjectSearchState } from '../../../../object-search.state';
import { I18nState } from '@frontmania/i18n';
import { map } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'frontmania-doc-count-info',
  templateUrl: './doc-count-info.component.html',
  styleUrls: ['./doc-count-info.component.scss']
})
export class DocCountInfoComponent {

  constructor(private store: Store, private decimalPipe: DecimalPipe) {}

  totalNumberOfDocuments$: Observable<string> = this.transformNumber(this.store.select(ObjectSearchState.totalNumberOfDocumentsByClasses), 1000000, '>');
  totalNumberOfDocumentsByQuery$: Observable<string> = this.transformNumber(this.store.select(ObjectSearchState.totalNumberOfDocumentsByQuery), 500000, '>');
  loadedNumberOfDocuments$: Observable<string> = this.transformNumber(this.store.select(ObjectSearchState.loadedNumberOfDocuments), 1000);

  private transformNumber(selector: Observable<number>, defaultCount: number, prefix = '') {
    return combineLatest([
      selector,
      this.store.select(I18nState.selectedLocale)
    ]).pipe(
      map(([count, local]) => {
        if (count === undefined) {
          return undefined;
        }
        return count === -1 ? `${prefix} ${this.decimalPipe.transform(defaultCount,undefined, local)}` : `${this.decimalPipe.transform(count,null, local)}`
      })
    );
  }

}

