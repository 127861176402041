import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldDefinitionGroup, DynamicFormOutput, DynamicFormInput } from '@frontmania/object-master-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DynamicFormsService } from '../../util/dynamic-forms.service';
import { LoadingService } from '@frontmania/material';
import { DynamicFormControlService } from '../../util/dynamic-form-control.service';
import { ResetForm } from '@ngxs/form-plugin';

@UntilDestroy()
@Component({
  selector: 'frontmania-dynamic-form',
  templateUrl: './form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges {

  @Input()
  formDefinitionInput: DynamicFormInput;
  @Input()
  formName: string;

  @Output()
  submitEvent: EventEmitter<DynamicFormOutput[]> = new EventEmitter<DynamicFormOutput[]>();
  @Output()
  resetEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  saveSearch: EventEmitter<DynamicFormOutput[]> = new EventEmitter<DynamicFormOutput[]>();

  formModel: FormGroup = new FormGroup({});
  formReady = false;
  isLoading$: Observable<boolean>;

  constructor(private formsService: DynamicFormsService,
              private dynamicFormControlService: DynamicFormControlService,
              private store: Store,
              private fb: FormBuilder,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.isLoading$ = this.loadingService.isLoading().pipe(untilDestroyed(this));
    this.dynamicFormControlService.onResetForm.pipe(untilDestroyed(this)).subscribe(() => this.reset());
    this.dynamicFormControlService.onSendForm.pipe(untilDestroyed(this)).subscribe(() => this.submit());
    this.dynamicFormControlService.onSaveForm.pipe(untilDestroyed(this)).subscribe(() => this.save());
    this.dynamicFormControlService.onCancelForm.pipe(untilDestroyed(this)).subscribe(() => this.cancel());
    this.formModel.statusChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      tap(() => this.dynamicFormControlService.formModelChanged(this.formModel))
    ).subscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formDefinitionInput?.currentValue) {
      this.formReady = false;
      this.buildFormModel(this.formModel);
      this.formReady = true;
    }
  }

  submit() {
    if (this.formModel.valid) {
      this.submitEvent.emit(this.toFieldDefinitionValues());
    };
  }

  cancel() {
    this.store.dispatch(new ResetForm({path: this.formName}));
    this.resetEvent.emit();
  }

  reset() {
    const fGroup = new FormGroup({});
    this.buildFormModel(fGroup);
    this.store.dispatch(new ResetForm({path: this.formName, value: fGroup.value}));
    this.resetEvent.emit();
  }

  private buildFormModel(formGroup: FormGroup) {
    for (const controlsKey in formGroup.controls) {
      formGroup.removeControl(controlsKey, {emitEvent: false})
    }
    this.formsService.buildForm(this.formDefinitionInput, formGroup);
  }

  save() {
    this.saveSearch.emit(this.toFieldDefinitionValues());
  }

  private toFieldDefinitionValues(): DynamicFormOutput[] {
    return this.formsService.toFieldDefinitionValue(this.formModel, this.formDefinitionInput.formDefinition.getAllFieldDefinitions());
  }

  fieldDefinitionsOfGroup(group: FieldDefinitionGroup) {
    return group.fieldDefinitions;
  }



}
