<div class="flex flex-col sm:flex-row gap-4 sm:justify-start sm:items-start">
  <mat-card class="flex-auto basis-full">
    <mat-card-title>{{'profile.title' | translate}}</mat-card-title>
    <mat-card-content>
      <div class="info-row flex flex-row justify-start items-center">
        <div class="flex flex-row justify-start items-center flex-initial basis-full">
          <mat-icon>home</mat-icon>
          <span>{{'profile.settings.startPage' | translate}}</span>
        </div>
        <div class="basis-full">{{!!settings?.startPage ? format(settings.startPage) : '---' }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="flex-initial basis-full">
    <mat-card-title>{{'profile.settings.savedSearches' | translate}}</mat-card-title>
    <mat-card-content>
      <ng-container *ngIf="settings?.searches?.length > 0;else noSearches">
        <div *ngFor="let groupedSearch of group(settings.searches) | keyvalue" data-cy="saved-search-user-profile-entry"
             class="info-row flex flex-row justify-center items-center">
          <span class="basis-full">{{ 'documentClass.' + groupedSearch.key | translate}}</span>
          <div class="basis-full flex flex-col">
            <div class="basis-full flex flex-row justify-start items-center" *ngFor="let search of sort(groupedSearch.value)">
              <button data-cy="delete-search-button" color="warn" mat-icon-button
                      (click)="deleteSearch(search.id, search.name)">
                <mat-icon>delete</mat-icon>
              </button>
              <div>{{search.name}}</div>
            </div>
          </div>
        </div>
        <mat-divider class="hide-if-last-child"></mat-divider>
      </ng-container>
      <ng-template #noSearches>
        <div class="info-row flex flex-row justify-center items-center">
          <span class="basis-full">---</span>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
