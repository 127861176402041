import {Component, OnInit} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {FileSaverService} from "ngx-filesaver";
import {ObjectDetailsService} from "../../../service/object-details.service"
import {ObjectDetails} from '@frontmania/object-master-data';
import {Store} from '@ngxs/store';
import {ObjectDetailsState} from '../../../object-details.state';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {I18nState} from '@frontmania/i18n';

@UntilDestroy()
@Component({
  selector: 'frontmania-object-details-attachments-dialog',
  templateUrl: './object-details-attachments-dialog.component.html',
  styleUrls: ['./object-details-attachments-dialog.component.scss']
})
export class ObjectDetailsAttachmentsDialogComponent implements OnInit {

  columns = ["contentName", "contentSizeInBytes"];
  attachments$: Observable<ObjectDetails[]>;

  constructor( private objectDetailsService: ObjectDetailsService,
               private fileSaverService: FileSaverService,
               private store: Store) {
  }

  ngOnInit(): void {
    this.attachments$ = this.store.select(ObjectDetailsState.currentObject).pipe(
      untilDestroyed(this),
      switchMap(curObj => this.objectDetailsService.getAttachments(curObj.id))
    )
  }

  download(objectDetails: ObjectDetails): void {
    this.objectDetailsService.downloadFile(objectDetails.id).subscribe(fileBlob => {
      this.fileSaverService.save(fileBlob, objectDetails.contentName);
    })
  }

  selectedLocale(): string {
    return this.store.selectSnapshot(I18nState.selectedLocale);
  }

}
