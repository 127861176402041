import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DateRangeField,
  FieldDefinition,
  DynamicFormOutput,
  DynamicFormInput,
  FormValue,
  SearchInputField,
  SearchOperator,
  ValueType
} from '@frontmania/object-master-data';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormsService {

  static isDate(fieldDefinition: FieldDefinition) {
    return fieldDefinition.type === ValueType.DATE;
  }

  static isMasterDataField(fieldDefinition: FieldDefinition) {
    return !!fieldDefinition.masterDataSource;
  }

  static isChoiceValueSelect(fieldDefinition: FieldDefinition) {
    return !!fieldDefinition.choiceValues &&
      fieldDefinition.choiceValues.length > 0 &&
      fieldDefinition.type === ValueType.STRING
  }

  static isMultiSelect(fieldDefinition: FieldDefinition) {
    return this.isChoiceValueSelect(fieldDefinition) && fieldDefinition.multiValued
  }

  static isText(fieldDefinition: FieldDefinition) {
    return !fieldDefinition.choiceValues &&
      !this.isSearchInput(fieldDefinition) &&
      !this.isMasterDataField(fieldDefinition) &&
      (fieldDefinition.type === ValueType.STRING
        || fieldDefinition.type === ValueType.INTEGER
        || fieldDefinition.type === ValueType.LONG);
  }

  static isFloating(fieldDefinition: FieldDefinition) {
    return !fieldDefinition.choiceValues &&
      (fieldDefinition.type === ValueType.FLOATING)
  }

  static isBoolean(fieldDefinition: FieldDefinition) {
    return fieldDefinition.type === ValueType.BOOLEAN;
  }

  static isSearchInput(fieldDefinition: FieldDefinition) {
    return fieldDefinition.type === ValueType.STRING && !!fieldDefinition.searchOperator && !fieldDefinition.choiceValuesName
  }

  static isMandatory(fieldDefinition: FieldDefinition) {
    return fieldDefinition.mandatory;
  }

  private static handleMasterDataField(currentField: FieldDefinition, dynamicForm: FormGroup, initialValue: FormValue) {
    if (currentField.multiValued) {
      const formArray = new FormArray([]);
      if (initialValue) {
        if (Array.isArray(initialValue)) {
          initialValue.forEach(val => formArray.push(DynamicFormsService.createFormControl(currentField, val)))
        } else {
          formArray.push(DynamicFormsService.createFormControl(currentField, initialValue));
        }
      }
      dynamicForm.setControl(currentField.name, formArray);
    } else {
      dynamicForm.setControl(currentField.name, DynamicFormsService.createFormControl(currentField, initialValue));
    }

  }

  private static createFormControl(currentField: FieldDefinition, initialValue?: FormValue) {
    const control = new FormControl();
    if (currentField.readonly) {
      control.disable()
    }
    if (currentField.mandatory) {
      control.setValidators(Validators.required)
    }
    if (initialValue) {
      control.setValue(initialValue);
    }
    return control;
  }

  private static determineFieldValue(field: FieldDefinition, valueFromForm: FormValue): FormValue {
    if (!!field.choiceValuesName && Array.isArray(valueFromForm)) {
      return valueFromForm.length > 0 ? valueFromForm : null;
    }
    return valueFromForm;
  }


  private static determineInitialValue(currentField: FieldDefinition, predefinedValue: FormValue): FormValue {
    if (currentField.type === ValueType.DATE || currentField.type === ValueType.DATE_TIME) {
      return this.determineInitialDateValues(currentField, predefinedValue as DateRangeField);
    }
    if (DynamicFormsService.isSearchInput(currentField)) {
      return this.determineSearchFieldValue(currentField, predefinedValue);
    }
    return predefinedValue;
  }

  private static determineInitialDateValues(currentField: FieldDefinition, predefinedValue?: FormValue): DateRangeField {
    if (Number.isInteger(predefinedValue)) {
      return {
        start: moment(predefinedValue as number),
        operator: currentField.dateRange ? currentField.searchOperator || SearchOperator.DATE_EQUALS: undefined
      }
    }
    if (predefinedValue) {
      return predefinedValue as DateRangeField;
    }
    return {
      operator: currentField.dateRange ? currentField.searchOperator || SearchOperator.DATE_EQUALS: undefined
    }
  }

  private static determineSearchFieldValue(currentField: FieldDefinition, predefinedValue?: FormValue): SearchInputField {
    if (predefinedValue) {
      return {
        searchValue: predefinedValue['searchValue'] || "",
        searchOperator: predefinedValue['searchOperator'] || currentField.searchOperator || SearchOperator.STRING_EQ
      }
    }
    return{
      searchValue: "",
      searchOperator: currentField.searchOperator || SearchOperator.STRING_EQ
    }
  }

  private static handleDateField(initialValue: DateRangeField, currentField: FieldDefinition, formGroup: FormGroup) {
    const dateGroup = new FormGroup({});
    dateGroup.setControl("start", DynamicFormsService.createFormControl(currentField, initialValue?.start));
    if (currentField.dateRange) {
      dateGroup.setControl("end", DynamicFormsService.createFormControl(currentField, initialValue?.end));
      dateGroup.setControl("operator", DynamicFormsService.createFormControl(currentField, initialValue.operator));
    }
    formGroup.setControl(currentField.name, dateGroup);
  }

  buildForm(formDefInput: DynamicFormInput, formGroup: FormGroup) {
    formDefInput.formDefinition.fieldDefinitionGroup.forEach((group) => {
      group.fieldDefinitions.forEach((currentField: FieldDefinition) => {
        const initialValue = DynamicFormsService.determineInitialValue(currentField, formDefInput.getValue(currentField.name));
        if (currentField.type === ValueType.DATE || currentField.type === ValueType.DATE_TIME) {
          DynamicFormsService.handleDateField(initialValue as DateRangeField, currentField, formGroup);
        } else if (DynamicFormsService.isMasterDataField(currentField)) {
          DynamicFormsService.handleMasterDataField(currentField, formGroup, initialValue);
        } else {
          formGroup.setControl(currentField.name, DynamicFormsService.createFormControl(currentField, initialValue));
        }
      });
    });
  }

  toFieldDefinitionValue(formModel: FormGroup, formFields: FieldDefinition[]): DynamicFormOutput[] {
    return formFields
      .map(field => {
        const newFieldValue = DynamicFormsService.determineFieldValue(field, formModel.get(field.name)?.value)
        return {fieldDefinition: field, value: newFieldValue}
      });
  }
}
