import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable} from "rxjs";
import {Select, Store} from '@ngxs/store';
import {ObjectDetails, Options} from '@frontmania/object-master-data';
import {TableColumn} from "../../../object-search.model";
import {ObjectSearchState} from "../../../object-search.state";
import { filter, map, withLatestFrom } from 'rxjs/operators';

export class TableDataSource implements DataSource<ObjectDetails> {

  @Select(ObjectSearchState.options) options$: Observable<Options>;
  @Select(ObjectSearchState.loadedDocuments) loadedDocuments$: Observable<ObjectDetails[]>;
  @Select(ObjectSearchState.resultTableColumns) resultTableColumns$: Observable<TableColumn[]>;
  @Select(ObjectSearchState.currentPageSize) currentResultSizePerPage$;
  @Select(ObjectSearchState.currentPage) currentPage$;

  resultTableColumnNames$ = this.store.select(ObjectSearchState.resultTableColumnNames).pipe(
    filter(columns => !!columns),
    map(columns => ['preview', 'attachments', ...columns]),
    withLatestFrom(this.isShowSelected()),
    map(([columns, isShowSelected]) => isShowSelected ? ['multiselection', ...columns]: columns)
  )

  constructor(private store: Store) {}

  connect(): Observable<ObjectDetails[] | ReadonlyArray<ObjectDetails>> {
    return this.loadedDocuments$;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  disconnect(collectionViewer: CollectionViewer): void {

  }

  isShowSelected() {
    return this.options$.pipe(map((options) =>  options?.export?.csv || options?.export?.content));
  }
}
