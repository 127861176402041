<fm-maintenance-page *ngIf="maintenanceRunning;"></fm-maintenance-page>
<div *ngIf="!maintenanceRunning;" class="application flex flex-col">
  <fm-maintenance-info-banner [locale]="selectedLocale$ | async"></fm-maintenance-info-banner>
  <fm-toolbar
    [exportAdminViewEnabled]="exportAdminViewEnabled"
    [userName]="(currentUser$ | async)?.fullName"
    [logoutButtonVisible]="logoutButtonVisible"
    [selectedLocale]="selectedLocale$ | async"
    [availableLocales]="availableLocales$ | async"
    (logoutEvent)="logout()"
    (languageSelectEvent)="selectLocale($event)">
    <div leftToolbarPart class="flex flex-row justify-start items-center">
      <mat-icon data-cy="sidebar-button" class="burger-menu" matRipple aria-hidden="false" (click)="toggleSideNav()">menu</mat-icon>
      <img class="toolbar-image" src="assets/images/logo-simple.svg" alt="Logo">
      <div class="flex flex-col">
        <span>{{ "applicationName" | translate }}</span>
      </div>
    </div>
  </fm-toolbar>

  <ap-sidebar class="flex-1">
    <router-outlet></router-outlet>
  </ap-sidebar>
</div>







