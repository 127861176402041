export class InitObjectDetails {
  static readonly type = '[Object Details] Init';
  constructor(public language: string, public objectId: string) {}
}

export class ClearEditForm {
  static readonly type = '[ObjectDetails] ClearObjectDetailsEditForm';
}

export class UnloadObject {
  static readonly type = '[ObjectDetails] Unload';
}

export class UpdateObjectDetails {
  static readonly type = '[ObjectDetails] Update';

  constructor(public uploadData: { [key: string]: unknown }) {
  }
}

export class UploadNewVersion {
  static readonly type = '[ObjectDetails] UploadNewVersion';

  constructor(public file: File) {
  }
}

export class UpdateViewState {
  static readonly type = '[ObjectDetails] UpdateViewState';

  constructor(public inEditView: boolean) {
  }
}
