import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@frontmania/material';
import {RouterModule} from '@angular/router';
import {I18nModule} from '@frontmania/i18n';
import {FormsModule} from '@angular/forms';
import {ObjectExportConfig} from './object-export.config';
import {ExportJobsComponent} from './component/export-jobs/export-jobs.component';
import {authGuard} from "@frontmania/auth";
import {JobInfoComponent} from './component/job-info/job-info.component';

@NgModule({
    imports: [CommonModule,
        MaterialModule,
        RouterModule.forChild([
            {
                path: 'object-export/jobs',
                component: ExportJobsComponent,
                canActivate: [authGuard]
            }
        ]),
        I18nModule,
        FormsModule
    ],
  declarations: [ExportJobsComponent, JobInfoComponent],
  exports: []
})

export class ObjectExportModule  {
  static forRoot(
    config?: ObjectExportConfig
  ): ModuleWithProviders<ObjectExportModule> {
    return {
      ngModule: ObjectExportModule,
      providers: [
        {
          provide: ObjectExportConfig,
          useValue: config
        }
      ]
    };
  }
}
