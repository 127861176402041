import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {ObjectClassState} from '../../backend/object-class/object-class.state';
import {TemplateState} from '../../backend/templates/template.state';
import {ChoiceValuesState} from '../../backend/choice-values/choice-values.state';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {ChoiceValueDefinition, ObjectAclDefinition, Options, FormDefinition} from '../../..';
import {FieldDefinitionService} from '../../backend/field-definition.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectCheckinConnector {

  constructor(private store: Store, private filedDefService: FieldDefinitionService) {}

  getDefaultInstanceSecurity(objectClassNames: string[]): Observable<ObjectAclDefinition[]> {
    return this.store.selectOnce(ObjectClassState.defaultInstanceSecurity(objectClassNames));
  }

  public getCheckInOptions(templateName: string): Observable<Options> {
    const template = this.store.selectSnapshot(TemplateState.checkinTemplateByName(templateName));
    return of(template?.options);
  }

  public getFormDefinition(templateName: string): Observable<FormDefinition> {
    const template = this.store.selectSnapshot(TemplateState.checkinTemplateByName(templateName));
    return combineLatest([
        this.store.selectOnce(ObjectClassState.propertyDefinitions(template.objectClasses)),
        this.getChoiceValues(template.objectClasses)
      ]).pipe(
      switchMap(([propertyDefinitions, choiceValues]) => this.filedDefService.toFormDefinition(template.formGroups, propertyDefinitions, choiceValues))
    )
  }

  private getChoiceValues(objectClassNames: string[]): Observable<ChoiceValueDefinition[]> {
    return this.store.selectOnce(ObjectClassState.choiceValueNamesOfClasses(objectClassNames)).pipe(
      filter(choiceValueNames => !!choiceValueNames && choiceValueNames.length > 0),
      switchMap(choiceValueNames => this.store.selectOnce(ChoiceValuesState.choiceValuesByNames(choiceValueNames)))
    );
  }
}
