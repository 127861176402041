<div *ngIf="fdValue">
  <div class="object-details-item">
    <div class="flex flex-col">
      <div class="caption">{{'objectAttributes.' + fdValue.fieldDefinition.name | translate}}</div>
      <ng-container *ngIf="!isBooleanValue(fdValue.fieldDefinition.type) && !fdValue.fieldDefinition.choiceValuesName">
        <div class="overflow-container" [ngSwitch]="fdValue.fieldDefinition.name">
          <div *ngSwitchCase="'language'">{{fdValue.value !== undefined ? ('objectAttributes.languageValues.' + fdValue.value | translate) : '---' }}</div>
          <div *ngSwitchCase="'country'">{{fdValue.value !== undefined ? ('objectAttributes.countryValues.' + fdValue.value | translate) : '---'}}</div>
          <div *ngSwitchCase="'versionState'">{{('objectAttributes.versionStateValues.' + fdValue.value | translate) ?? '---'}}</div>
          <div *ngSwitchDefault>{{ (asStringValue(fdValue.value) | superUnit : selectedLocale() : fdValue.fieldDefinition?.unitTransformInfo) ?? '---' }}</div>
        </div>
      </ng-container>
      <div *ngIf="!!fdValue.fieldDefinition.choiceValuesName">
        {{ (asStringValue(fdValue.value) | choiceValuesMapping: fdValue.fieldDefinition.choiceValuesName) ?? '---' }}
      </div>
      <div *ngIf="isBooleanValue(fdValue.fieldDefinition.type)">
        {{ fdValue.value !== undefined ? ('objectAttributes.booleanValues.' + fdValue.value | translate) : '---' }}
      </div>
    </div>
    </div>

</div>
