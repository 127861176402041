<div class="small-toolbar flex flex-row">
  <div class="flex flex-row justify-between items-center w-full">
    <ng-content select="[leftToolbarPart]"></ng-content>
    <div>
      <button data-cy="export-button" *ngIf="exportAdminViewEnabled" mat-button routerLink="/object-export/jobs"
              routerLinkActive="is-active">
        <mat-icon aria-hidden="false">get_app</mat-icon>
        {{'toolbar.controls.btnExports' | translate }}
      </button>

      <button data-cy="user-menu-open-button" *ngIf="!!userName" mat-button [matMenuTriggerFor]="profile">
        <mat-icon aria-hidden="false">person</mat-icon>{{ userName }}
      </button>

      <mat-menu #profile="matMenu" xPosition="before">
        <button mat-menu-item [matMenuTriggerFor]="language">
          <mat-icon>flag</mat-icon>
          {{'toolbar.controls.btnLanguage' | translate}}
        </button>
        <button data-cy="profile-button" mat-menu-item routerLink="/profile" routerLinkActive="is-active">
          <mat-icon>face</mat-icon>
          {{'toolbar.labels.profile' | translate }}
        </button>
        <button *ngIf="logoutButtonVisible" data-cy="logout-button" mat-menu-item (click)="logOut()">
          <mat-icon>reply</mat-icon>
          {{'toolbar.controls.btnLogout' | translate}}
        </button>
      </mat-menu>

      <mat-menu #language="matMenu" xPosition="before">
        <button (click)="setLanguage(locale)" [ngClass]="{'selected':locale === selectedLocale }" mat-menu-item
                *ngFor="let locale of availableLocales">
          <span class="flag-icon flag-icon-{{locale}} mat-elevation-z2 rounded"></span>
          {{'locales.' + locale | translate}}
        </button>
      </mat-menu>
    </div>
  </div>
</div>

