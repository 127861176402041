import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, first, interval, map, Observable } from 'rxjs';
import { Countdown, MaintenanceConfig } from './maintenance-config.model';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceInfoService {

  private static BANNER_CLOSE_TIME_KEY = 'MAINTENANCE_BANNER_CLOSE_TIME';
  private _config: MaintenanceConfig | undefined

  constructor(private httpClient: HttpClient) {
    this.httpClient.get<MaintenanceConfig>('assets/config/maintenance-config.json').pipe(
      first(),
      catchError(() => EMPTY)
    ).subscribe(config => this._config = config);
  }

  private static durationToCountdown(durationInMillis: number): Countdown {
    console.log('Millis: ' + durationInMillis);
    const durationInSecs = durationInMillis / 1000;
    const hours = durationInSecs / 3600; // from secs to hours
    const mins = (durationInSecs % 3600) / 60;
    const secs = (mins * 60) % 60;
    return {hours: Math.trunc(hours), minutes: Math.trunc(mins), seconds: Math.trunc(secs)}
  }

  public countdown(): Observable<Countdown> {
    return interval(1000).pipe(
      map(() => {
        const endtime = this.maintenanceTimestamp() + (this.duration() * 1000 * 60 * 60);
        const durationLeft = endtime - Date.now();
        return  MaintenanceInfoService.durationToCountdown(durationLeft);
      })
    );
  }

  public isMaintenanceActive() {
    const now = Date.now();
    return !!this._config &&
      this._config.maintenanceDate + (1000 * 60 * 60 * this.duration()) > now &&
      this._config.enabled;
  }

  /**
   * we are between start and end of the period
   */
  public isMaintenanceRunning() {
    const now = Date.now();
    return !!this._config &&
      this._config.maintenanceDate < now && now < this._config.maintenanceDate + (1000 * 60 * 60 * this.duration()) &&
      this._config.enabled;
  }

  public maintenanceTimestamp() {
    return !!this._config && this._config.maintenanceDate ? this._config.maintenanceDate : 0;
  }

  public duration() {
    return !!this._config && this._config.durationInHours ? this._config.durationInHours : 0;
  }

  /**
   * is visible, when:
   * 1. maintenance is active and user did not close the banner within 'showInfoLeadTime' manually
   * 2. is visible again for a browser-session, if 'forceShowInfoLeadTime' is active
   */
  public isBannerClosed(): boolean {
    if (!this._config || !this.isMaintenanceActive()) {
      return true;
    }

    if (this.isMaintenanceActive()) {
      if (this.isForceInfoLeadTimeActive() && this.bannerVisibleFromStorage(sessionStorage)) {
        return true;
      }

      if (this.isInfoLeadTimeActive() && this.bannerVisibleFromStorage(localStorage)) {
        return true;
      }
    }
    return false;
  }

  public persistCloseState() {
    if (this.isForceInfoLeadTimeActive()) {
      sessionStorage.setItem(MaintenanceInfoService.BANNER_CLOSE_TIME_KEY, String(Date.now()));
    }
    else {
      localStorage.setItem(MaintenanceInfoService.BANNER_CLOSE_TIME_KEY, String(Date.now()));
    }
  }

  private isInfoLeadTimeActive(): boolean {
    return !!this._config &&
      this.isMaintenanceActive() &&
      (Date.now() > (this._config?.maintenanceDate - this._config?.showInfoLeadTime)) &&
      !this.isForceInfoLeadTimeActive()
  }

  private isForceInfoLeadTimeActive(): boolean {
    return !!this._config &&
      this.isMaintenanceActive() &&
      (Date.now() > (this._config?.maintenanceDate - this._config?.forceShowInfoLeadTime));
  }

  private bannerVisibleFromStorage(storage: Storage) {
    const closeTime = Number(storage.getItem(MaintenanceInfoService.BANNER_CLOSE_TIME_KEY));
    return !!this._config && !!closeTime && closeTime < this._config?.maintenanceDate;

  }

}
