import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Backend} from './object-class.model';
import {map} from 'rxjs/operators';
import {ObjectClassMapper} from './object-class.mapper';
import {ObjectClass} from '../../api/model/object-class.model';
import {ObjectStoreMasterDataConfig} from '../../object-master-data.config';


@Injectable({
  providedIn: 'root'
})
export class ObjectClassService {

  constructor(private httpClient: HttpClient, private  config: ObjectStoreMasterDataConfig) {
  }

  load(objectClassName: string): Observable<ObjectClass> {
    return this.httpClient.get<Backend.ObjectClass>(this.config.classPropertiesUrl.replace(":name", objectClassName))
      .pipe(
        map(backendObjectClass => ObjectClassMapper.mapObjectClass(backendObjectClass))
      );
  }
}
