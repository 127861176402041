import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {DataTableService} from "../../../../service/data-table.service";
import {FileSaverService} from 'ngx-filesaver';
import {ObjectDetails} from '@frontmania/object-master-data';

@Component({

  selector: 'frontmania-object-attachments',
  templateUrl: './attached-objects.component.html',
  styleUrls: ['./attached-objects.component.scss']
})
export class AttachedObjectsComponent implements OnInit {

  @Input()
  objectId: string;

  attachments$: Observable<ObjectDetails[]>;

  constructor(private route: Router,
              private dataTableService: DataTableService,
              private fileSaverService: FileSaverService) {
  }

  ngOnInit(): void {
    this.attachments$ = this.dataTableService.getAttachments(this.objectId);
  }

  selectRow(doc: ObjectDetails) {
    this.route.navigate(["objectDetails", doc.id]);
  }

  download(doc: ObjectDetails): void {
    this.dataTableService.downloadFile(doc.id).subscribe(fileBlob => {
      this.fileSaverService.save(fileBlob, doc.contentName);
    })
  }

}
