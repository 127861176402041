import { FormValue, ObjectDetails, Options, UnitTransformInfo } from '@frontmania/object-master-data';
import { Sort } from '@angular/material/sort';
import { DynamicFormModel } from '@frontmania/dynamic-forms';

export enum TableColumnType {
  STRING, DATE, BOOLEAN, FLOATING
}

export interface TableColumn {
  name: string;
  choiceValuesName?: string;
  type: TableColumnType;
  multivalued: boolean;
  unitTransformInfo?: UnitTransformInfo;
}

export interface Content {
  name: string;
  sizeInBytes: number;
  type: string;
}

export enum PreviewType {
  PDF, TEXT, IMAGE, UNSUPPORTED
}

export interface BackendSearchResult {
  elements: {[key: string]: unknown}[] ;
  totalElements: number;
}

export class SearchResult {
  constructor(public elements: ObjectDetails[], public totalElements: number) {}
}

export class DocumentSearch {
  index?: string;
  query: string;
  pageNumber: number;
  pageSize: number;
  sorts?: string[];
  withElementsCount?: boolean
}

export class ObjectCount {
  constructor(public rsql: string) {}
}

export interface ObjectCountResult {
  rsql: string;
  count: number;
}

export const FORM_NAME = 'objectSearch.searchForm';


export const STATE_DEFAULTS = {
  currentTemplateName: undefined,
  rsqlQuery: undefined,
  sortSettings: undefined,
  currentResultSizePerPage: undefined,
  currentPage: 0,
  currentPageSize: 100,
  totalElements: undefined,
  totalElementsByQuery: undefined,
  resultTableColumns: undefined,
  loadedDocuments: undefined,
  selection: [],
  searchIndexName: undefined,
  options: undefined,
  searchForm: undefined,
  loading: false
};

export interface ObjectSearchStateModel {
  currentTemplateName: string;
  rsqlQuery: string;
  sortSettings: Sort;
  currentPage: number;
  currentPageSize: number;
  totalElements?: number;
  totalElementsByQuery: number;
  resultTableColumns: TableColumn[];
  loadedDocuments: ObjectDetails[];
  selection: string[];
  searchIndexName?: string;
  options?: Options;
  searchForm: DynamicFormModel;
  loading: boolean;
}

export interface SearchData {
  id: string;
  name: string;
  formFields: FormField[],
  objectClassNames: string[];
}

export interface FormField {
  name: string;
  value: FormValue;
}
