import {MatPaginatorIntl} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";
import {Select} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {I18nState} from "./i18n.state";

@Injectable()
export class PaginatorLanguageSwitcher extends MatPaginatorIntl {

  private rangeLabelIntl: string;
  @Select(I18nState.selectedLocale) selectedLocale$;

  public constructor(private translate: TranslateService) {
    super();

    this.selectedLocale$.subscribe(() => {
      this.getTranslations();
    });

    this.getTranslations();
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + this.rangeLabelIntl + '  ' + length;
  };

  getTranslations() {
    this.translate.get([
      'resultTable.paginator.itemsPerPage',
      'resultTable.paginator.nextPage',
      'resultTable.paginator.previousPage',
      'resultTable.paginator.firstPage',
      'resultTable.paginator.lastPage',
      'resultTable.paginator.range'
    ])
      .subscribe(translation => {
        this.rangeLabelIntl = translation['resultTable.paginator.range'];
        this.itemsPerPageLabel = translation['resultTable.paginator.itemsPerPage'];
        this.nextPageLabel = translation['resultTable.paginator.nextPage'];
        this.previousPageLabel = translation['resultTable.paginator.previousPage'];
        this.firstPageLabel = translation['resultTable.paginator.firstPage'];
        this.lastPageLabel = translation['resultTable.paginator.lastPage'];
        this.itemsPerPageLabel = translation['resultTable.paginator.itemsPerPage'];
        this.changes.next();
      });
  }
}
