export interface SearchInputValue {
  searchValue: string;
  searchOperator: SearchOperator;
}

export interface ComponentSearchOperator {
  label: string;
  value: SearchOperator;
}

export enum SearchOperator {
  STRING_EQ = "STRING_EQ",
  STRING_CONTAINS = "STRING_CONTAINS",
  STRING_STARTS_WITH = "STRING_STARTS_WITH",
  STRING_NOT_EQUAL = "STRING_NOT_EQUAL"
}

export const DEFAULT_COMPONENT_SEARCH_OPERATORS: ComponentSearchOperator[] = [
  {
    label: '==',
    value: SearchOperator.STRING_EQ,
  },
  {
    label: '..Az..',
    value: SearchOperator.STRING_CONTAINS
  },
  {
    label: 'Az..',
    value: SearchOperator.STRING_STARTS_WITH
  },
  {
    label: '!=',
    value: SearchOperator.STRING_NOT_EQUAL
  }
]
