import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ObjectDetailsStateModel, STATE_DEFAULTS} from './object-details.model';
import {
  ObjectEditConnector,
  ObjectMasterDataService
} from '@frontmania/object-master-data';
import {ObjectDetailsService} from './service/object-details.service';
import {
  ClearEditForm,
  InitObjectDetails, UnloadObject,
  UpdateObjectDetails,
  UpdateViewState,
  UploadNewVersion
} from './object-details.actions';
import {concatMap, switchMap, tap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';


@State<ObjectDetailsStateModel>({
  name: 'objectDetails',
  defaults: STATE_DEFAULTS
})
@Injectable({
  providedIn: 'root'
})
export class ObjectDetailsState {

  @Selector()
  static isInEditView(state: ObjectDetailsStateModel) {
    return state.inEditView;
  }

  @Selector()
  static currentObject(state: ObjectDetailsStateModel) {
    return state.object;
  }

  @Selector()
  static versionable(state: ObjectDetailsStateModel) {
    return state.versionable;
  }

  @Selector()
  static templateName(state: ObjectDetailsStateModel) {
    return state.templateName;
  }

  @Selector()
  static checkinOptions(state: ObjectDetailsStateModel) {
    return state.checkinOptions;
  }

  @Selector()
  static defaultInstanceSecurity(state: ObjectDetailsStateModel) {
    return state.defaultInstanceSecurity ?? [];
  }

  @Selector()
  static currentVersions(state: ObjectDetailsStateModel) {
    return state.versions;
  }

  @Selector()
  static hasVersions(state: ObjectDetailsStateModel) {
    return state.versions?.length > 1;
  }

  constructor(private store: Store,
              private objectMasterDataService: ObjectMasterDataService,
              private objectDetailsService: ObjectDetailsService,
              private objectEditConnector: ObjectEditConnector) {
  }

  @Action(UpdateViewState)
  private updateViewState(ctx: StateContext<ObjectDetailsStateModel>, action: UpdateViewState) {
    return ctx.patchState({inEditView: action.inEditView});
  }

  @Action(UpdateObjectDetails)
  updateObjectDetails(ctx: StateContext<ObjectDetailsStateModel>, action: UpdateObjectDetails) {
    const objectId = this.store.selectSnapshot(ObjectDetailsState.currentObject).id;
    return this.objectDetailsService.updateObjectDetails(objectId, action.uploadData).pipe(
      tap(updatedObjectDetails => ctx.patchState({object: updatedObjectDetails}))
    );
  }

  @Action(ClearEditForm)
  private clearCheckinForm(ctx: StateContext<ObjectDetailsStateModel>) {
    return ctx.patchState({ editForm: undefined});
  }

  @Action(InitObjectDetails)
  initObjectDetails(ctx: StateContext<ObjectDetailsStateModel>, action: InitObjectDetails) {

      return this.objectDetailsService.load(action.objectId).pipe(
        switchMap(object => forkJoin([
          of(object),
          this.objectDetailsService.loadVersions(action.objectId),
          this.objectMasterDataService.loadEditMasterData(action.language, object.classDefinitionName),
        ])),
        tap(([objectDetails, versions,]) =>
          ctx.patchState({
            object: objectDetails,
            versions: versions,
            templateName: objectDetails.classDefinitionName
          })),
        concatMap(([objectDetails]) => forkJoin([
          this.objectEditConnector.getDefaultInstanceSecurity([objectDetails.classDefinitionName]),
          this.objectEditConnector.getCheckInOptions(objectDetails.classDefinitionName),
          this.objectEditConnector.isVersionable(objectDetails.classDefinitionName)
        ])),
        tap(([defaultInstanceSecurity, checkinOptions, versionable]) =>
          ctx.patchState({
            defaultInstanceSecurity: defaultInstanceSecurity,
            checkinOptions: checkinOptions,
            versionable: versionable
          }))
    );
  }

  @Action(UploadNewVersion)
  private uploadNewVersion(ctx: StateContext<ObjectDetailsStateModel>, action: UploadNewVersion) {
    const objectId = this.store.selectSnapshot(ObjectDetailsState.currentObject).id;

    return this.objectDetailsService.uploadNewVersion(action.file, objectId).pipe(
      tap(newVersion => ctx.patchState({object: newVersion}))
    );
  }

  @Action(UnloadObject)
  private unloadObject(ctx: StateContext<ObjectDetailsStateModel>) {
    return ctx.patchState({object: undefined, versions: []})
  }
}
