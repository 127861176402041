<fm-page>
  <fm-titlebar fmTitlebar [backButtonVisible]="true">
    <div class="flex flex-row justify-between items-center">
      <div>{{ 'objectExport.header' | translate }}</div>
    </div>
  </fm-titlebar>
  <div fmContent class="object-export-layout">
    <table class="fm-row-hover" mat-table [dataSource]="dataSource" *ngIf="dataSource?.length > 0;else noJobs">

      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef>{{ 'objectExport.info' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon data-cy="export-info-icon" color="primary" class="pointer" (click)="openInfo(element)">info
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'objectExport.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <a data-cy="export-job-download-link" class="pointer flex flex-row"
             [class.is-disabled]="element.currentState !== jobState.FINISHED"
             (click)="download(element)">
            <mat-icon *ngIf="element.currentState === jobState.FINISHED">download</mat-icon>&nbsp;
            {{element.jobDefinition.name}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{ 'objectExport.type' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            title="{{('objectExport.title.'+ element.jobDefinition.reportType) | translate }}">{{('objectExport.icon.' + element.jobDefinition.reportType) | translate }}</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>{{ 'objectExport.state.label' | translate }}</th>
        <td mat-cell *matCellDef="let element">
        <span data-cy="export-job-state-span" [ngClass]="{
          'is-failed-job': (element?.currentState === jobState.FAILED),
          'is-pending-job': (element?.currentState === jobState.PENDING),
          'is-canceled-job': (element?.currentState === jobState.CANCELED),
          'is-finished-job': (element?.currentState === jobState.FINISHED)
          }">
           {{('objectExport.state.' + element.currentState) | translate }}
        </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'objectExport.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button data-cy="delete-export-job-button" mat-icon-button
                  matTooltip="{{'objectExport.action.delete' | translate}}" matTooltipPosition="right"
                  matTooltipShowDelay="1000" color="warn" (click)="delete(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expiry">
        <th mat-header-cell *matHeaderCellDef>{{ 'objectExport.expiry' | translate }}</th>
        <td mat-cell *matCellDef="let element">
      <span *ngIf="(element?.expiryDate !== null)">
        {{ formatExpiry(element.expiryDate) }}
      </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ng-template #noJobs>
      <div class="flex flex-row justify-center">
        {{'objectExport.noJobs' | translate}}
      </div>
    </ng-template>

  </div>
</fm-page>






