import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient) {}

  loadMasterData(searchString: string, url: string): Observable<string[]> {
    const params = new HttpParams().set('query', searchString);
    return this.http.get<string[]>(url, { params });
  }
}
