import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {DynamicFormControlService} from '../../util/dynamic-form-control.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'frontmania-dynamic-form-control-buttons',
  templateUrl: './dynamic-form-control-buttons.component.html',
  styleUrls: ['./dynamic-form-control-buttons.component.scss']
})
export class DynamicFormControlButtonsComponent implements OnInit {

  /**
   * Action buttons are all buttons that need a valid form like sendButton or saveButton
   */
  @Input()
  actionButtonsDisabled$: Observable<boolean>  = of(false)
  @Input()
  resetButtonDisabled$: Observable<boolean>;
  @Input()
  sendButtonVisible = true;
  @Input()
  resetButtonVisible = true;
  @Input()
  cancelButtonVisible =  false;
  @Input()
  saveButtonEnabled = false;

  internalActionButtonDisabled$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private dynamicFormControlService: DynamicFormControlService) {}

  ngOnInit(): void {
    combineLatest([this.actionButtonsDisabled$, this.dynamicFormControlService.formInvalid])
      .pipe(untilDestroyed(this))
      .subscribe(([externalDisabled, formInvalid]) => this.internalActionButtonDisabled$$.next(externalDisabled || formInvalid))
  }


  resetForm() {
    this.dynamicFormControlService.resetForm();
  }

  cancelForm() {
    this.dynamicFormControlService.cancelForm();
  }

  sendForm() {
    this.dynamicFormControlService.sendForm();
  }

  saveForm() {
    this.dynamicFormControlService.saveForm();
  }

}
