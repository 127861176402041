import { Injectable } from '@angular/core';
import {FieldDefinition, FormDefinition} from '../model/field-definition.model';
import {FillStrategy} from '../model/template.model';
import {CurrentUser} from '@frontmania/auth';
import {FilterCvByRoleCountryStrategy} from './strategies/filter-cv-by-role-country.strategy';
import {PrefillByRoleCountryStrategy} from './strategies/prefill-by-role-country.strategy';

@Injectable({
  providedIn: 'root'
})
export class FillStrategyService {

  private filterCvByRoleCountryStrategy = new FilterCvByRoleCountryStrategy();
  private prefillByRoleCountryStrategy = new PrefillByRoleCountryStrategy();

  public apply(formDefinition: FormDefinition, user: CurrentUser): void {
    formDefinition.getAllFieldDefinitions().forEach(fieldDefinition => this.processFieldDefinition(fieldDefinition, user));
  }

  private processFieldDefinition(fieldDefinition: FieldDefinition, user: CurrentUser): void {
    switch (fieldDefinition?.fillStrategy) {
      case FillStrategy.FILTER_CV_BY_ROLE_COUNTRY:
        return this.filterCvByRoleCountryStrategy.apply(fieldDefinition, user);
      case FillStrategy.PREFILL_BY_ROLE_COUNTRY:
        return this.prefillByRoleCountryStrategy.apply(fieldDefinition, user);
    }
  }
}
