import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';

import {ClearAuthority, LoadSecurityTemplates, Login, Logout, SetAuthority, SetRedirectUri} from './auth.actions';
import {AuthService} from './auth.service';
import {CurrentUser, SecurityTemplate, User} from './auth.model';
import {tap} from 'rxjs/operators';
import {SecurityTemplateService} from './securityTemplates/security-template.service';

export class AuthStateModel {
  authorized?: boolean;
  user?: User;
  securityTemplates: SecurityTemplate[];
  redirectUri: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    authorized: false,
    user: null,
    securityTemplates: [],
    redirectUri: undefined
  }
})
@Injectable()
export class AuthState {


  constructor(private logger: NGXLogger,
              private authService: AuthService,
              private securityTemplateService: SecurityTemplateService) {
  }

  @Selector()
  static authorized(state: AuthStateModel): boolean {
    return state.authorized;
  }

  @Selector()
  static redirectUri(state: AuthStateModel): string {
    return state.redirectUri;
  }

  @Selector()
  static currentUser(state: AuthStateModel): CurrentUser {
    const user = state.user;
    return {
      fullName: user.name,
      userName: user.username,
      email: user.email,
      roles: Array.isArray(user.authorities) ? user.authorities : [user.authorities]
    };
  }

  static securityTemplate(securityTemplateName: string) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.securityTemplates.find(tpl => tpl.name === securityTemplateName);
    });
  }

  @Action(SetRedirectUri)
  setRediredctUri(ctx: StateContext<AuthStateModel>, action: SetRedirectUri) {
    ctx.patchState({
      redirectUri: action.uri,
    });
  }

  @Action(Login)
  logIn() {
    this.authService.logIn();
  }

  @Action(Logout)
  logout() {
    return this.authService.logout();
  }

  @Action(ClearAuthority)
  clearAuthority(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      authorized: false,
      user: null,
    });
  }

  @Action(SetAuthority)
  setAuthority(ctx: StateContext<AuthStateModel>, payload: SetAuthority) {
    ctx.patchState({
      authorized: true,
      user: payload.authority
    });
  }

  @Action(LoadSecurityTemplates)
  loadSecurityTemplates(ctx: StateContext<AuthStateModel>) {
    return this.securityTemplateService.loadSecurityTemplates().pipe(
      tap(templates => {
        ctx.patchState({securityTemplates: templates});
      })
    );
  }

}
