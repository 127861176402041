import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {UserProfileConfig} from "./user-profile.config";
import {UserSettingsContent, UserSettingsResponse} from "./user-profile.model";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(
    private httpClient: HttpClient,
    private config: UserProfileConfig
  ) {
  }

  saveUserProfile(payload: UserSettingsContent) {
    return this.httpClient.post<void>(this.config.userProfileUrl.replace(":name", this.config.name), payload);
  }

  getUserProfile() {
    return this.httpClient.get<UserSettingsResponse>(this.config.userProfileUrl.replace(":name", this.config.name));
  }

}
