import { NgModule } from '@angular/core';
import { MaterialModule } from '@frontmania/material';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@frontmania/i18n';
import { FormComponent } from './components/form/form.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { DynamicFormsState } from './dynamic-forms.state';
import { DateFieldComponent } from './components/form-field/date-field/date-field.component';
import { AclEditorComponent } from './components/acl-editor/acl-editor.component';
import { SelectAllComponent } from './components/form-field/select-all/select-all.component';
import { CommonModule } from '@angular/common';
import { DynamicFormControlButtonsComponent } from './components/dynamic-form-control-buttons/dynamic-form-control-buttons.component';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    I18nModule,
    NgxsModule.forFeature([DynamicFormsState]),
    NgxsFormPluginModule.forRoot(),
  ],
  declarations: [
    FormComponent,
    FormFieldComponent,
    DateFieldComponent,
    AclEditorComponent,
    SelectAllComponent,
    DynamicFormControlButtonsComponent
  ],
  exports: [
    FormComponent,
    AclEditorComponent,
    DynamicFormControlButtonsComponent
  ],
  providers: [],
})
export class DynamicFormsModule {}
