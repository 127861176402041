import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { FmMasterdataInputComponent } from './fm-masterdata-input.component';
import { MasterDataService } from './master-data.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class MasterdataInputValidator {

  private static isEmptyInputValue(value: unknown): Observable<boolean> {
    return of(value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0));
  }

  static valueValidator(componentRef: FmMasterdataInputComponent, service: MasterDataService, url: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors|null> => {
      const value = control.value;
      return combineLatest([
        this.isEmptyInputValue(value),
        service.loadMasterData(value, url)
      ]).pipe(
        map(([isEmpty, resultList]) => {
          if (isEmpty || resultList.includes(value)) {
            return null;
          }
          return { 'valueNotAllowed': value };
        })
      )
    };
  }
}


