import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DynamicFormOutput,
  FillStrategyService, DynamicFormInput,
  ObjectSearchConnector,
} from '@frontmania/object-master-data';
import { RSQLMapper } from '../../../service/rsql-mapper';
import { FieldsService } from '../../../service/fields-service';
import { AuthState } from '@frontmania/auth';
import { FORM_NAME, FormField, SearchData } from '../../../object-search.model';
import { MatDialog } from '@angular/material/dialog';
import { SaveSearchDialogComponent } from './save-search-dialog/save-search-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { ObjectSearchService } from '../../../api/object-search.service';
import { ClearResultTable } from '../../../object-search.actions';

@UntilDestroy()
@Component({

  selector: 'frontmania-object-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  @Input() templateName$: Observable<string>;
  @Input() savedSearchFields$: Observable<FormField[]>;
  @Input() allowSavingSearch: boolean;


  @Output() executeTheSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCurrentSearch: EventEmitter<SearchData> = new EventEmitter<SearchData>();

  formDefinitionInput$$: BehaviorSubject<DynamicFormInput> = new BehaviorSubject<DynamicFormInput>(undefined);

  formName: string = FORM_NAME;

  private _objectClassNames: string[];

  private additionalSearchTerm?: string;

  constructor(public dialog: MatDialog, private objectSearchService: ObjectSearchService, private store: Store,
              private objectSearchConnector: ObjectSearchConnector,
              private fillStrategyService: FillStrategyService) {
  }

  ngOnInit(): void {
    // we load all master-data and create the form everytime the templateName changes
    this.templateName$.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      filter(templateName => !!templateName),
      switchMap((templateName) => combineLatest([
        this.objectSearchConnector.getFormDefinition(templateName),
        this.objectSearchConnector.getSearchTemplate(templateName),
        this.store.select(AuthState.currentUser),
        this.objectSearchService.applyFillStrategies$$.asObservable()
      ])),
      filter(([formDefinition, template, currentUser]) => !!currentUser && !!template && !!formDefinition),
    ).subscribe(([formDefinition, template, currentUser, applyFillStrategies]) => {

      if (applyFillStrategies) {
        this.fillStrategyService.apply(formDefinition, currentUser)
      }

      if (!!formDefinition && !!template) {
        this._objectClassNames = template.objectClasses;
        this.additionalSearchTerm = template.options?.additionalSearchTerm;
        FieldsService.prepareSearchFields(formDefinition)
        this.formDefinitionInput$$.next(new DynamicFormInput(formDefinition));
      }
    });

    if (this.savedSearchFields$ && this.allowSavingSearch) {
      this.savedSearchFields$.pipe(
        untilDestroyed(this),
        withLatestFrom(this.templateName$),
        switchMap(([fields, templateName]) => {
          return combineLatest([of(fields), this.objectSearchConnector.getFormDefinition(templateName)])
        })
      ).subscribe(([fieldsFromSavedSearch, formDef]) => {
        FieldsService.prepareSearchFields(formDef);
        const formDefInput = FieldsService.toFormDefinitionInput(fieldsFromSavedSearch, formDef);

        this.formDefinitionInput$$.next(formDefInput);
      });
    }
  }

  formResetReceived() {
    this.store.dispatch(new ClearResultTable());
    this.formDefinitionInput$$.next(this.formDefinitionInput$$.getValue());
  }

  formSubmitReceived(formFields: DynamicFormOutput[]) {
    this.executeTheSearch.emit(RSQLMapper.toRsql(formFields, this._objectClassNames, this.additionalSearchTerm));
  }

  saveSearchReceived(formFields: DynamicFormOutput[]) {
    this.openDialog(formFields);
  }

  openDialog(formFields: DynamicFormOutput[]): void {
    const dialogRef = this.dialog.open(SaveSearchDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().pipe(
      untilDestroyed(this)
    ).subscribe(searchIdentifier => {
      if (searchIdentifier) {
        this.saveCurrentSearch.emit({
          id: uuidv4(),
          name: searchIdentifier,
          formFields: FieldsService.toFormFields(formFields),
          objectClassNames: this._objectClassNames
        });
      }
    });
  }
}
