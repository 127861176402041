import {Pipe, PipeTransform} from '@angular/core';
import {Store} from "@ngxs/store";
import {ChoiceValuesState} from '../../backend/choice-values/choice-values.state';

@Pipe({
  name: 'choiceValuesMapping'
})
export class ChoiceValuesMappingPipe implements PipeTransform {

  constructor(private store: Store) {
  }

  transform(fieldValue: string | string[], choiceValuesName: string): string {
    if (Array.isArray(fieldValue)) {
      const mappedValues = fieldValue.map(value => this.store.selectSnapshot(ChoiceValuesState.choiceValueLabel(choiceValuesName, value)));
      return mappedValues ? mappedValues.join(", ") : fieldValue.join(", ");
    }
    const mappedCVValue = this.store.selectSnapshot(ChoiceValuesState.choiceValueLabel(choiceValuesName, fieldValue));
    return mappedCVValue ? mappedCVValue : fieldValue;
  }

}
