import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {ObjectClassState} from '../../backend/object-class/object-class.state';
import {TemplateState} from '../../backend/templates/template.state';
import {ChoiceValuesState} from '../../backend/choice-values/choice-values.state';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {ChoiceValueDefinition, FormDefinition, Template} from '../../..';
import {FieldDefinitionService} from '../../backend/field-definition.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectSearchConnector {

  constructor(private store: Store, private filedDefService: FieldDefinitionService) {
  }

  getSearchTemplate(templateName: string): Observable<Template> {
    return this.store.selectOnce(TemplateState.searchTemplateByName(templateName));
  }

  public getFormDefinition(templateName: string): Observable<FormDefinition> {
    return this.store.selectOnce(TemplateState.searchTemplateByName(templateName)).pipe(
      filter(template => !!template),
      switchMap(tpl => combineLatest([
        this.store.selectOnce(ObjectClassState.propertyDefinitions(tpl.objectClasses)),
        this.getChoiceValues(tpl.objectClasses),
        of(tpl)
      ])),
      switchMap(([propertyDefinitions, choiceValues, template]) => this.filedDefService.toFormDefinition(template.formGroups, propertyDefinitions, choiceValues))
    )
  }

  private getChoiceValues(objectClassNames: string[]): Observable<ChoiceValueDefinition[]> {
    return this.store.selectOnce(ObjectClassState.choiceValueNamesOfClasses(objectClassNames)).pipe(
      filter(choiceValueNames => !!choiceValueNames),
      switchMap(choiceValueNames => this.store.selectOnce(ChoiceValuesState.choiceValuesByNames(choiceValueNames)))
    );
  }
}
