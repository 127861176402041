import {User} from "./auth.model";

export class Login {
  static readonly type = '[Auth] Login';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SetRedirectUri {
  static type = '[Auth] SetRedirectUri';

  constructor(public uri: string) {
  }
}

export class SetAuthority {
  static type = '[Auth] SetAuthority';

  constructor(public authority: User) {
  }
}

export class ClearAuthority {
  static type = '[Auth] ClearAuthority';
}

export class LoadSecurityTemplates {
  static readonly type = '[Auth] LoadSecurityTemplates';
}
