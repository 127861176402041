import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from './auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oauthStorage: OAuthStorage,
              private router: Router,
              private store: Store) {
  }

  private readonly AUTHORIZATION_HEADER = 'Authorization';

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.oauthStorage.getItem("access_token")) {
      const headers = new HttpHeaders()
        .append(this.AUTHORIZATION_HEADER, 'Bearer ' + this.oauthStorage.getItem('access_token'))
      return next.handle(req.clone({headers: headers}));
    }

    return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if (err.status === 401 || err.status === 403) {
      this.store.dispatch(new Logout());
      this.router.navigateByUrl(`/login`);
      return EMPTY;
    }
    return throwError(() => err);
  }
}
