import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {SizeUnit} from './size-unit.enum';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  private static getAutoUnit(value: number): SizeUnit {
    if (value >= 1e9) {
      return SizeUnit.GB
    } else if(value >= 1e6) {
      return SizeUnit.MB
    } else if (value >= 1e3) {
      return SizeUnit.kB
    }
    return SizeUnit.B
  }

  /**
   * transforms a numeric value to a filesize
   * @param value to convert
   * @param sizeUnit where to convert like kB, MB, GB or use 'auto' for some magic :)
   * @param digitInfo info on how to format number! default is: 1.0-1
   * @param locale
   */
  public transform(value: number | string, locale: string, sizeUnit = SizeUnit.Auto, digitInfo = '1.0-1'): string[] | string  {
    if (isNaN(+value)) {
      return String(value);
    }
    const numberValue = Number(value);
    let unitForCalculation: SizeUnit = sizeUnit;
    if (sizeUnit === SizeUnit.Auto) {
      unitForCalculation = FileSizePipe.getAutoUnit(numberValue);
    }
    const resultValue = numberValue / unitForCalculation;
    return `${this.decimalPipe.transform(resultValue, digitInfo, locale)} ${SizeUnit[unitForCalculation]}`
  }

}
