import {User} from "./auth.model";

export class AuthorityExtractor {

  static extractAuthority(accessToken: string): User {
    const payload: User = JSON.parse(atob(accessToken.split('.')[1]));
    const user: User = {
      authorities: payload.authorities,
      email: payload.email,
      family_name: payload.family_name || payload.Nachname,
      given_name: payload.given_name || payload.Vorname,
      name: this.buildName(payload),
      preferred_username: payload.preferred_username || payload['login-name'],
      sub: payload.sub,
      username: payload.preferred_username || payload['login-name']
    };

    // the IDP returns a String if the user has only one role, so we have to convert it to an array
    if (!Array.isArray(user.authorities)) {
      user.authorities = [user.authorities];
    }

    user.authorities = user.authorities.map<string>(role => {
      return role.toLowerCase()
        .replace(/dn=[a-z0-9-]+/ig, '')
        .replace(/ou=[a-z0-9-]+/ig, '')
        .replace(/o=[a-z0-9-]+/ig, '')
        .replace(/,*/ig, '')
        .replace('cn=', '')
    });
    return user;
  }

  private static buildName(payload: User) {
    if (payload.name) {
      return payload.name;
    }
    if (payload.Vorname && payload.Nachname) {
      return payload.Vorname + ' ' + payload.Nachname;
    }
    if (payload.Vorname) {
      return payload.Vorname;
    }
    return payload.Nachname;
  }
}
