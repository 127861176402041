import {TranslateLoader} from "@ngx-translate/core";
import { catchError, EMPTY, map, Observable } from 'rxjs';
import {HttpClient} from "@angular/common/http";

interface TranslationEntry {
  key: string;
  labels: { [key: string]: string }
}

export class CustomHttpTranslateLoader implements TranslateLoader {

  private static toNestedJSON(body: TranslationEntry[], lang: string): unknown {
    const result = [];
    body.forEach(entry => result[entry.key] = entry.labels[lang]);
    return result;
  }

  constructor(private http: HttpClient, private url: string) {

  }

  getTranslation(lang: string): Observable<unknown> {
    return this.http.get<TranslationEntry[]>(this.url + "?language=" + lang).pipe(
      catchError(error => EMPTY),
      map((result) => {
        return CustomHttpTranslateLoader.toNestedJSON(result, lang);
      })
    );
  }

}


