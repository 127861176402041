<div>
  <h1 mat-dialog-title>{{'objectExport.dialog' | translate}}</h1>
  <div mat-dialog-content>

    <div class="content mat-body flex flex-col gap-2">

      <div class="flex flex-row">
        <div class="caption basis-1/3">{{'objectExport.name' | translate }}</div>
        <div class="basis-2/3">{{data.jobDefinition.name}}</div>
      </div>

      <div class="flex flex-row">
        <div class="caption basis-1/3">{{'objectExport.type' | translate }}</div>
        <div class="basis-2/3">{{('objectExport.title.' + data.jobDefinition.reportType) | translate }}</div>
      </div>

      <div class="flex flex-row">
        <div class="caption basis-1/3">{{'objectExport.states' | translate }}</div>
        <div class="basis-2/3">
          <div class="flex flex-row justify-between" *ngFor="let stateTransition of (data.stateTransitions)">
            <div>{{('objectExport.state.' + stateTransition.state) | translate }}</div>
            <div>{{stateTransition.transitionDate | date:'short':undefined: selectedLanguage()}}</div>
          </div>
        </div>
      </div>

      <div class="flex flex-row">
        <div class="caption basis-1/3">{{'objectExport.runTime' | translate }}</div>
        <div class="basis-2/3">{{data.runTime | date:'HH:ss': '+0000': selectedLanguage()}}</div>
      </div>

      <div class="flex flex-row">
        <div class="caption basis-1/3">{{'objectExport.expires' | translate }}</div>
        <div class="basis-2/3">{{data.expiryDate | date:'medium':undefined: selectedLanguage()}}</div>
      </div>

    </div>
  </div>
</div>

