import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Template, TemplateType} from '../../api/model/template.model';
import {TemplateMapper} from './template.mapper';
import {Backend} from './template.model';
import {ObjectStoreMasterDataConfig} from '../../object-master-data.config';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private httpClient: HttpClient, private  config: ObjectStoreMasterDataConfig) {
  }

  public load(templateName: string, templateType: TemplateType): Observable<Template> {
    return this.httpClient.get<Backend.Template>(this.toTemplateUrl(templateType).replace(":templateName", templateName))
      .pipe(
        map(template => TemplateMapper.mapTemplate(template))
      );
  }

  private toTemplateUrl(type: TemplateType): string {
    switch (type) {
      case TemplateType.CHECKIN:
        return this.config.checkinTemplateUrl;
      case TemplateType.EDIT:
        return this.config.editTemplateUrl;
      case TemplateType.SEARCH:
        return this.config.searchTemplateUrl;
    }
  }
}
