import {ObjectAclDefinition} from "@frontmania/object-master-data";

export class InitAcl {
  static readonly type = '[DynamicForms] Acl.Init';
  constructor(public acl: ObjectAclDefinition[]) {}
}

export class ClearAcl {
  static readonly type = '[DynamicForms] Acl.Clear';

}

export class AddAcl {
  static readonly type = '[DynamicForms] Acl.Add';

  constructor(public newAcl: ObjectAclDefinition) {
  }
}

export class RemoveAcl {
  static readonly type = '[DynamicForms] Acl.Remove';

  constructor(public name: string) {
  }
}

export class UpdatePermission {
  static readonly type = '[DynamicForms] Acl.UpdatePermission';

  constructor(public name: string, public permission: string, public add: boolean) {
  }
}


export class RemoveForm {
  static readonly type = '[DynamicForms] RemoveForm';

  constructor(public formName: string) {
  }
}
