<div class="flex flex-row h-full">
  <mat-sidenav-container class="basis-full" autosize (backdropClick)="toggleMenuByName(MAIN_SIDE_NAV)">
    <mat-sidenav *ngIf="showSideMenu" [opened]="(sideNavExpanded$ | async)" [mode]="isLargeScreen() ? 'side' : 'over'"
                 [autoFocus]="false">
      <ng-container *ngFor="let mainItem of allowedMenuItems">
        <mat-nav-list>

          <ng-container
            *ngIf="hasSubitems(mainItem); then nestedItem; else plainItem">
          </ng-container>

          <ng-template #nestedItem>
            <mat-list-item class="fm-bold-list-item" (click)="toggleMenuByName(mainItem.name)" *ngIf="!showSubItemsDirectly()">
              <div class="flex flex-row justify-between items-center">
                <span>{{'sidebar.menuItems.' + mainItem.name | translate}}</span>
                <mat-icon [ngClass]="{'rotate_neg_90' : subMenuExpanded(mainItem.name) === false}">
                  expand_more
                </mat-icon>
              </div>

            </mat-list-item>
          </ng-template>

          <div *ngIf="subMenuExpanded(mainItem.name) || showSubItemsDirectly()">
            <mat-nav-list (click)="toggleMenuByName(MAIN_SIDE_NAV)" *ngFor="let item of mainItem.subItems">
              <a mat-list-item class="sublistItem" routerLink="{{item.route}}" routerLinkActive="is-active">
                <div class="flex flex-row justify-between items-center">
                  <span>{{'sidebar.menuItems.' + item.name | translate}}</span>
                  <mat-icon *ngIf="isUserStartPage(item.route)">star</mat-icon>
                </div>
              </a>
            </mat-nav-list>
          </div>

          <ng-template #plainItem>
            <mat-list-item class="fm-bold-list-item" (click)="toggleMenuByName(MAIN_SIDE_NAV)" routerLink="{{mainItem.route}}" routerLinkActive="is-active">
              <div class="flex flex-row justify-between items-center">
                <span>{{'sidebar.menuItems.' + mainItem.name | translate}}</span>
                <mat-icon *ngIf="isUserStartPage(mainItem.route)">star</mat-icon>
              </div>
            </mat-list-item>
          </ng-template>
        </mat-nav-list>

        <mat-divider *ngIf="allowedMenuItems?.length > 1"></mat-divider>
      </ng-container>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-content *ngIf="!showRolesWarning"></ng-content>
      <div *ngIf="!!showRolesWarning">
        <h2>{{'noConfiguredRoles' | translate}}</h2>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

