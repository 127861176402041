import { Component, Input } from '@angular/core';
import { FieldDefinition, SearchOperator } from '@frontmania/object-master-data';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'frontmania-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent {

  @Input()
  fieldDefinition: FieldDefinition;
  @Input()
  dynamicForm: FormGroup;
  @Input()
  mandatory: boolean;

  dateOperators = [
    {
      name: SearchOperator.DATE_EQUALS,
      label: "equals"
    },
    {
      name: SearchOperator.DATE_BETWEEN,
      label: "between"
    },
    {
      name: SearchOperator.DATE_LESS_THAN,
      label: "lessThan"
    },
    {
      name: SearchOperator.DATE_LESS_THAN_OR_EQUAL,
      label: "lessThanOrEqual"
    },
    {
      name: SearchOperator.DATE_GREATER_THAN,
      label: "greaterThan"
    },
    {
      name: SearchOperator.DATE_GREATER_THAN_OR_EQUAL,
      label: "greaterThanOrEqual"
    }
    ];

  hasField(key: string) {
    const val = this.dynamicForm.get(this.fieldDefinition.name).get(key);
    return !!val;
  }

  isDateRangeOperator() {
    return this.dynamicForm.get(this.fieldDefinition.name).value?.operator === SearchOperator.DATE_BETWEEN;
  }

  hasErrors() {
    return this.dynamicForm.get(this.fieldDefinition.name).errors?.invalidDateSelection;
  }

}
