import {Action, createSelector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {filter, mergeMap, tap} from 'rxjs/operators';
import {
  ObjectAclDefinition,
  ObjectAclStateType,
  ObjectClass,
  PropertyDefinition
} from '../../api/model/object-class.model';
import {ObjectClassService} from './object-class.service';
import {LoadObjectClasses} from './object-class.actions';
import {from} from 'rxjs';

export interface ObjectClassModel {
  objectClasses: {
    [name: string]: ObjectClass
  }
}

export const STATE_DEFAULTS: ObjectClassModel = {
  objectClasses: {}
}

@State<ObjectClassModel>({
  name: 'objectClassCache',
  defaults: STATE_DEFAULTS
})
@Injectable({
  providedIn: 'root'
})
export class ObjectClassState {

  static objectClass(objectClassName: string) {
    return createSelector([ObjectClassState], (state: ObjectClassModel) => {
      return state?.objectClasses[objectClassName];
    });
  }

  static searchIndexName(objectClassName: string) {
    return createSelector([ObjectClassState], (state: ObjectClassModel) => {
      return state?.objectClasses[objectClassName].searchIndexName;
    });
  }

  static defaultInstanceSecurity(objectClassNames: string[]) {
    return createSelector([ObjectClassState], (state: ObjectClassModel) => {
      const result: ObjectAclDefinition[] = [];
      objectClassNames.forEach(objectClassName => {
        const defInstanceSecurity = state?.objectClasses[objectClassName].defaultInstanceSecurity;
        if (defInstanceSecurity) {
          defInstanceSecurity.forEach(aclEntry => {
            const aclEntryWithStateType = {...aclEntry, stateType: ObjectAclStateType.DEFAULT};
            result.push(aclEntryWithStateType);
          })
        }
      })
      return result;
    });
  }

  static propertyDefinitions(objectClassNames: string[]) {
    return createSelector([ObjectClassState], (state: ObjectClassModel) => {
      const result: PropertyDefinition[] = [];
      objectClassNames.forEach(objectClassName => {
        const classPropertyDefinitions = state?.objectClasses[objectClassName].propertyDefinitions;
        classPropertyDefinitions.forEach(propDev => {
          if (result.findIndex(elem => elem.name === propDev.name) === -1) {
            result.push(propDev);
          }
        })
      })
      return result;
    });
  }

  static choiceValueNamesOfClasses(objectClassNames: string[]) {
    return createSelector([ObjectClassState], (state: ObjectClassModel) => {
      const result: string[] = [];
      objectClassNames.forEach(objectClassName => {
        const notUniqueList = state?.objectClasses[objectClassName].propertyDefinitions
          .filter(def => def.choiceValuesName)
          .map(def => def.choiceValuesName);

        notUniqueList.forEach(element => {
          if (result.findIndex(e => e === element) === -1) {
            result.push(element);
          }
        });
      })
      return result;
    });
  }

  constructor(private store: Store,
              private objectClassService: ObjectClassService) {
  }

  @Action(LoadObjectClasses)
  loadObjectClasses(ctx: StateContext<ObjectClassModel>, action: LoadObjectClasses) {
    return from(action.objectClassNames).pipe(
      filter(className => !ctx.getState()?.objectClasses[className]),
      mergeMap(className => this.objectClassService.load(className)),
      tap(objectClassModel => {
        ctx.patchState({objectClasses: {...ctx.getState()?.objectClasses, [objectClassModel.name]: objectClassModel}});
      })
    );
  }
}
