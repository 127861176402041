import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Location} from '@angular/common';
import {AuthConfig, NullValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {CookieService} from 'ngx-cookie';
import {AuthModuleConfig} from './auth.config';
import {Store} from '@ngxs/store';
import {ClearAuthority, LoadSecurityTemplates, SetAuthority} from './auth.actions';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthState} from './auth.state';
import {AuthorityExtractor} from "./authority-extractor";
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private logger: NGXLogger,
    private store: Store,
    private location: Location,
    private oAuthService: OAuthService,
    private router: Router,
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private config: AuthModuleConfig) {
    this.init();
  }

  init() {
    const redirect_uri = window.location.origin + this.location.prepareExternalUrl('/');
    const silent_refresh_uri = window.location.origin + this.location.prepareExternalUrl('/assets/silent-renew.html');

    const useSilentRefresh = true;

    const authConfig: AuthConfig = {
      issuer: this.config.issuer,
      redirectUri: redirect_uri,
      silentRefreshRedirectUri: silent_refresh_uri,
      useSilentRefresh: useSilentRefresh,
      clientId: this.config.clientId,
      scope: this.config.scope,
      requireHttps: false,
      clearHashAfterLogin: true,
      sessionChecksEnabled: false,
      disableAtHashCheck: true,
      showDebugInformation: false,
      customQueryParams: {
        resourceServer: 'ECM'
      },
      responseType: 'code',
      strictDiscoveryDocumentValidation: false
    };
    this.oAuthService.configure(authConfig);
    this.oAuthService.tokenValidationHandler = new NullValidationHandler();
    if (useSilentRefresh) {
      this.oAuthService.setupAutomaticSilentRefresh();
    }
    this.oAuthService.events.pipe(
      filter(event => event.type === 'token_received' || event.type === 'session_terminated' || event.type === 'silent_refresh_error')
    ).subscribe(event => {
        if (event.type === 'token_received') {
          this.cookieService.put('accessToken', this.oAuthService.getAccessToken());
          if (!this.store.selectSnapshot(AuthState.authorized)) {
            this.store.dispatch([
              new SetAuthority(AuthorityExtractor.extractAuthority(this.oAuthService.getAccessToken())),
              new LoadSecurityTemplates()
            ]);
            this.forwardToLastNavigationRoute();

          }
        }

        if (event.type === 'session_terminated' || event.type === 'silent_refresh_error') {
          this.logout();
        }
      });
    if (this.config.autoLogin) {
      this.oAuthService.loadDiscoveryDocumentAndLogin();
    } else {
      this.oAuthService.loadDiscoveryDocument();
    }
  }

  logIn() {
    this.oAuthService.initCodeFlow()
  }

  logout() {
    this.oAuthService.logOut();
    this.store.dispatch(new ClearAuthority());
  }

  private forwardToLastNavigationRoute(): void {
    const redirectUri = this.store.selectSnapshot(AuthState.redirectUri);
    if (redirectUri) {
      this.router.navigateByUrl(redirectUri);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
