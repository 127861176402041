/**
 * This is the internal datamodel used for Templates.
 */
export class Template {
  documentClass: string;
  templateName: string;
  objectClasses: string[];
  // at the moment we just use a list of fields. groups and columns are ignored in the model;
  // hence we flat it in the TemplateMapper...
  formGroups: FormGroup[];
  resultTableColumns: Field[];
  aggregations: Field[];
  options: Options;
}

export class Options {
  disableVersioningButton?: boolean;
  export?: {
    csv: boolean;
    content: boolean;
  };
  permissions?: string[];
  showPermissions?: boolean;
  additionalSearchTerm?: string;
}

export class FormGroup {
  label?: string;
  formFields: Field[];
}

export class Field {
  dateRange: boolean
  name: string;
  readonly?: boolean;
  searchOperator?: SearchOperator;
  masterDataSource?: string;
  unitTransformInfo?: UnitTransformInfo;
  fillStrategy?: FillStrategy;
}

export interface UnitTransformInfo {
  converter: UnitTransformConverter,
  digitInfo?: string,
  unitSymbol?: string
}

export enum UnitTransformConverter {
  NUMERIC = 'NUMERIC',
  FILE_SIZE = 'FILE_SIZE',
  DATE = 'DATE',
  CURRENCY = 'CURRENCY'
}


export enum SearchOperator {
  STRING_EQ = "STRING_EQ",
  STRING_CONTAINS = "STRING_CONTAINS",
  STRING_STARTS_WITH = "STRING_STARTS_WITH",
  STRING_NOT_EQUAL = "STRING_NOT_EQUAL",
  DATE_ARRAY_CONTAINS = "DATE_ARRAY_CONTAINS",
  DATE_BETWEEN = "DATE_BETWEEN",
  DATE_LESS_THAN = "DATE_LESS_THAN",
  DATE_LESS_THAN_OR_EQUAL = "DATE_LESS_THAN_OR_EQUAL",
  DATE_GREATER_THAN = "DATE_GREATER_THAN",
  DATE_GREATER_THAN_OR_EQUAL = "DATE_GREATER_THAN_OR_EQUAL",
  DATE_EQUALS = "DATE_EQUALS"
}

export enum FillStrategy {
  FILTER_CV_BY_ROLE_COUNTRY = 'FILTER_CV_BY_ROLE_COUNTRY',
  PREFILL_BY_ROLE_COUNTRY = 'PREFILL_BY_ROLE_COUNTRY'
}

export enum TemplateType {
  SEARCH, CHECKIN, EDIT
}
