import {Component, OnInit} from '@angular/core';
import {Job, JobState} from '../../api/model/job.model'
import {ObjectExportService} from '../../api/object-export.service'
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from '@angular/material/dialog';
import {FileSaverService} from "ngx-filesaver";
import {JobInfoComponent} from '../job-info/job-info.component';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {catchError} from "rxjs";
import {NotificationType, OpenNotification} from "@frontmania/notification";
import {Store} from "@ngxs/store";

@UntilDestroy()
@Component({
  selector: 'frontmania-export-jobs',
  templateUrl: './export-jobs.component.html',
  styleUrls: ['./export-jobs.component.scss']
})
export class ExportJobsComponent implements OnInit {

  jobState = JobState;
  displayedColumns: string[] = ['info', 'name', 'type', 'state', 'actions', 'expiry'];
  dataSource: Job[];

  constructor(private translateService: TranslateService,
              private objectExportService: ObjectExportService,
              private fileSaverService: FileSaverService,
              private store: Store,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.objectExportService.getAllJobs().pipe(
      untilDestroyed(this)
    ).subscribe(jobs => {
      this.dataSource = jobs
    });

  }

  formatExpiry(timestamp): string {

    const day = 24 * 60 * 60 * 1000;

    if (timestamp) {
      const diff = timestamp - new Date().getTime();
      if (diff > day) {
        return Math.floor(diff / day) + ' ' + this.translateService.instant('objectExport.unit.days');
      }
      return Math.floor(diff / 3600000) + ' ' + this.translateService.instant('objectExport.unit.hours');
    }
    return ''
  }

  download(job: Job): void {
    this.objectExportService.downloadFile(job)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(fileBlob => {
      this.fileSaverService.save(fileBlob, job.jobDefinition.name + ".zip");
    })
  }

  openInfo(job: Job) {

    this.dialog.open(JobInfoComponent, {
      data: job
    });
  }

  delete(job: Job) {
    this.objectExportService.deleteJob(job).pipe(
        untilDestroyed(this),
        catchError((error) => this.store.dispatch(new OpenNotification(NotificationType.ERROR, "objectExport.deleteError", {reason: error?.message})))
    ).subscribe(() => {
      this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, "objectExport.deleted", {jobName: job.jobDefinition.name}));
    })
    const pos = this.dataSource.indexOf(job);

    if (pos >= 0) {
      this.dataSource.splice(pos, 1);
      this.dataSource = [...this.dataSource];
    }
  }

}

