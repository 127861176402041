import {Injectable} from '@angular/core';
import {distinctUntilChanged, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private httpLoading$: Subject<boolean> = new Subject<boolean>();

  isLoading(): Observable<boolean> {
    return this.httpLoading$.asObservable().pipe(distinctUntilChanged());
  }

  updateLoadingState(state: boolean) {
    this.httpLoading$.next(state);
  }
}
