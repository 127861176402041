import {Component, Inject, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {NotificationType, OpenNotification} from "@frontmania/notification";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataTableService} from "../../../../service/data-table.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {catchError, finalize, switchMap} from "rxjs/operators";
import {PreviewType} from "../../../../object-search.model";
import {ObjectSearchConfig} from "../../../../object-search.config";
import {I18nState} from "@frontmania/i18n";

export interface PreviewData {
  objectId: string;
  type: PreviewType;
}

@UntilDestroy()
@Component({

  selector: 'frontmania-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  private readonly objectId: string;
  private readonly previewType: PreviewType;
  pdfObject: Blob;
  loading = true;
  textContent: string;
  imageSrc: string;
  currentLocale: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PreviewData,
              private store: Store,
              private config: ObjectSearchConfig,
              private dataTableService: DataTableService,
              private dialogRef: MatDialogRef<PreviewComponent>) {
    this.objectId = data.objectId;
    this.previewType = data.type;
  }

  ngOnInit(): void {
    this.currentLocale = this.store.selectSnapshot(I18nState.selectedLocale);
    if (this.isPDF) {
      this.dataTableService.downloadInlineContent(this.objectId)
        .pipe(
          untilDestroyed(this),
          catchError((error) => {
            this.dialogRef.close();
            return this.store.dispatch(new OpenNotification(NotificationType.ERROR, 'preview.download.error', {reason: error?.message}));
          }),
          finalize(() => {
            this.hideLoader();
          })
        )
        .subscribe((response: Blob) => {
          this.pdfObject = response;
        });
    }
    if (this.isText) {
      this.dataTableService.downloadInlineContent(this.objectId).pipe(
        untilDestroyed(this),
        switchMap((blob: Blob) => blob.text()),
        finalize(() => this.hideLoader()),
      ).subscribe((textContent: string) => {
        this.textContent = textContent;
      });
    }
    if (this.isImage) {
      this.imageSrc = this.config.inlineContentUrl.replace('{objectId}', this.objectId);
      this.hideLoader();
    }
  }

  get isPDF() {
    return this.previewType === PreviewType.PDF;
  }

  get isText() {
    return this.previewType === PreviewType.TEXT;
  }

  get isImage() {
    return this.previewType === PreviewType.IMAGE;
  }

  hideLoader() {
    this.loading = false;
  }

  errorOccurred($event: Error) {
    this.store.dispatch(new OpenNotification(NotificationType.ERROR, 'preview.download.error', {reason: $event?.message}));
    this.hideLoader();
    this.dialogRef.close();
  }
}
