export class LoadObjectSearchMasterData {
  static readonly type = '[ObjectMasterData] LoadObjectSearchMasterData';

  constructor(public language: string, public templateName: string) {
  }
}

export class LoadCheckinMasterData {
  static readonly type = '[ObjectMasterData] LoadCheckinMasterData';

  constructor(public language: string, public templateName: string) {
  }
}

export class UpdateLanguage {
  static readonly type = '[ObjectMasterData] UpdateLanguage';

  constructor(public language: string) {
  }
}

export class LoadEditMasterData {
  static readonly type = '[ObjectMasterData] LoadEditMasterData';

  constructor(public language: string, public className: string) {
  }
}

export class RefreshAllChoiceValues {
  static readonly type = '[ObjectMasterData] RefreshAllChoiceValues';

  constructor(public language: string) {
  }
}
