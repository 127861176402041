<div class="layout-container fm-background-container" [class.hidden]="tableHidden() | async" >
    <div class="flex flex-row justify-between items-center">
      <div>
        <frontmania-doc-count-info></frontmania-doc-count-info>
      </div>
      <div>
        <button [disabled]="selection?.isEmpty()" data-cy="export-csv-button" *ngIf="(anyFilesDocs.options$ | async)?.export?.csv" mat-button color="primary"  (click)="export(reportType.CSV)">
          <mat-icon>download</mat-icon>
          {{'resultTable.export.asCsv' | translate}}
        </button>
        <button [disabled]="selection?.isEmpty()" *ngIf="(anyFilesDocs.options$ | async)?.export?.content" mat-button color="primary" (click)="export(reportType.ZIP)">
          <mat-icon>download</mat-icon>
          {{'resultTable.export.asZip' | translate}}
        </button>
      </div>
    </div>
    <table mat-table class="fm-row-hover fm-collapsable-rows" [dataSource]="anyFilesDocs" matSort multiTemplateDataRows>
      <ng-container matColumnDef="multiselection" >
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex flex-row justify-start items-center">
            <mat-checkbox data-cy="select-all-checkbox" class="table-checkbox"
                          (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()" disabled>
            </mat-checkbox>
          </div>
        </th>
        <td mat-cell *matCellDef="let anyFilesDoc">
          <div class="flex flex-row justify-start items-center">
            <mat-checkbox data-cy="select-specific-checkbox" class="table-checkbox"  (click)="$event.stopPropagation()" (change)="$event ? toggleSelection(anyFilesDoc.id) : null"
                          [checked]="selection.isSelected(anyFilesDoc.id)">
            </mat-checkbox>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex flex-row justify-start items-center">
            <mat-icon>preview</mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let anyFilesDoc">
          <div class="flex flex-row justify-start items-center">
              <mat-icon data-cy="preview-icon" color="primary" *ngIf="supportsPreview(anyFilesDoc)" (click)="showPreview($event, anyFilesDoc); $event.stopPropagation()">preview</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="attachments">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex flex-row justify-start items-center">
            <mat-icon>attach_file</mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let anyFilesDoc">
          <div class="flex flex-row justify-start items-center">
            <mat-icon data-cy="attachment-icon" color="primary" *ngIf="anyFilesDoc['hasAttachments']" (click)="toggleExpand($event, anyFilesDoc); $event.stopPropagation() ">attach_file</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container *ngFor="let column of (anyFilesDocs.resultTableColumns$ | async); let first = first" matColumnDef="{{column.name}}">
        <th mat-header-cell disableClear mat-sort-header [disabled]="column.name === 'documentTitle'" *matHeaderCellDef>{{'objectAttributes.' + column.name | translate}}
        </th>
        <td mat-cell *matCellDef="let anyFilesDoc" [class.text-break]="!column.multivalued">
          <div class="overflow-container">
            <ng-container *ngIf="!!column.choiceValuesName">
                {{ (asStringValue(anyFilesDoc[column.name]) | choiceValuesMapping: column.choiceValuesName) ?? '' }}
            </ng-container>
            <ng-container *ngIf="!column.choiceValuesName">
              <div>{{ (asStringValue(anyFilesDoc[column.name]) | superUnit : selectedLocale() : column.unitTransformInfo) ?? ''  }} </div>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let anyFilesDoc" [attr.colspan]="(anyFilesDocs.resultTableColumnNames$ | async).length">
          <div class="collapsing-content" *ngIf="anyFilesDoc['hasAttachments']" [@detailExpand]="anyFilesDoc.id === expandedDocId ? 'expanded' : 'collapsed'">
            <ng-container *ngIf="templateName$ | async as templateName">
              <frontmania-object-attachments *ngIf="anyFilesDoc.id === expandedDocId" [objectId]="anyFilesDoc.id">
              </frontmania-object-attachments>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container>
        <tr class="mat-row no-data-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="(anyFilesDocs.resultTableColumnNames$ | async)?.length">
            <div class="flex flex-row justify-center items-center">
              <span *ngIf="(loading$ | async) === false">{{'resultTable.noDocuments' | translate}}</span>
              <mat-spinner *ngIf="loading$ | async"></mat-spinner>
            </div>

          </td>
        </tr>
      </ng-container>

      <tr mat-header-row class="table-row" *matHeaderRowDef="anyFilesDocs.resultTableColumnNames$ | async"></tr>
      <tr data-cy="result-table-row" mat-row class="table-row" *matRowDef="let row; columns: anyFilesDocs.resultTableColumnNames$ | async" (click)="selectRow(row)"></tr>
      <tr data-cy="result-table-row" mat-row *matRowDef="let attachment; columns: ['expandedDetail']" class="collapsing-content-row" [class.expanded]="expandedDocId === attachment.id"></tr>
    </table>
</div>
