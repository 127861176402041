import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChoiceValueDefinition} from '../../api/model/choice-values.model';
import {ChoiceValuesMapper} from './choice-values.mapper';
import {Backend} from './choice-values.model';
import {ObjectStoreMasterDataConfig} from '../../object-master-data.config';


@Injectable({
  providedIn: 'root'
})
export class ChoiceValuesService {

  constructor(private httpClient: HttpClient, private config: ObjectStoreMasterDataConfig) {
  }

  load(language: string, choiceValueNames: string[]): Observable<ChoiceValueDefinition[]> {
    let param: HttpParams = new HttpParams().append('language', language);
    choiceValueNames.forEach(cvName => param = param.append('name', cvName));
    return this.httpClient.get<Backend.ChoiceValueDefinition[]>(this.config.choiceValuesUrl, {params: param})
      .pipe(
        map(list => ChoiceValuesMapper.mapChoiceValuesList(list, language))
      );
  }

}
